import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { OrderChange } from '../../interfaces/order-change';
import { eOrderChangeOperation } from '../../enums';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { BehaviorSubject, concat, concatMap, EMPTY, filter, forkJoin, map, merge, mergeMap, Observable, Subject, switchAll, switchMap, takeUntil, tap } from 'rxjs';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';
import { PathConversionService } from '../../services/path-conversion/path-conversion.service';
import { OrderUIConfiguration } from '../../interfaces/ui-configurations/order-ui-configuration';
import { OrderAction } from '../../interfaces/orderAction';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'lib-order-changes',
  templateUrl: './order-changes.component.html',
  styleUrls: ['./order-changes.component.css'],
  animations: [
    trigger('slideDown', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.5s cubic-bezier(0.5, 0.1, 0.1, 1)')
      ])
    ])
  ]
})
export class OrderChangesComponent implements OnInit, OnDestroy {
  @Input() saving$!: Observable<boolean>;
  @Input() uiConfiguration!: OrderUIConfiguration;
  protected orderChanges$ = new BehaviorSubject<OrderChange[]>([]);
  protected orderActions$ = new BehaviorSubject<OrderAction[]>([]);

  private onDestroy$ = new Subject<void>();

  protected originalOrder!: Order;
  protected formText: string | undefined;

  constructor(
    private orderManager: OrderManagerService,
    private ordersService: OrderRepositoryService,
    private pathConversionService: PathConversionService) { }

  ngOnInit(): void {
    this.orderManager.currentOrder.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      order => this.orderChanges$.next(order.orderChanges ?? []));

    this.orderManager.currentOrder.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      order => this.formText = [... new Set(order.orderActions?.map(action => action.registryForm?.formFull))].join(', '));

    this.orderManager.parentOrder.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(order => this.originalOrder = order);

    if(!this.orderManager.parentOrder.value.id) {
      const orderChangesSubscription = this.orderChanges$.pipe(
        takeUntil(this.onDestroy$),
        filter(changes => changes.length > 0),
        switchMap(changes => this.ordersService.getOrder('Orders', changes.at(0)!.originalOrderID))
      ).subscribe(order => {
        this.orderManager.updateParentOrder(order);
        orderChangesSubscription.unsubscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  protected getIcon(change: OrderChange): string {
    if(change.operation === eOrderChangeOperation.Add) {
      return 'add_circle_outline';
    } else if(change.operation === eOrderChangeOperation.Remove) {
      return 'remove_circle_outline';
    } else if(change.operation === eOrderChangeOperation.Replace) {
      return 'swap_horiz';
    } else {
      return '';
    }
  }

  protected getIconTooltip(change: OrderChange): string {
    if(change.operation === eOrderChangeOperation.Add) {
      return 'Added';
    } else if(change.operation === eOrderChangeOperation.Remove) {
      return 'Removed';
    } else if(change.operation === eOrderChangeOperation.Replace) {
      return 'Replaced';
    } else {
      return '';
    }
  }

  protected convertPath(change: OrderChange): string {
    let formattedPath: string = change.path;
    const partiesRegex = /^Parties(\/\d+)?$/
    const assetsRegex = /^Assets(\/\d+)?$/

    if(partiesRegex.test(change.path)) {
      if ((change.newValue?.includes('Debtor') ?? false) || (change.originalValue?.includes('Debtor') ?? false)) {
        formattedPath = 'Debtors';
      } else if ((change.newValue?.includes('Dealer') ?? false) || (change.originalValue?.includes('Dealer') ?? false)) {
        formattedPath = 'Dealers';
      } else if ((change.newValue?.includes('Secured') ?? false) || (change.originalValue?.includes('Secured') ?? false)) {
        formattedPath = 'Secured Parties';
      } else if ((change.newValue?.includes('Registering') ?? false) || (change.originalValue?.includes('Registering') ?? false)) {
        formattedPath = 'Registering Agents';
      }
    } else if (assetsRegex.test(change.path)) {
      return 'Assets';
    } else if(this.originalOrder) {
      formattedPath = this.pathConversionService.convertPath(this.originalOrder, change.path, true);
    } else {
      formattedPath = '';
    }

    return formattedPath;
  }

  protected formatDescription(change: OrderChange): string {
    if(change.operation === eOrderChangeOperation.Add) {
      return `<strong>Added</strong> ${change.newValue}`;
    } else if(change.operation === eOrderChangeOperation.Remove) {
      return `<strong>Removed</strong> ${change.originalValue}`
    } else if(change.operation === eOrderChangeOperation.Replace) {
      if(change.newValue && !change.originalValue) {
        return `<strong>Replaced</strong> empty field <strong>with</strong> ${this.formatValue(change.formattedNewValue)}`
      } else if(!change.newValue && change.originalValue) {
        return `<strong>Replaced</strong> ${this.formatValue(change.formattedOriginalValue)} <strong>with</strong> empty field`
      } else {
        return `<strong>Replaced</strong> ${this.formatValue(change.formattedOriginalValue)} <strong>with</strong> ${this.formatValue(change.formattedNewValue)}`
      }
    } else {
      return '';
    }
  }

  //Todo: move into API
  protected formatValue(value: string | undefined): string {
    const dateRegex = /^(?:\d{4}[-\/]\d{2}[-\/]\d{2}|\d{2}[-\/]\d{2}[-\/]\d{4})(?:\s(?:\d{1,2}:\d{2}(?::\d{2})?\s?(?:AM|PM|am|pm)?))?$/;
    if(value?.toLowerCase() === 'true') {
      return 'checked';
    } else if(value?.toLowerCase() === 'false') {
      return 'unchecked';
    } else if(dateRegex.test(value ?? '')) {
      return value?.split(' ')?.at(0) ?? value ?? '';
    } else {
      return value ?? '';
    }
  }
}
