import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-static-warnings',
  templateUrl: './static-warnings.component.html',
  styleUrls: ['./static-warnings.component.css']
})
export class StaticWarningsComponent {
  @Input() showHistoricalWarning: boolean = false;
  @Input() showBasicWarning: boolean = false;
}
