import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JurisdictionSelectionComponent } from '../jurisdiction-selection/jurisdiction-selection.component';
import { Order } from '../../interfaces/orders/order';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { DateUtils } from '../../date-utils';

@Component({
  selector: 'lib-signing-location',
  templateUrl: './signing-location.component.html',
  styleUrls: ['./signing-location.component.css']
})
export class SigningLocationComponent implements OnDestroy {
  @Input() order!: Order;
  @Input() isDisabled: boolean = false;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Output() signingLocationFormValueChangedEvent = new EventEmitter<any>();

  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  public signingLocation!: FormGroup;

  private onDestroy$ = new Subject<void>();

  signingDateError$ = new BehaviorSubject<string | null | undefined>(null);
  signingCityError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    // this.order.lien!.signingCountry = this.order.lien!.signingCountry ?? "CA";

    this.signingLocation = this.formBuilder.group({
      signingDate: [this.order.lien?.signingDate, null],
      signingCity: [this.order.lien?.signingCity, null],
      signingJurisdiction: [this.order.lien?.signingJurisdiction, null],
      signingCountry: [this.order.lien?.signingCountry, null]
    })

    if (this.isDisabled) {
      this.signingLocation.disable();
    }

    this.signingLocation.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.signingLocationFormValueChangedEvent.emit(this.signingLocation.value));
    this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
  }

  ngAfterViewInit() {
    let countryControl = this.jurisdictionSelectionComponent.countrySelectionForm.controls['Country'];
    let jurisdictionControl = this.jurisdictionSelectionComponent.countrySelectionForm.controls['Jurisdiction'];

    this.signingLocation.setControl('signingCountry', countryControl);
    this.signingLocation.setControl('signingJurisdiction', jurisdictionControl);

    if (this.order.lien?.signingCountry && this.order.lien?.signingCountry != "") {
      this.jurisdictionSelectionComponent.setCountrySelection(this.order.lien!.signingCountry);
    }

    if (this.order.lien?.signingJurisdiction && this.order.lien?.signingJurisdiction != "") {
      this.jurisdictionSelectionComponent.setJurisdictionSelection(this.order.lien!.signingJurisdiction);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.jurisdictionSelectionComponent.pushErrors(errors);

    this.signingDateError$.next(errors.filter(error => error.path.includes('/signingdate'))?.at(0)?.userFriendlyMessage);
    this.signingCityError$.next(errors.filter(error => error.path.includes('/signingcity'))?.at(0)?.userFriendlyMessage);
  }

  public get signingDate(): Date | null {
    const dateValue = this.signingLocation.get('signingDate')?.value;
    return DateUtils.getDateNoTimestamp(dateValue);
  }

  public get signingCity(): string | null {
    return this.signingLocation.get('signingCity')?.value || null;
  }

  public get signingJurisdiction(): string | null {
    return this.signingLocation.get('signingJurisdiction')?.value || null;
  }

  public get signingCountry(): string | null {
    return this.signingLocation.get('signingCountry')?.value || null;
  }
}
