import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth-common/auth/auth.service';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Invoice } from "../../interfaces/invoices/invoice";

@Injectable({
    providedIn: 'root'
})
export class InvoicesRepositoryService extends PaginatedRepository<Invoice> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/BillingDetails/[1]/Invoices`;
        this._baseLink = `${config.rootUrl}/Invoices`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getInvoice = (invoiceID: string) => {
        return this.http.get<Invoice>(`${this._baseLink}/${invoiceID}`, this.generateHeaders());
    }
}