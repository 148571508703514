import { Component, Input } from '@angular/core';
import { OrderPreviewComponent } from '../order-preview/order-preview-component';
import { Order } from 'projects/reg-hub-common/src/public-api';

@Component({
  selector: 'lib-registration-order-preview',
  templateUrl: './registration-order-preview.component.html',
  styleUrls: ['./registration-order-preview.component.css']
})
export class RegistrationOrderPreviewComponent extends OrderPreviewComponent {
  @Input() order!: Order;

  getTerm() {
    return this.order.lien?.isInfiniteTerm ? 'Infinity' : this.order.lien?.term;
  }
}
