import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RegHubApiResult, UserGroup, UserGroupRepositoryService, BillingDetails, BillingDetailsRepositoryService, ConfigurationDetails, ConfigurationDetailsRepository, ConfigurationState, UserGroupForUpdate } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-user-group-info',
    templateUrl: './user-group-info.component.html',
    styleUrls: ['./user-group-info.component.css']
})
export class UserGroupInfoComponent implements OnInit {
    private routeParamSubscription!: Subscription;
    hasAccess: boolean = false;
    userGroup: UserGroup | any = null
    billingDetailsOptions: BillingDetails[] = []
    configurationDetailsOptions: ConfigurationDetails[] = []

    constructor(private userGroupRepo: UserGroupRepositoryService,
        private billingDetailsRepo: BillingDetailsRepositoryService,
        private configurationDetailsRepo: ConfigurationDetailsRepository,
        private configurationState: ConfigurationState,
        private messageService: ToastrService,
        private router: Router,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.configurationState.hasUserManagementAccess().then(userAccess => {
            this.hasAccess = userAccess;
            if (!this.hasAccess) {
                //Shouldn't be here
                this.messageService.error("Access Denied!");
            }
            else {
                this.routeParamSubscription = this.activatedRoute.paramMap.subscribe(userGroupParams => {
                    // check current slug for user group id
                    const userGroupID = userGroupParams.get('userGroupID')!;
        
                    this.getUserGroup(userGroupID);
                });
            }
        })
    }

    ngOnDestroy() {
        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    private getUserGroup = (userGroupID: string) => {
        // this wil call api/v1/corporations
        this.userGroupRepo.getUserGroup(userGroupID)
            .subscribe(group => {
                this.userGroup = group;
                this.getBillingDetails(this.userGroup);
                this.getConfigurationDetails(this.userGroup);
            })
    }

    private getBillingDetails = (userGroup: UserGroup) => {
        this.billingDetailsRepo.populateBaseLink([userGroup.corporationID]);
        
        var billingParams = new HttpParams().set('PageSize', 100);
        this.billingDetailsRepo.getFirstPageSubscribable(billingParams)
            .subscribe({
                next: details => {
                    this.billingDetailsOptions = details;
                },
                error: () => {
                    this.openSnackBar("Failed to get Billing Centres");
                }
            });
    }

    private getConfigurationDetails = (userGroup: UserGroup) => {
        this.configurationDetailsRepo.populateBaseLink([`${userGroup.corporationID}`]);

        var configParams = new HttpParams().set('PageSize', 100);

        this.configurationDetailsRepo.getFirstPageSubscribable(configParams)
            .subscribe(configs => {
                this.configurationDetailsOptions = configs;
            });
    }

    updateUserGroupDetails() {
        if (!this.hasAccess) {
            //How did you get here?
            this.messageService.error("Access Denied!");
        }
        
        this.userGroupRepo.updateUserGroup(this.userGroup!.id, this.userGroup as UserGroupForUpdate)
            .subscribe(result => {
                const apiResult = result as RegHubApiResult
                if (apiResult.validation == null) {
                    this.openSnackBar("Save successful");
                    this.router.navigate(['management', 'userGroups', this.userGroup!.id as string]);
                }
            })
    }

    openSnackBar(message: string) {
        const config = new MatSnackBarConfig();
        config.duration = 5000; // Set the duration in milliseconds (2 seconds)

        this.snackBar.open(message, 'Close', config);
    }
}