import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { VinDecodeResult } from '../../interfaces/vin-decode/vin-decode-result';

@Injectable({
  providedIn: 'root'
})
export class VinDecoderService {

  rootUrl: string = "https://vpic.nhtsa.dot.gov/api/"

  constructor(
    private http: HttpClient) {}

  // CRUD Operations ---------------
  public decode = (vin: string) => {
    const options = {
      withCredentials: false
    };

    return this.http.get<VinDecodeResult>(
      `${this.rootUrl}vehicles/DecodeVin/${vin}?format=json`,
      options
    );
  }
}
