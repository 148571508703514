import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ReusableGeneralCollateral } from '../../interfaces/reusable-general-collateral/reusable-general-collateral';
import { ReusableGeneralCollateralForCreation } from '../../interfaces/reusable-general-collateral/reusable-general-collateral-for-creation';
import { ReusableGeneralCollateralForUpdate } from '../../interfaces/reusable-general-collateral/reusable-general-collateral-for-update';

@Injectable({
  providedIn: 'root'
})
export class ReusableGeneralCollateralRepositoryService extends PaginatedRepository<ReusableGeneralCollateral> {
  protected override _baseLink: string;

  constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      
      super(http,messageService);

      this._currentParams = new HttpParams()
          .set('pageSize', 10);

      this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/ReusableGeneralCollaterals`;
      this._baseLink = this._baseLinkTemplate;
  }

  private generateHeaders = () => {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
  }

  public createReusableGeneralCollateral = (corporationID: string, reusableGeneralCollateral: ReusableGeneralCollateralForCreation, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.post<ReusableGeneralCollateral>(
          `${this.config.rootUrl}${urlSlug}/ReusableGeneralCollaterals`,
          reusableGeneralCollateral,
          this.generateHeaders());
  }

  public getReusableGeneralCollateral = (corporationID: string, reusableGeneralCollateralID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.get<ReusableGeneralCollateral>(
          `${this.config.rootUrl}${urlSlug}/ReusableGeneralCollaterals/${reusableGeneralCollateralID}`,
          this.generateHeaders());
  }

  public getReusableGeneralCollaterals = (corporationID: string, userGroupID?: string | null) => {
    var urlSlug = `/Corporations/${corporationID}`
    if (userGroupID) {
      urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
    }

    return this.http.get<ReusableGeneralCollateral[]>(
        `${this.config.rootUrl}${urlSlug}/ReusableGeneralCollaterals?IsVisible=true`,
        this.generateHeaders());
  }

  public updateReusableGeneralCollateral = (corporationID: string, reusableGeneralCollateralID: string, reusableGeneralCollateral: ReusableGeneralCollateralForUpdate, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.put<ReusableGeneralCollateralForUpdate>(
          `${this.config.rootUrl}${urlSlug}/ReusableGeneralCollaterals/${reusableGeneralCollateralID}`,
          reusableGeneralCollateral,
          this.generateHeaders());
  }

  public deleteReusableGeneralCollateral = (corporationID: string, reusableGeneralCollateralID: string, userGroupID?: string | null) => {
      var urlSlug = `/Corporations/${corporationID}`
      if (userGroupID) {
        urlSlug = `${urlSlug}/UserGroups/${userGroupID}`
      }

      return this.http.delete(
          `${this.config.rootUrl}${urlSlug}/ReusableGeneralCollaterals/${reusableGeneralCollateralID}`,
          this.generateHeaders());
  }
}
