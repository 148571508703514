import { Injectable } from '@angular/core';
import { UserRepositoryService } from '../user/user-repository.service';
import { UserGroupRepositoryService } from '../user-group/user-group-repository.service';
import { of, lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationState {
    private _isSet: boolean = false;
    private _isCorporationSet: boolean = false;
    private _corporationID: string = "";
    private _userGroupID: string = "";
    private _userID: string = "";
    private _username: string = "";
    private _billingDetailsID: string = "";
    private _hasAccountingAccess: boolean = false;
    private _hasUserManagementAccess: boolean = false;
    private _hasManageReusablePartyAccess: boolean = false;
    private _hasSystemAdminAccess: boolean = false;
    private _hasClientManagementAccess: boolean = false;

    constructor(private userRepo: UserRepositoryService,
        private userGroupRepo: UserGroupRepositoryService) {}

    async setConfiguration(): Promise<boolean> {
        if (this._isSet) {
            return lastValueFrom(of(this._isSet));
        }
        else {
            await lastValueFrom(this.userRepo.getAuthenticatedUser()).then(user => {
                this._isSet = true;
                this._userGroupID = user.userGroupID;
                this._userID = user.id;
                this._username = `${user.firstName} ${user.lastName}`
                this._hasAccountingAccess = user.configurationDetails?.hasAccountingAccess ?? false;
                this._hasUserManagementAccess = user.configurationDetails?.hasUserManagementAccess ?? false;
                this._hasManageReusablePartyAccess = user.configurationDetails?.hasManageReusablePartyAccess ?? false;
                this._hasSystemAdminAccess = user.configurationDetails?.hasSystemAdminAccess ?? false;
                this._hasClientManagementAccess = user.configurationDetails?.hasClientManagementAccess ?? false;
            });

            return this._isSet;
        }
    }

    async setCorporation(): Promise<boolean> {
        if (this._isCorporationSet) {
            return lastValueFrom(of(this._isCorporationSet));
        }
        else {
            await this.setConfiguration().then(async _ => {
                await lastValueFrom(this.userGroupRepo.getUserGroup(this._userGroupID)).then(userGoup => {
                    this._isCorporationSet = true;
                    this._corporationID = userGoup.corporationID;
                    this._billingDetailsID = userGoup.billingDetailsID ?? "";
                });
            });

            return this._isCorporationSet;
        }
    }

    getCorporationID(): Promise<string> {
        return this.setCorporation().then(_ => {
            return this._corporationID;
        })
    }

    getUserGroupID(): Promise<string> {
        return this.setConfiguration().then(_ => {
            return this._userGroupID;
        })
    }

    getUserID(): Promise<string> {
        return this.setConfiguration().then(_ => {
            return this._userID;
        })
    }

    getUserName(): Promise<string> {
        return this.setConfiguration().then(_ => {
            return this._username;
        });
    }

    getBillingDetailsID(): Promise<string> {
        return this.setCorporation().then(_ => {
            return this._billingDetailsID;
        })
    }

    hasAccountingAccess(): Promise<boolean> {
        return this.setConfiguration().then(_ => {
            return this._hasAccountingAccess;
        })
    }

    hasUserManagementAccess(): Promise<boolean> {
        return this.setConfiguration().then(_ => {
            return this._hasUserManagementAccess;
        })
    }

    hasManageReusablePartyAccess(): Promise<boolean> {
        return this.setConfiguration().then(_ => {
            return this._hasManageReusablePartyAccess;
        })
    }

    hasSystemAdminAccess(): Promise<boolean> {
        return this.setConfiguration().then(_ => {
            return this._hasSystemAdminAccess;
        })
    }

    hasClientManagementAccess(): Promise<boolean> {
        return this.setConfiguration().then(_ => {
            return this._hasClientManagementAccess;
        })
    }
}