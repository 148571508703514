
<mat-card class="grows-to-fill">
    <mat-card-content>
        <!-- Main orders table -->
        <div class="table-container">
            <div class="flex-container-center">
                <div class="flex-item card-header">Complete Orders</div>
                <button color="primary" class="flex-item-fixed-size" (click)="onViewAll()" mat-icon-button aria-label="View All Completed Orders">
                    <mat-icon>visibility</mat-icon>
                </button>
            </div>

            <div *ngIf="data.length > 0">
                <!-- Orders table -->
                <app-order-list [data]="this.data" [total]="this.total" [loading]="this.loading" [displayedColumns]="displayedColumns"></app-order-list>
                <!-- End of orders table-->
            </div>
        </div>

        <!-- End of main orders table-->
        
        <div *ngIf="!loading && data.length === 0" class="spacer-small"></div>
        <lib-placeholder iconName="cancel" iconClass="icon-error" message="Error loading complete orders"
            [condition]="(this.repo.error$ | async) ?? false && !loading"></lib-placeholder>
        <lib-placeholder iconName="check_circle" iconClass="icon-success" message="No complete orders found"
            [condition]="!(this.repo.error$ | async) && !loading && data.length === 0"></lib-placeholder>

    </mat-card-content>
</mat-card>
