import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class ContactUsComponent {
  supportLink: string;
  supportPhone: string;
  billingLink: string;

  constructor() {
    this.supportLink = this.createMailtoLink('admin@reghub.ca', '', '');
    this.supportPhone = this.createTelLink('1-888-508-0608');
    this.billingLink = this.createMailtoLink('billing@reghub.ca', '', '');
  }

  createMailtoLink(email: string, subject: string, body: string): string {
    return `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  }

  createTelLink(phoneNumber: string): string {
    // Remove non-numeric characters from the phone number
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    return `tel:+${cleanedNumber}`;
  }
}