<div class="padding">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="_selectedIndex">
                    <mat-tab label="Live Reports">
                        <app-report-templates-list></app-report-templates-list>
                    </mat-tab>
                    <mat-tab label="Saved Reports">
                        <app-requested-reports-list></app-requested-reports-list>
                    </mat-tab>
                    <!-- <mat-tab label="Schedule Reports">
                        Coming Soon!
                    </mat-tab> -->
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>