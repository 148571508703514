import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Amendment, eAmendmentType, Order, OrderManagerService, OrderUIConfiguration, ValidationProblem } from 'reg-hub-common';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-amendment-details',
  templateUrl: './amendment-details.component.html',
  styleUrls: ['./amendment-details.component.css']
})
export class AmendmentDetailsComponent implements OnInit, OnDestroy {
  @Input() uiConfiguration!: OrderUIConfiguration;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Output() amendmentDetailsFormChanged = new EventEmitter<Amendment>();

  private order!: Order;
  amendmentDetails!: FormGroup;

  change = eAmendmentType.change;
  correction = eAmendmentType.correction;

  amendmentTypeError$ = new BehaviorSubject<string | null | undefined>(null);
  reasonTextError$ = new BehaviorSubject<string | null | undefined>(null);

  private onDestroy$ = new Subject<void>();

  constructor(
    private orderManager: OrderManagerService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.orderManager.currentOrder.subscribe(order => this.order = order);

    this.amendmentDetails = this.formBuilder.group({ });

    if(this.uiConfiguration.amendmentConfiguration?.showCorrectionOrChange) {
      this.amendmentDetails.addControl('amendmentTypeID', this.formBuilder.control(this.order.amendment?.amendmentTypeID ?? eAmendmentType.change));
    }

    if(this.errors$) {
      this.errors$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(errors => this.pushErrors(errors));
    }

    this.amendmentDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => this.amendmentDetailsFormChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public updateAmendment(order: Order) {
    order.amendment = { 
      amendmentTypeID: this.amendmentDetails.get('amendmentTypeID')?.value
    };
  }

  private pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.amendmentTypeError$.next(errors.filter(error => error.path.includes('/amendmenttypeid')).at(0)?.userFriendlyMessage);
  }
}
