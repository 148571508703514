import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { DebtorsComponent } from "../../../../orders/debtors/debtors.component";
import { SecuredPartiesComponent } from "../../../../orders/secured-parties/secured-parties.component";
import { ReviewComponent } from "projects/reg-hub-client/src/app/orders/review/review.component";
import { CollateralComponent } from "projects/reg-hub-client/src/app/orders/collateral/collateral.component";
import { ACOLLienDetailsComponent } from "projects/reg-hub-client/src/app/orders/lien-details/acol-lien-details/acol-lien-details.component";
import { OrderChangesComponent } from "projects/reg-hub-client/src/app/orders/order-changes/order-changes.component";

export class PEAmendmentStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: ACOLLienDetailsComponent, validationState: StepValidationState.DefaultState },
            { title: "Debtors", content: DebtorsComponent, validationState: StepValidationState.DefaultState },
            { title: "Sec. Parties", content: SecuredPartiesComponent, validationState: StepValidationState.DefaultState },
            { title: "Collateral", content: CollateralComponent, validationState: StepValidationState.DefaultState },
            { title: "Changes", content: OrderChangesComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}