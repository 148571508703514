import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Lien } from '../../interfaces/liens/lien';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class LienRepositoryService {

  
  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {}

  // CRUD Operations ---------------
  public getLien = (route: string, lienID: string) => {
    // Get Liens
    return this.http.get<Lien>(
      this.createCompleteRoute(route + "/" + lienID, this.config.rootUrl));
  }

  public getLiens = (route: string) => {
    // Get Liens
    return this.http.get<Lien[]>(
      this.createCompleteRoute(route, this.config.rootUrl));
  }

  public createLien = (route: string, lien: Lien) => {
    return this.http.post<Lien>(
      this.createCompleteRoute(route, this.config.rootUrl),
      lien,
      this.generateHeaders());
  }

  public updateLien = (route: string, lien: Lien) => {
    return this.http.put(this.createCompleteRoute(route, this.config.rootUrl), lien, this.generateHeaders());
  }
  public deleteLien = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this.config.rootUrl));
  }
  //-------------------------------

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
