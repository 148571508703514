import { Order, Party } from "projects/reg-hub-common/src/public-api";

export abstract class OrderPreviewComponent {

    protected getFirstDebtorName(order: Order): string {
        let debtor = order.parties?.find(party => party.partyType.name.includes("Debtor"));
        return this.getPartyName(debtor);
    }

    protected getFirstSecuredPartyName(order: Order): string {
        let securedParty = order.parties?.find(party => party.partyType.name.includes("Secured"));
        return this.getPartyName(securedParty);
    }

    private getPartyName(party: Party | null | undefined): string {
        let name = "";

        if(party?.busName) {
            name = party.busName;
        } else if(party?.firstName) {
            name = `${party.firstName} ${party.lastName}`;
        }

        return name;
    }

    protected getSearchCriteria(order: Order): string {
        let party = order.parties?.at(0);
        let asset = order.assets?.at(0);
        let criteria = "";
    
        if(party?.busName) {
          criteria += party.busName ?? "";
    
          if(order.jurisdiction == "QC" &&
            party.contactDetails?.address?.postalCode) {
            criteria += ` ${party.contactDetails.address.postalCode}`;
          }
        } else if(party?.firstName) {
          criteria = `${party.firstName} ${party.lastName}`;
        } else if(asset) {
          criteria = asset.serialNumber;
        }
    
        return criteria;
    }
}