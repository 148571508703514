import { Component, Input } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { ContactDetails, ContactDetailsForCreation, ContactDetailsForUpdate, eMatSelectAppearance } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
  selector: 'app-client-contact-details-card',
  templateUrl: './client-contact-details-card.component.html',
  styleUrls: ['./client-contact-details-card.component.css']
})
export class ClientContactDetailsCardComponent {
  @Input() enabled: boolean = true;
  @Input() contactDetails!: ContactDetails | ContactDetailsForCreation | ContactDetailsForUpdate;
  protected appearance: MatFormFieldAppearance = eMatSelectAppearance.outline;
  isEditing = false;

  constructor() {}

  // protected jurisdictionFormChanges(event: FormGroup) {
  //   let countryControl = event.controls['Country'];
  //   let jurisdictionControl = event.controls['Jurisdiction'];

  //   this.contactDetails!.address?.countryCode = countryControl.value;
  //   this.contactDetails!.address?.jurisdiction = jurisdictionControl.value;
  // }

  protected handleJurisdictionChangeEvent(jurisdiction: string) {
    this.contactDetails.address.jurisdiction = jurisdiction;
  }

  protected handleCountryChangeEvent(country: string) {
    this.contactDetails.address.countryCode = country;
  }
}