import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ListComponent, ConfigurationState, UserGroup, UserGroupRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-user-groups-list',
    templateUrl: './user-groups-list.component.html',
    styleUrls: ['./user-groups-list.component.css']
})
export class UserGroupsListComponent extends ListComponent<UserGroup> implements OnInit {
    hasUserManagementAccess: boolean | null = null;
    displayedColumns: string[] = ['name',
        'address',
        'billingCentre',
        'configuration'];

    constructor(repo: UserGroupRepositoryService,
        private router: Router,
        private messageService: ToastrService,
        private configurationState: ConfigurationState) {
        super(repo);
    }

    ngOnInit(): void {
        this.configurationState.hasUserManagementAccess().then(hasAccess => {
            if (hasAccess) {
                this.repo.getFirstPage();
            }
            else {
                this.messageService.error("Access Denied");
            }
        });
    }

    navigateToAddUserGroup() {
        this.router.navigate(['management', 'userGroups', 'add']);
    }
}