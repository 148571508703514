import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedRepository } from 'reg-hub-common';
import { OrderFee } from 'reg-hub-common';
import { EnvironmentUrlService } from '../environment-url/environment-url.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ClientInvoiceOrderFeesRepositoryService extends PaginatedRepository<OrderFee> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        private environmentUrl: EnvironmentUrlService) {
        super(
            http,
            messageService);

        this._currentParams = new HttpParams()
            .set('pageSize', 10);

        this._baseLinkTemplate = `${this.environmentUrl.urlAddress}/Corporations/[0]/BillingDetails/[1]/Invoices/[2]/OrderFees`;
        this._baseLink = this._baseLinkTemplate;
    }
}