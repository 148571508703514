import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Notification } from '../../interfaces/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications: Notification[] = [];
  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  private nextId = 0;

  constructor() {}

  addNotification(header: string, body: string, orderId: string) {
    const notification: Notification = {
      id: this.nextId++,
      header,
      body,
      orderId
    };

    this.notifications.push(notification);
    this.notificationsSubject.next(this.notifications); // Emit the updated notifications array

    // Optionally, remove the notification after a certain time
    setTimeout(() => {
      this.removeNotification(notification.id);
    }, 7000); // Adjust time as needed
  }

  removeNotification(id: number) {
    this.notifications = this.notifications.filter(n => n.id !== id);
    this.notificationsSubject.next(this.notifications); // Emit the updated notifications array
  }
}
