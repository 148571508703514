<div *ngIf="isSearchOrder()" class="flex-container">
    <div class="flex-item">
        <h3>Search Criteria</h3>
        <div *ngIf="isAssetSearch">
            <div class="flex-container">
                <span class="flex-item">Serial Number:</span>
                <span class="flex-item-2">{{ searchAsset?.serialNumber }}</span>
            </div>
            <div class="flex-container" *ngIf="order.jurisdiction == 'SK'">
                <span class="flex-item">Asset Type:</span>
                <span class="flex-item-2">{{ searchAsset?.assetType?.name }}</span>
            </div>
        </div>
        <div *ngIf="isDebtorSearch">
            <div class="flex-container" *ngIf="isIndividual">
                <span class="flex-item">First Name:</span>
                <span class="flex-item-2">{{ searchDebtor?.firstName }}</span>
            </div>
            <div class="flex-container" *ngIf="isIndividual">
                <span class="flex-item">Middle Name:</span>
                <span class="flex-item-2">{{ searchDebtor?.middleName }}</span>
            </div>
            <div class="flex-container" *ngIf="isIndividual">
                <span class="flex-item">Last Name:</span>
                <span class="flex-item-2">{{ searchDebtor?.lastName }}</span>
            </div>
            <div class="flex-container" *ngIf="isIndividual && searchDebtor?.dateOfBirth != null">
                <span class="flex-item">Date of Birth:</span>
                <span class="flex-item-2">{{ searchDebtor?.dateOfBirth | localizedDate:'yyyy-MM-dd' }}</span>
            </div>
            <div class="flex-container" *ngIf="!isIndividual">
                <span class="flex-item">Business Name:</span>
                <span class="flex-item-2">{{ searchDebtor?.busName }}</span>
            </div>
            <div class="flex-container" *ngIf="order.jurisdiction == 'QC'">
                <span class="flex-item">Postal Code:</span>
                <span class="flex-item-2">{{ searchDebtor?.contactDetails?.address?.postalCode }}</span>
            </div>
        </div>
    </div>
    <div class="flex-item" *ngIf="this.searchParameters != null">
        <h3>Search Parameters</h3>
        <div class="flex-container">
            <span class="flex-item">Exact Only Results:</span>
            <span class="flex-item-2">{{ searchParameters.exactOnlyResults ? "YES" : "NO" }}</span>
        </div>
        <div class="flex-container" *ngIf="searchParameters.searchFromDate != null">
            <span class="flex-item">Search From Date:</span>
            <span class="flex-item-2">{{ searchParameters.searchFromDate | localizedDate:'yyyy-MM-dd' }}</span>
        </div>
        <div class="flex-container" *ngIf="searchParameters.searchToDate != null">
            <span class="flex-item">Search To Date:</span>
            <span class="flex-item-2">{{ searchParameters.searchToDate | localizedDate:'yyyy-MM-dd' }}</span>
        </div>
    </div>
</div>