import { HttpParams } from "@angular/common/http";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { PaginatedRepository } from '../services/pagination/paginated.repository';

export abstract class ListComponent<TEntity> {

    constructor(protected repo: PaginatedRepository<TEntity>) { }

    public page(event: PageEvent) {
        this.repo.page(event);
    }

    public filter(event: HttpParams) {
        this.repo.filter(event);
    }

    public sort(event: Sort) {
        this.repo.sort(event);
    }
}