import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './reports/reports.component';
import { TemplateReportComponent } from './template-report/template-report.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    children: []
  },
  { path: 'reportTemplates/:reportTemplateID', component: TemplateReportComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }