<lib-parties-form
    partyTypeTitle="Registering Agent"
    [businessPartyType]="businessRegisteringAgent"
    [individualPartyType]="individualRegisteringAgent"
    [defaultPartyType]="defaultType"
    [party]="agent"
    [errors$]="observableErrors$"
    [showSaveButton]="false"
    [uiConfiguration]="uiConfiguration.registeringAgentsConfiguration!"
    (partySavedEvent)="handlePartySavedEvent($event)"
    (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
</lib-parties-form>