import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Subject } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';

@Component({
  selector: 'lib-order-business-report-criteria',
  templateUrl: './order-business-report-criteria.component.html',
  styleUrls: ['./order-business-report-criteria.component.css']
})
export class OrderBusinessReportCriteriaComponent implements OnInit, OnDestroy {
  order!: Order

  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) { }

  ngOnInit(): void {
      this.order = this.orderManager.currentOrder.value;
  }

  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}
