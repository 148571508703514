import { Injectable } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { ePartyType } from '../../enums';
import { Party } from '../../interfaces/parties/party';
import { Utilities } from '../../utilities';

@Injectable({
  providedIn: 'root'
})
export class PathConversionService {

  constructor() { }

  public convertPath(order: Order, path: string, showSinglePartyPath = false): string {
    let formattedPath: string;
    if(path.startsWith('Parties')) {
      formattedPath = this.convertPartyPath(order, path);
    } else if(path.startsWith('Assets')) {
      var assetPosition = this.parseEntityPosition(path);
      const asset = order.assets![assetPosition];
      formattedPath = asset ? `Asset ${assetPosition + 1}` : 'Assets';
    } else if (showSinglePartyPath === true) {
      return this.getLastPathItem(path);
    } else {
      return '';
    }

    return formattedPath.replace(/\s+/g, ' ');
  }

  protected getLastPathItem(path: string): string {
    var splitPath = path.split('/');
    var item = splitPath.at(splitPath.length - 1);

    if(item) {
      return Utilities.spacePascalCase(item).trim();
    } else {
      return path;
    }
  }

  protected convertPartyPath(order: Order, path: string): string {
    var partyPosition = this.parseEntityPosition(path);

    if(partyPosition === -1) {
      return path;
    }

    const party = order.parties?.at(partyPosition);

    if(!party) {
      return path;
    }

    if(party.partyTypeID === ePartyType.businessDebtor || party.partyTypeID === ePartyType.individualDebtor) {
      return this.buildPartyString(type => type === ePartyType.businessDebtor || type === ePartyType.individualDebtor, order, path, party, 'Debtor');
    } else if (party.partyTypeID === ePartyType.businessDealer || party.partyTypeID === ePartyType.individualDealer) {
      return this.buildPartyString(type => type === ePartyType.businessDealer || type === ePartyType.individualDealer, order, path, party, 'Dealer');
    } else if (party.partyTypeID === ePartyType.businessSecuredParty || party.partyTypeID === ePartyType.individualSecuredParty) {
      return this.buildPartyString(type => type === ePartyType.businessSecuredParty || type === ePartyType.individualSecuredParty, order, path, party, 'Secured Party');
    } else if (party.partyTypeID === ePartyType.businessRegisteringAgent || party.partyTypeID === ePartyType.individualRegisteringAgent) {
      return this.buildPartyString(type => type === ePartyType.businessRegisteringAgent || type === ePartyType.individualRegisteringAgent, order, path, party, 'Registering Agent');
    } else {
      return path;
    }
  }

  private parseEntityPosition(path: string): number {
    var splitPath = path.split('/');

    if(splitPath.length < 2 || isNaN(Number(splitPath[1]))) {
      return -1;
    }

    return Number(splitPath[1]);
  }

  private buildPartyString(checker: (input: string) => boolean, order: Order, path: string, party: Party, type: string): string {
    var parties = order.parties?.filter(party => checker(party.partyTypeID));

    if(!parties) {
      return type;
    }

    const index = parties.indexOf(party);

    if(index === -1) {
      return type;
    }

    return `${type} ${index + 1}`;
  }
}
