<h3>General Collateral</h3>

<div class="padding-25" *ngIf="uiConfiguration.showCollateralHistory">
    <lib-general-collateral-history-expandable [order]="order"></lib-general-collateral-history-expandable>
</div>

<form [formGroup]="generalCollateralDetails" class="padding">
    <div *ngIf="uiConfiguration.showSecurityInterest" class="flex-container margin-bottom-15">
        <mat-checkbox class="flex-item" name="securityInterest" formControlName="securityInterest">The security interest
            is taken
            in all of the debtor's present and after-acquired personal property.</mat-checkbox>
    </div>

    <div *ngIf="uiConfiguration.showReusableGeneralCollateral && reusableGeneralCollateral.length > 0" 
        class="flex-container">
        <mat-form-field class="flex-item-6" appearance="outline">
            <mat-select placeholder="Reusable Collateral" 
                [(ngModel)]="this.selectedReusableGeneralCollateralID" 
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let collateral of reusableGeneralCollateral" [value]="collateral.id!">
                    {{ collateral.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button
            (click)="populateReusableGeneralCollateral()"
            [disabled]="!selectedReusableGeneralCollateralID"
            color="primary"
            class="margin-top-15 padding-left-25 flex-item"
            aria-label="Save Reusable Collateral button">
            Use Reusable Collateral
        </button>
    </div>

    <div *ngIf="uiConfiguration.showCollateralValue" class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>{{ uiConfiguration.collateralValueTitle }}</mat-label>
                <textarea matInput name="general-collateral" formControlName="collateralValue"
                    placeholder="Some description of goods." [rows]="textareaRows"></textarea>
            </mat-form-field>
            <lib-error-bubble [control]="generalCollateralDetails.controls['collateralValue']"
                [message]="collateralValueError$"></lib-error-bubble>
        </div>
    </div>

    <div *ngIf="uiConfiguration.showCollateralToAdd" class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>General Collateral to Add</mat-label>
                <textarea matInput name="general-collateral-to-add" formControlName="collateralToAdd"
                    [rows]="textareaRows" placeholder="Collateral to add"></textarea>
            </mat-form-field>
            <lib-error-bubble [control]="generalCollateralDetails.controls['collateralToAdd']"
                [message]="collateralToAddError$"></lib-error-bubble>
        </div>
    </div>

    <div *ngIf="uiConfiguration.showCollateralToRemove" class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>General Collateral to Remove</mat-label>
                <textarea matInput name="general-collateral-to-remove" formControlName="collateralToRemove"
                    [rows]="textareaRows" placeholder="Collateral to remove"></textarea>
            </mat-form-field>
            <lib-error-bubble [control]="generalCollateralDetails.controls['collateralToRemove']"
                [message]="collateralToRemoveError$"></lib-error-bubble>
        </div>
    </div>
</form>