<div class="padding">
    <div class="flex-container">
        <h1 class="left-box">Invoice Details</h1>
        <span class="spacer"></span>
        <lib-document-download *ngIf="invoice?.document" 
            [documentID]="invoice!.document!.id"
            [documentName]="'Invoice Details'">
        </lib-document-download>
    </div>
    
    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                <span class="flex-item">Invoice #:</span><span class="flex-item-2">{{ invoice?.invoiceNumber }}</span>
                <span class="flex-item">Billing Centre:</span><span class="flex-item-2">{{ billingDetails?.name }}</span>
                <span class="flex-item">Billing Cycle:</span><span class="flex-item-2">{{ invoice?.billingCycle!.name }}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-container">
                <span class="flex-item">Date Opened:</span><span class="flex-item-2">{{ invoice?.dateOpened | localizedDate:'yyyy-MM-dd' }}</span>
                <span class="flex-item">Date Closed:</span><span class="flex-item-2">{{ invoice?.dateClosed | localizedDate:'yyyy-MM-dd' }}</span>
                <span class="flex-item">Date Sent:</span><span class="flex-item-2">{{ invoice?.dateExported | localizedDate:'yyyy-MM-dd' }}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-container">
                <span class="flex-item"><b>Sub-Total:</b></span><span class="flex-item-2"><b>{{ invoice?.subTotal | currency }}</b></span>
                <span class="flex-item"><b>Tax:</b></span><span class="flex-item-2"><b>{{ invoice?.tax | currency }}</b></span>
                <span class="flex-item"><b>Total:</b></span><span class="flex-item-2"><b>{{ invoice?.total | currency }}</b></span>
            </div>
            <br/>
            <app-invoice-fees></app-invoice-fees>
        </mat-card-content>
    </mat-card>
</div>