import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SelectOption } from '../../interfaces/select-option';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { eMatSelectAppearance } from '../../enums';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { RegHubCommonConfig, REG_HUB_COMMON_CONFIG } from '../../reg-hub-common-config';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { Address } from '../../interfaces/address/address';

@Component({
  selector: 'lib-jurisdiction-selection',
  templateUrl: './jurisdiction-selection.component.html',
  styleUrls: ['./jurisdiction-selection.component.css']
})
export class JurisdictionSelectionComponent implements OnInit {
  @Input() address: Address | null | undefined = null;
  @Input() countryFieldName: string = 'Country';
  @Input() jurisdictionFieldName: string = 'Jurisdiction';
  @Input() required: boolean = false;
  @Input() appearance: MatFormFieldAppearance = eMatSelectAppearance.fill
  @Input() enabled: boolean = true;
  @Input() public defaultCountry: string | null = null;
  @Input() public defaultJurisdiction: string | null = null;
  @Input() countryFieldToFill: string = 'countryCode';
  @Input() jurisdictionFieldToFill: string = 'jurisdiction';
  @Input() multiSelect: boolean = false;
  @Input() allowInternationalAddressJurisdiction: boolean = false;
  
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() jurisdictionChosenEvent = new EventEmitter<string>();
  @Output() countryChosenEvent = new EventEmitter<string>();

  countryControl = new FormControl();
  countryFilterControl = new FormControl();
  jurisdictionFilterControl = new FormControl();

  protected countries: SelectOption[] = [];
  protected jurisdictions: SelectOption[] = [];
  filteredCountries: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>(this.countries);
  filteredJurisdictions: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>(this.jurisdictions);

  countryError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  jurisdictionError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  public countrySelectionForm: FormGroup = this.formBuilder.group({});

  private selectedJurisdictions: string[] = [];
  public isAllSelected: boolean = false;

  protected shouldShowJurisdictionDropdown: boolean = true;

  constructor(
    private repo: EnumeratedTypesRepositoryService,
    private formBuilder: FormBuilder,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  ngOnInit(): void {
    this.repo.getCountryInfoAsSelectOptions(this.config.rootUrl)
      .subscribe(options => {
        this.countries = options;
        this.countries.unshift({ label: 'Clear Country', value: null })
        // Load the initial country list
        this.filteredCountries.next(this.countries.slice());

        const selectedCountry = this.address?.countryCode ?? this.defaultCountry;

        if (selectedCountry != null) {
          this.setCountrySelection(selectedCountry);
          this.countryChanged(selectedCountry);

          if (this.defaultJurisdiction) {
            this.setJurisdictionSelection(this.defaultJurisdiction);
          }
        }
      });

    let formConfig: { [key: string]: any } = {};
    if(this.required) {
      formConfig[this.countryFieldName] = [this.address?.countryCode ?? this.defaultCountry, Validators.required];
      formConfig[this.jurisdictionFieldName] = [this.address?.jurisdiction ?? this.defaultJurisdiction, Validators.required];
    } else {
      formConfig[this.countryFieldName] = [this.address?.countryCode ?? this.defaultCountry, null];
      formConfig[this.jurisdictionFieldName] = [this.address?.jurisdiction ?? this.defaultJurisdiction, null];
    }

    this.countrySelectionForm = this.formBuilder.group(formConfig);
    
    this.countrySelectionForm.valueChanges.subscribe(value => {
      if(!value[this.countryFieldName]) {
        this.jurisdictions = [];
      }
    });

    this.formReady.emit(this.countrySelectionForm);
    
    if (!this.enabled) {
      this.countrySelectionForm.disable();
    }

    // Listen for search field value changes
    this.countryFilterControl.valueChanges
      .pipe(
        debounceTime(200),
        startWith(''),
        map(search => this.filterCountries(search))
      )
      .subscribe(filteredCountries => {
        this.filteredCountries.next(filteredCountries);
      });

    this.jurisdictionFilterControl.valueChanges
      .pipe(
        debounceTime(200),
        startWith(''),
        map(search => this.filterJurisdictions(search))
      )
      .subscribe(filteredJurisdictions => {
        this.filteredJurisdictions.next(filteredJurisdictions);
        this.setJurisdictionControlUsability(filteredJurisdictions);
      });
  }

  filterCountries(value: string): SelectOption[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  filterJurisdictions(value: string): SelectOption[] {
    const filterValue = value.toLowerCase();
    return this.jurisdictions.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  protected onCountryChange(event: MatSelectChange) {
    this.countryChanged(event.value);
    this.countryChosenEvent.emit(event.value);
  }

  protected onJurisdictionChange(event: MatSelectChange) {
    const selected = event.value;

    if (selected.includes('selectAll')) {
      // If "Select All" is selected, select all jurisdictions
      this.isAllSelected = true;
      const allJurisdictions: string[] = this.jurisdictions
        .map(j => j.value)
        .filter((val): val is string => val !== 'selectAll' && val !== null);
      this.selectedJurisdictions = allJurisdictions;
      this.countrySelectionForm.get(this.jurisdictionFieldName)?.setValue(allJurisdictions);
    } else {
      // If "Select All" is deselected, or regular selections occur
      this.isAllSelected = false;
      this.selectedJurisdictions = selected;
    }

    this.jurisdictionChosenEvent.emit(event.value);
  }

  protected countryChanged(country: string) {
    const jurisdictionControl = this.countrySelectionForm.get(this.jurisdictionFieldName);
    jurisdictionControl?.reset();

    this.repo.getJurisdictionInfoAsSelectOptions(this.config.rootUrl, country)
      .subscribe(options => {
        this.jurisdictions = options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        if (!this.multiSelect) {
          this.jurisdictions.unshift({ label: 'Clear Jurisdiction', value: null })
        } else {
          this.jurisdictions.unshift({ label: 'Select All', value: 'selectAll' })
        }
        this.filteredJurisdictions.next(this.jurisdictions.slice());

        this.setJurisdictionControlUsability(this.jurisdictions);
        this.setShouldShowJurisdictionDropdown();
      });
  }

  setCountrySelection(selectedCountry: string) {
    const countryControl = this.countrySelectionForm.get(this.countryFieldName);
    const jurisdictionControl = this.countrySelectionForm.get(this.jurisdictionFieldName);

    countryControl?.setValue(selectedCountry);
    jurisdictionControl?.setValue(null);
  }

  setJurisdictionSelection(selectedJurisdiction: string) {
    const countryControl = this.countrySelectionForm.get(this.countryFieldName);
    
    if (countryControl) {
      this.countryChanged(countryControl.value);
      const jurisdictionControl = this.countrySelectionForm.get(this.jurisdictionFieldName);
  
      jurisdictionControl?.setValue(selectedJurisdiction);
    }

    if (!this.multiSelect) {
      this.selectedJurisdictions = [selectedJurisdiction]
    }

    return this.selectedJurisdictions;
  }

  setJurisdictionSelections(selectedJurisdictions: string[]) {
    const countryControl = this.countrySelectionForm.get(this.countryFieldName);
    
    if (countryControl) {
      this.countryChanged(countryControl.value);
      const jurisdictionControl = this.countrySelectionForm.get(this.jurisdictionFieldName);
      
      jurisdictionControl?.setValue(selectedJurisdictions);
    }

    return this.selectedJurisdictions = selectedJurisdictions;
  }

  private setJurisdictionControlUsability(jurisdictions: SelectOption[]) {
    this.setShouldShowJurisdictionDropdown();

    if(this.shouldShowJurisdictionDropdown && jurisdictions.length <= 1) {
      this.countrySelectionForm.get(this.jurisdictionFieldName)?.disable();
    } else {
      this.countrySelectionForm.get(this.jurisdictionFieldName)?.enable();
    }
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if(!errors) {
      return;
    }

    this.countryError$.next(errors?.filter(error => {
      var splitPath = error.path.split('/');
      return splitPath.at(splitPath.length - 1)?.includes(`${this.countryFieldToFill}`);
    }).at(0)?.userFriendlyMessage);
    
    this.jurisdictionError$.next(errors?.filter(error => {
      var splitPath = error.path.split('/');
      return splitPath.at(splitPath.length - 1)?.includes(`${this.jurisdictionFieldToFill}`);
    }).at(0)?.userFriendlyMessage);
  }

  public getSelectedJurisdictions(): string[] | string {
    return this.selectedJurisdictions;
  }

  public setShouldShowJurisdictionDropdown() {
    var selectedCountry = this.countrySelectionForm.get(this.countryFieldName)?.value;

    if(selectedCountry !== 'CA' && selectedCountry !== 'US' && this.allowInternationalAddressJurisdiction) {
      this.shouldShowJurisdictionDropdown = false;
    } else {
      this.shouldShowJurisdictionDropdown = true;
    }
  }
}
