import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ClientAuthService } from './services/auth/client-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {

  constructor(private authService: ClientAuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getRoleFromToken() ?? "";

      if (userRole == 'Client') {
        return true;
      }
      // You don't have access to this portal, log you out.
      this.authService.logOut();
      return false;
    } else {
      // this will just force a log in.
      return true;
    }
  }
}