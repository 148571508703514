<div class="flex-container">
    <div class="flex-item">
        <mat-list>
            <mat-list-item>
                <span matListItemTitle>ID</span>
                <span matListItemLine>{{ order.id }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Date Requested</span>
                <span>{{ order.dateRequested | localizedDate }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Visibility</span>
                <span matListItemLine>{{ order.isVisible ? 'Visible' :
                    'Deleted'
                    }}</span>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="flex-item">
        <mat-list class="flex-item">
            <mat-list-item>
                <span matListItemTitle>Registration Number</span>
                <span>{{ order.lien?.registrationNumber ?? 'N/A' }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Secured Party Name</span>
                <span>{{ getFirstSecuredPartyName(order) }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Debtor Name</span>
                <span>{{ getFirstDebtorName(order) }}</span>
            </mat-list-item>
        </mat-list>
    </div>
</div>