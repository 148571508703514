<div class="flex-container">
    <span class="flex-item"><b>City:</b></span>
    <span class="flex-item-2">{{ result.address?.city }}</span>

    <span class="flex-item"><b>Creation Date:</b></span>
    <span class="flex-item-2">{{ result.creationDate | localizedDate: 'yyyy-MM-dd' }}</span>

</div>

<div class="flex-container">
    <span class="flex-item"><b>Type:</b></span>
    <span class="flex-item-2">{{ result.companyType }}</span>

    <span class="flex-item"><b>Status Date:</b></span>
    <span class="flex-item-2">{{ result.statusDate | localizedDate: 'yyyy-MM-dd' }}</span>
</div>

<div *ngIf="result.businessReports && result.businessReports.length > 0" class="flex-container">
    <span class="flex-item"><b>View Previous Report:</b></span>
    <mat-form-field appearance="outline" class="flex-item-2">
        <mat-label>Previous Reports</mat-label>
        <mat-select (selectionChange)="onReportSelected($event.value)">
            <mat-option *ngFor="let report of result.businessReports" [value]="report.businessReportOrderID">
                {{ report.added | localizedDate: 'yyyy-MM-dd' }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <span class="flex-item">&nbsp;</span>
    <span class="flex-item-2">&nbsp;</span>
</div>