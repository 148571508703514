import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BillingDetails, ConfigurationState, Corporation, Invoice, OrderFee, UserGroupRepositoryService } from 'reg-hub-common';
import { InvoicesRepositoryService, CorporationRepositoryService, BillingDetailsRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-invoice-info',
    templateUrl: './invoice-info.component.html',
    styleUrls: ['./invoice-info.component.css']
})
export class InvoiceInfoComponent {
    invoice: Invoice | null = null
    billingDetails: BillingDetails | null = null
    corporation: Corporation | any = null
    total: number = 0;
    subtotal: number = 0;

    orderFees: OrderFee[] = [];
    displayedColumns: string[] = ['description', 'productCode', 'taxCode', 'amount', 'gst', 'pst', 'hst'];
    isLoadingOrderFees: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
        private invoicesRepoService: InvoicesRepositoryService,
        private billingDetailsRepoService: BillingDetailsRepositoryService,
        private corporationsRepoService: CorporationRepositoryService,
        private configurationState: ConfigurationState,
        private messageService: ToastrService,
        private router: Router) { }

    ngOnInit(): void {
        this.configurationState.hasAccountingAccess().then(hasAccountingAccess => {
            if (hasAccountingAccess) {
                this.activatedRoute.paramMap.subscribe(params => {
                    const invoiceID = params.get('invoiceID')!;
                    this.getInvoice(invoiceID);
                })
            }
            else {
                this.messageService.error("Access Denied");
            }
        });
    }

    private getInvoice = (invoiceID: string) => {
        // this wil call api/v1/corporations
        this.configurationState.getCorporationID().then(corporationID => {
            this.configurationState.getBillingDetailsID().then(billingDetailsID => {
                this.invoicesRepoService.populateBaseLink([corporationID, billingDetailsID, invoiceID]);
                this.invoicesRepoService.getInvoice(invoiceID)
                    .subscribe(invoice => {
                        this.invoice = invoice;

                        this.getBillingDetails(corporationID, billingDetailsID);
                        this.getCorporation(corporationID);
                    });
            });
        });
    }

    private getBillingDetails = (corporationID: string, billingDetailsID: string) => {
        this.billingDetailsRepoService.getBillingDetails(corporationID, billingDetailsID)
            .subscribe(billing => {
                this.billingDetails = billing;
            })
    }

    private getCorporation = (corporationID: string) => {
        this.corporationsRepoService.getCorporation(corporationID)
            .subscribe(corporation => {
                this.corporation = corporation;
            })
    }

    private calculateTotals = () => {
        this.orderFees.forEach(fee => {
            this.subtotal += fee.amount;
            this.total += (fee.amount + fee.gst + fee.pst + fee.hst);
        });
    }

    navigateToLogs() {
        this.router.navigate(['logs'], { queryParams: { tab: 'changes', entityID: this.invoice!.id, entityName: 'Invoice' } });
    }
}