<div>
    <app-invoice-list-filter></app-invoice-list-filter>
    <div class="table-container">
        <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows class="mat-elevation-z8"
            (matSortChange)="sort($event)" [class.table-loading]="this.repo.loading$ | async">

            <!-- Fee Type -->
            <ng-container matColumnDef="feeType">
                <th mat-header-cell *matHeaderCellDef> Fee Type </th>
                <td mat-cell *matCellDef="let fee"> {{fee.feeType.name}} </td>
            </ng-container>

            <!-- Product Code -->
            <ng-container matColumnDef="productCode">
                <th mat-header-cell *matHeaderCellDef> Product Code </th>
                <td mat-cell *matCellDef="let fee"> {{fee.productCode}} </td>
            </ng-container>

            <!-- Tax Code -->
            <ng-container matColumnDef="taxCode">
                <th mat-header-cell *matHeaderCellDef> Tax Code </th>
                <td mat-cell *matCellDef="let fee"> {{fee.taxCode}} </td>
            </ng-container>

            <!-- Total -->
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef>Fee Total</th>
                <td mat-cell *matCellDef="let fee">{{(fee.amount + fee.gst + fee.pst + fee.hst) | currency}}</td>
            </ng-container>

            <!-- Added -->
            <ng-container matColumnDef="added">
                <th mat-header-cell *matHeaderCellDef> Added </th>
                <td mat-cell *matCellDef="let fee"> {{fee.added | localizedDate}} </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let fee" [attr.colspan]="displayedColumns.length">
                    <div class="expanded-entity-detail" [@detailExpand]="fee == expandedFee ? 'expanded' : 'collapsed'">
                        <div class="expanded-entity-information">
                            <div class="flex-container">
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>Order</span>
                                            <a matListItemLine [routerLink]="['/orders/', fee.orderID]">{{ fee.orderID
                                                }}</a>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>Amount</span>
                                            <span matListItemLine>{{ fee.amount | currency }}</span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>GST</span>
                                            <span matListItemLine>{{ fee.gst | currency }}</span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>PST</span>
                                            <span matListItemLine>{{ fee.pst | currency }}</span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>HST</span>
                                            <span matListItemLine>{{ fee.hst | currency }}</span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <div class="flex-item">
                                    <mat-list>
                                        <mat-list-item>
                                            <span matListItemTitle>TOTAL</span>
                                            <span matListItemLine>{{ fee.amount + fee.gst + fee.pst + fee.hst | currency }}</span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let fee; columns: displayedColumns;" class="expandable-table-row"
                [class.example-expanded-row]="expandedFee === fee"
                (click)="expandedFee = expandedFee === fee ? null : fee">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>

        </table>
    </div>
    <mat-paginator *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
        [pageSizeOptions]="[10, 25, 50]" (page)="page($event)"></mat-paginator>
</div>