import { StepValidationState } from "../../../../../interfaces/step";
import { AssetSearchCriteriaComponent } from "projects/reg-hub-client/src/app/orders/asset-search-criteria/asset-search-criteria.component";
import { AssetSearchReviewComponent } from "projects/reg-hub-client/src/app/orders/asset-search-review/asset-search-review.component";
import { SearchStepper } from "../search-stepper";

export class QCAssetSearchStepper extends SearchStepper {

    constructor() {
        super();

        this.configuration = [

        ];
        
        this.steps = [
            { title: "Search Criteria", content: AssetSearchCriteriaComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: AssetSearchReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}