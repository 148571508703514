import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OrderLog } from '../../interfaces/order-logs/order-logs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from "ngx-toastr";
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { OrderLogForCreation } from '../../interfaces/order-logs/order-log-for-creation';

@Injectable({
    providedIn: 'root'
})
export class OrderLogsRepositoryService extends PaginatedRepository<OrderLog> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLink = `${this.config.rootUrl}/Logs/Orders`;
    }

    public createLog = (orderId: string, orderLog: OrderLogForCreation) => {
        return this.http.post<OrderLog>(
            this.config.rootUrl + `/Orders/${orderId}/Logs`,
            orderLog,
            this.generateHeaders()
        );
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }
}