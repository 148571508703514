<div class="padding">
    <h1 class="left-box">Product Pricing</h1>

    <mat-card>
        <mat-card-content>
            <div *ngIf="this.repo.resource$ | async as data">
                <app-service-pricing-filter></app-service-pricing-filter>

                <div class="spacer-small"></div>

                <div class="spacer-small"></div>

                <!-- Main orders table -->
                <div class="table-container" *ngIf="data">

                    <!-- Conditional paginator at the top (show only when number of orders displayed is >= 50) -->
                    <div *ngIf="data.length >= 50">
                        <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                            [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                            (page)="page($event)"></mat-paginator>
                        <div class="spacer-small"></div>
                    </div>
                    <!-- End of conditional paginator at the top -->

                    <!-- Pricing table -->
                    <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows
                        class="mat-elevation-z8" (matSortChange)="sort($event)"
                        [class.table-loading]="this.repo.loading$ | async">

                        <!-- Product Code -->
                        <ng-container matColumnDef="productCode">
                            <th mat-header-cell *matHeaderCellDef> Product Code </th>
                            <td mat-cell *matCellDef="let price"> {{price.productCode}} </td>
                        </ng-container>

                        <!-- Description -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let price"> {{price.description}} </td>
                        </ng-container>

                        <!-- Total -->
                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef> Total </th>
                            <td mat-cell *matCellDef="let price"> {{(price.amount + (price.amount *
                                price.taxRate.gstRate) + (price.amount * price.taxRate.pstRate) + (price.amount *
                                price.taxRate.hstRate)) |
                                currency}} </td>
                        </ng-container>

                        <ng-container matColumnDef="indicator">
                            <th mat-header-cell *matHeaderCellDef id="indicator"></th>
                            <td mat-cell *matCellDef="let price">
                                <lib-disclosure-indicator
                                    [isExpanded]="price == expandedPrice"></lib-disclosure-indicator>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let price" [attr.colspan]="displayedColumns.length">
                                <div class="expanded-entity-detail"
                                    [@detailExpand]="price == expandedPrice ? 'expanded' : 'collapsed'">
                                    <div class="expanded-entity-information">
                                        <div class="flex-container">
                                            <div class="flex-item">
                                                <mat-list>
                                                    <mat-list-item>
                                                        <span matListItemTitle>Amount</span>
                                                        <span matListItemLine>{{ price.amount | currency }}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                            <div class="flex-item">
                                                <mat-list>
                                                    <mat-list-item>
                                                        <span matListItemTitle>Tax Rate</span>
                                                        <span matListItemLine>{{ price.taxRate.code }}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                            <div class="flex-item">
                                                <mat-list>
                                                    <mat-list-item>
                                                        <span matListItemTitle>GST Rate</span>
                                                        <span matListItemLine>{{ price.taxRate.gstRate | percent
                                                            }}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                            <div class="flex-item">
                                                <mat-list>
                                                    <mat-list-item>
                                                        <span matListItemTitle>PST Rate</span>
                                                        <span matListItemLine>{{ price.taxRate.pstRate | percent
                                                            }}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                            <div class="flex-item">
                                                <mat-list>
                                                    <mat-list-item>
                                                        <span matListItemTitle>HST Rate</span>
                                                        <span matListItemLine>{{ price.taxRate.hstRate | percent
                                                            }}</span>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let price; columns: displayedColumns;" class="expandable-table-row"
                            [class.example-expanded-row]="expandedPrice === price"
                            (click)="expandedPrice = expandedPrice === price ? null : price">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row">
                        </tr>

                    </table>
                    <!-- End of orders table-->

                    <!-- Message while loading (show only if data.length is 0 and loading) -->
                    <div class="padding center padding-top-bottom-50"
                        *ngIf="((this.repo.loading$ | async) ?? false) && data.length === 0">
                        &nbsp;
                    </div>

                    <!-- Paginator for the bottom -->
                    <div class="spacer-small"></div>

                    <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                        [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true"
                        (page)="page($event)"></mat-paginator>
                    <!-- End of paginator for the bottom -->
                </div>
                <!-- End of main pricing table-->

                <!-- Spinner (shows over entire card) -->
                <div *ngIf="this.repo.loading$ | async" class="loading-spinner-overlay">
                    <mat-spinner></mat-spinner>
                </div>
                <!-- End of spinner -->

                <lib-placeholder [iconName]="'cancel'" [iconClass]="'icon-error'"
                    message="Error loading product pricing"
                    [condition]="(this.repo.error$ | async) ?? false"></lib-placeholder>
            </div>
        </mat-card-content>
    </mat-card>
</div>