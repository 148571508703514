import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCommonModule, REG_HUB_DATE_FORMATS, RegHubDateAdapter } from 'reg-hub-common';
import { environment } from '../../environments/environment';
import { SharedModule } from 'projects/reg-hub-admin/src/app/shared/shared.module';
import { ManagementComponent } from './management/management.component';
import { UserGroupsListComponent } from './user-groups-list/user-groups-list.component';
import { ManagementRoutingModule } from './management-routing.module';
import { UserGroupInfoComponent } from './user-group-info/user-group-info.component';
import { UsersListComponent } from './users-list/users-list.component';
import { ClientContactDetailsCardComponent } from './contact-details-card/client-contact-details-card.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddUserGroupInitialAccessComponent } from './add-user-group-initial-access/add-user-group-initial-access.component';
import { ReusablePartyListComponent } from './reusable-party-list/reusable-party-list.component';
import { ManagementOptionsComponent } from './management-options/management-options.component';
import { AddReusablePartyComponent } from './add-reusable-party/add-reusable-party.component';
import { ReussablePartyInfoComponent } from './reusable-party-info/reusable-party-info.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
    declarations: [
        ManagementComponent,
        ClientContactDetailsCardComponent,
        UserGroupsListComponent,
        UserGroupInfoComponent,
        UsersListComponent,
        UserInfoComponent,
        AddUserComponent,
        AddUserGroupInitialAccessComponent,
        ReusablePartyListComponent,
        ManagementOptionsComponent,
        AddReusablePartyComponent,
        ReussablePartyInfoComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UiCommonModule.forRoot({ rootUrl: environment.urlAddress, batchRootURL: environment.batchUrlAddress }),
        ManagementRoutingModule
    ],
    providers: [
      { provide: DateAdapter, useClass: RegHubDateAdapter},
      { provide: MAT_DATE_FORMATS, useValue: REG_HUB_DATE_FORMATS }
    ]
})
export class ManagementModule { }