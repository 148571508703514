<mat-expansion-panel hideToggle>
    <mat-expansion-panel-header class="flex-container-space-between">
        <mat-panel-title>
            History
        </mat-panel-title>
        <mat-panel-description class="flex-container-right-align">
            <mat-icon class="padding-right-15">history</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngFor="let node of history; let i = index;">
        <div class="margin-bottom-15" *ngIf="i === 0 || (node.collateralValue && node.registrationNumber)">
            <div class="light-bold margin-bottom-5">{{ i === 0 ? 'Original' : 'Replaced by registration number ' + node.registrationNumber }}:</div>
            <span *ngIf="node.collateralValue; else none" class="preserve-line-breaks">{{ node.collateralValue }}</span>
        </div>
        <div *ngIf="i > 0 && node.collateralToAdd && node.registrationNumber" class="margin-bottom-15">
            <div class="light-bold margin-bottom-5">Added by registration number {{ node.registrationNumber }}:</div>
            <span class="preserve-line-breaks">{{ node.collateralToAdd }}</span>
        </div>
        <div *ngIf="i > 0 && node.collateralToRemove && node.registrationNumber" class="margin-bottom-15">
            <div class="light-bold margin-bottom-5">Removed by registration number {{ node.registrationNumber }}:</div>
            <span class="preserve-line-breaks">{{ node.collateralToRemove }}</span>
        </div>
    </div>
    <ng-template #none>
        <span class="text-faded">No collateral found.</span>
    </ng-template>
</mat-expansion-panel>