<form *ngIf="uiConfiguration" [formGroup]="termDetails">
    <div *ngIf="uiConfiguration.showTerm && uiConfiguration.registryDefinedTerm" class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="form-field flex-item" appearance="outline">
                <mat-label>Term</mat-label>
                <input matInput [disabled]="true" [value]="uiConfiguration.registryDefinedTerm" />
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="(uiConfiguration.showTerm && uiConfiguration.showExpiryDate) ||
                (uiConfiguration.showTerm && uiConfiguration.showInfinityTerm) ||
                (uiConfiguration.showExpiryDate && uiConfiguration.showInfinityTerm)"
        class="flex-container padding-top">
        <div class="flex-item form-field">
            <mat-radio-group formControlName="selectedTermType" name="term-radio"
                aria-label="Lien term or infinite lien radio option" (change)="onTermSelectionChange($event)">
                <mat-radio-button *ngIf="uiConfiguration.showTerm" class="radio-button" name="term-selection"
                    [value]="termInYearsSelection">Term in
                    Years</mat-radio-button>
                <mat-radio-button *ngIf="uiConfiguration.showExpiryDate" class="radio-button" name="expiry-selection"
                    [value]="expiryDateSelection">Expiry
                    Date</mat-radio-button>
                <mat-radio-button *ngIf="uiConfiguration.showInfinityTerm" class="radio-button"
                    name="infinite-selection" [value]="infiniteSelection">Infinite</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div *ngIf="uiConfiguration.showTerm && selectedTermType === termInYearsSelection && !uiConfiguration.registryDefinedTerm"
        class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="flex-item form-field" appearance="outline">
                <mat-label>Term</mat-label>
                <mat-select name="term" formControlName="term">
                    <mat-option *ngFor="let length of termOptions" [value]="length.value">
                        {{ length.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <lib-error-bubble [control]="termDetails.get('term')!" [message]="termError$"></lib-error-bubble>
        </div>
    </div>

    <div *ngIf="uiConfiguration.showExpiryDate && selectedTermType === expiryDateSelection" class="flex-container">
        <div class="validated-form-field-container flex-container">
            <mat-form-field class="form-field flex-item" appearance="outline">
                <mat-label>Expiry Date</mat-label>
                <input matInput [matDatepicker]="expiryDate" name="expiry-date" formControlName="expiryDate"
                    placeholder="YYYY-MM-DD">
                <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
                <mat-datepicker #expiryDate></mat-datepicker>
            </mat-form-field>
            <lib-error-bubble [control]="termDetails.get('expiryDate')!"
                [message]="expiryDateError$"></lib-error-bubble>
        </div>
    </div>
</form>