<form [formGroup]="amendmentDetails">
    <div *ngIf="uiConfiguration.amendmentConfiguration?.showCorrectionOrChange" class="flex-item form-field">
        <div class="validated-form-field-container flex-container">
            <mat-radio-group formControlName="amendmentTypeID"
                aria-label="Change or correction amendment type option">
                <mat-radio-button name="change-selection" class="radio-button"
                    [value]="change">Change</mat-radio-button>
                <mat-radio-button name="correction-selection" class="radio-button" [value]="correction">Correction</mat-radio-button>
            </mat-radio-group>
            <lib-error-bubble [control]="amendmentDetails.controls['amendmentTypeID']"
                [message]="amendmentTypeError$"></lib-error-bubble>
        </div>
    </div>
</form>