import { Component, Input } from '@angular/core';
import { OrderPreviewComponent } from '../order-preview/order-preview-component';
import { Order } from 'projects/reg-hub-common/src/public-api';

@Component({
  selector: 'lib-default-order-preview',
  templateUrl: './default-order-preview.component.html',
  styleUrls: ['./default-order-preview.component.css']
})
export class DefaultOrderPreviewComponent extends OrderPreviewComponent {
  @Input() order!: Order;
}
