import { Component } from '@angular/core';
import { ListComponent, Order, OrderRepositoryService } from 'reg-hub-common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-orders',
  templateUrl: './search-orders.component.html',
  styleUrls: ['./search-orders.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SearchOrdersComponent extends ListComponent<Order> {

  protected _expandedOrder: Order | null = null;

  displayedColumns: string[] = [
    'referenceNumber', 
    'orderTypeID', 
    'orderStatusTypeID',
    'country',
    'jurisdiction',
    'dateRequested'
  ];

  columnsToDisplayWithView = [...this.displayedColumns, 'view', 'indicator'];

  constructor(
    repo: OrderRepositoryService,
    private router: Router) {
      super(repo);
   }

  protected viewOrder(order: Order, newTab: boolean) {
    if (newTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['orders', order.id])
      );
  
      window.open(url, '_blank');
    }
    else {
      this.router.navigate(['orders', order.id])
    }
  }
}
