import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LienDetailsUIConfiguration, Order, OrderManagerService, ValidationProblem } from 'reg-hub-common';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-on-collateral',
  templateUrl: './on-collateral.component.html',
  styleUrls: ['./on-collateral.component.css']
})
export class OnCollateralComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Input() isDisabled: boolean = false;
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() uiConfiguration!: LienDetailsUIConfiguration;
  @Output() ontarioCollateralFormValueChangedEvent = new EventEmitter<any>();

  ontarioCollateral!: FormGroup;

  collateralClassificationsError$ = new BehaviorSubject<string | null | undefined>(null);

  private onDestroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
    private orderManager: OrderManagerService) { }

  ngOnInit(): void {
    this.ontarioCollateral = this.formBuilder.group({ });

    if(this.uiConfiguration.showConsumerGoods) {
      this.ontarioCollateral.addControl('consumerGoods', this.formBuilder.control(this.order.lien?.consumerGoods ?? false));
    }

    if(this.uiConfiguration.showInventory) {
      this.ontarioCollateral.addControl('inventory', this.formBuilder.control(this.order.lien?.inventory ?? false));
    }

    if(this.uiConfiguration.showAccounts) {
      this.ontarioCollateral.addControl('accounts', this.formBuilder.control(this.order.lien?.accounts ?? false));
    }

    if(this.uiConfiguration.showEquipment) {
      this.ontarioCollateral.addControl('equipment', this.formBuilder.control(this.order.lien?.equipment ?? false));
    }

    if(this.uiConfiguration.showOther) {
      this.ontarioCollateral.addControl('other', this.formBuilder.control(this.order.lien?.other ?? false));
    }

    if(this.uiConfiguration.showMVIncluded) {
      this.ontarioCollateral.addControl('mvIncluded', this.formBuilder.control(this.order.lien?.mvIncluded ?? false));
    }

    if(this.isDisabled) {
      this.ontarioCollateral.disable();
    }
    
    this.ontarioCollateral.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.orderManager.updateLien(value);
        this.ontarioCollateralFormValueChangedEvent.emit(value);
      });
    this.errors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public pushErrors(errors: ValidationProblem[] | undefined): void {
    if(!errors) {
      return;
    }

    this.collateralClassificationsError$.next(errors.filter(error => error.path.includes('/collateralclassifications'))?.at(0)?.userFriendlyMessage);
  }
}
