import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Order, OrderManagerService, OrderUIConfiguration, UiConfigurationRequest, Validation, ValidationProblem, ValidationService } from "reg-hub-common";
import { BehaviorSubject, map, Observable, Subject, take, takeUntil, tap } from "rxjs";
import { StepValidationState } from "./step";

@Component({
    template: ''
})
export abstract class SteppedComponent implements OnInit, OnDestroy {
    @Input() public uiConfiguration!: OrderUIConfiguration;
    @Output() public triggerUiConfigurationReload = new EventEmitter<UiConfigurationRequest>();

    public validation$ = new BehaviorSubject<Validation | undefined>(undefined);

    public errors: ValidationProblem[] | undefined;
    private errors$ = new BehaviorSubject<ValidationProblem[] | undefined>(undefined)
    public observableErrors$ = this.errors$.pipe(
        map(errors => this.mapPathsToLowerCase(errors)),
        tap(errors => this.errors = errors),
        tap(errors => this.pushErrors(errors))
    );

    public warnings: ValidationProblem[] | undefined;
    private warnings$ = new BehaviorSubject<ValidationProblem[] | undefined>(undefined);
    public observableWarnings$ = this.warnings$.pipe(
        tap(warnings => this.warnings = warnings)
    );

    public order!: Order;
    public saving$!: Observable<boolean>;

    protected onDestroy$ = new Subject<void>();

    constructor(
        protected orderManager: OrderManagerService,
        protected validationService: ValidationService) { }

    /** Get validation state for stepper icons */
    public abstract getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState;

    ngOnInit(): void {
        this.orderManager.currentOrder
            .pipe(take(1))
            .subscribe(order => this.init(order));
        this.orderManager.currentOrder
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(updatedOrder => this.handleOrderUpdate(updatedOrder));
        this.observableErrors$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
    }

    /** Logic to be used on receiving initial order */
    protected init(order: Order): void {
        this.order = order;
    }

    /** Logic to be used on receiving order */
    protected handleOrderUpdate(updatedOrder: Order): void {
        this.order = updatedOrder;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.unsubscribe();
    }

    protected validate(orderToValidate: Order): void {
        this.validationService.validate(orderToValidate)
            .subscribe(results => {
                this.validation$.next(results);
                this.errors$.next(results.errors);
                this.warnings$.next(results.warnings);
            });
    }

    protected copyOrder(orderToCopy: Order): Order {
        return JSON.parse(JSON.stringify(orderToCopy));
    }

    /** Logic to be performed before update call to API */
    public onSaving(): void {

    }

    /** Logic to pass errors on to error bubbles in forms */
    public pushErrors(errors: ValidationProblem[] | undefined): void {

    }

    private mapPathsToLowerCase(problems: ValidationProblem[] | undefined): ValidationProblem[] | undefined {
        return problems?.map(problem => {
            return {
                path: problem.path.toLowerCase(),
                message: problem.message,
                userFriendlyMessage: problem.userFriendlyMessage,
                entityID: problem.entityID
            }
        });
    }
}