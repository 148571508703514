<mat-toolbar color="primary" class="top-menubar">
    <div class="top-menubar-left" [@toggleSidenav]="isSidenavOpen ? 'sidenav-open' : 'sidenav-closed'">
        <button (click)="toggleSidenav()" mat-icon-button class="padding-left-15" aria-label="icon-button with home icon.">
            <mat-icon *ngIf="isSidenavOpen" color="primary">menu</mat-icon>
            <mat-icon *ngIf="!isSidenavOpen" color="white">menu</mat-icon>
        </button>

        <button *ngIf="isSidenavOpen" (click)="navigateHome()" mat-button class="" aria-label="Home Button">
            <div class="">
                <img src="assets/reg-hub-logo.png" alt="Logo" class="logo">
            </div>
        </button>
    </div>
    
    <span class="spacer"></span>

    <div class="top-menubar-right padding-right-15">
        <!-- Content for the right part of the menubar -->
        <button mat-raised-button extended *ngIf="!userAuthenticated" [matMenuTriggerFor]="matMenuUnAuthenticated" color="accent">
            <mat-icon>account_circle</mat-icon>
            Account
        </button>
        <mat-menu #matMenuUnAuthenticated="matMenu">
            <button (click)="logIn()" mat-menu-item>Log In</button>
        </mat-menu>

        <button mat-button *ngIf="userAuthenticated" [matMenuTriggerFor]="matMenuAuthenticated" color="white">
            <mat-icon>account_circle_fill</mat-icon>
            {{ username }}
        </button>
        <mat-menu #matMenuAuthenticated="matMenu">
            <button (click)="navigateToMyAccount()" mat-menu-item>Account</button>
            <button (click)="logOut()" mat-menu-item>Log Out</button>
        </mat-menu>
    </div>
</mat-toolbar>