import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { ClientInvoiceOrderFeesRepositoryService } from '../../services/invoice-order-fees/clien-invoice-order-fees-repository.service';
import { OrderFee, FiltersComponent, SelectOption, EnumeratedTypesRepositoryService, ConfigurationState } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-invoice-list-filter',
    templateUrl: './invoice-fees-filter.component.html',
    styleUrls: ['./invoice-fees-filter.component.css'],
    providers: [DatePipe]
})
export class InvoiceFeesFilterComponent extends FiltersComponent<OrderFee> implements OnInit {
    protected autoExpand: boolean = false;
    protected feeTypes: SelectOption[] = [];

    constructor(
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private route: ActivatedRoute,
        private environmentUrl: EnvironmentUrlService,
        private configurationState: ConfigurationState,
        orderFeesRepository: ClientInvoiceOrderFeesRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe,
    ) {
        super(
            orderFeesRepository,
            formBuilder,
            datePipe);
    }

    ngOnInit(): void {
        let requests = [
            this.enumerationRepo.getFeeTypesAsSelectOptions(this.environmentUrl.urlAddress)
        ]

        forkJoin(requests).subscribe(values => {
            this.feeTypes = values[0];
        })

        this._filterForm = this.formBuilder.group({
            orderID: [null],
            isInvoiced: [null],
            feeTypeID: [null],
            productCode: [null],
            taxCode: [null],
            addedDateFrom: [null],
            addedDateTo: [null],
            amountFrom: [null],
            amountTo: [null]
        });

        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
            const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

            if (routeQueryStringParamKeys.length > 0) {
                let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                routeQueryStringParamKeys.forEach(key => {
                    const control = this._filterForm.get(key);

                    if (control) {
                        control.setValue(routeQueryStringParams[key]);
                        this._filterForm.markAsDirty();
                        this.filtered = true;
                    }
                })

                this.repo.getFirstPage(preFilteredParams);
            } else {
                this.configurationState.getCorporationID().then(corpID => {
                    this.configurationState.getBillingDetailsID().then(billingDetailsID => {
                        this.route.paramMap.subscribe(params => {
                            const invoiceID = params.get('invoiceID')!;
                            this.repo.populateBaseLink([corpID, billingDetailsID, invoiceID]);
                            this.repo.getFirstPage(this._defaultParams);
                        });
                    });
                });  
            }
        });
    }
}