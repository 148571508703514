import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Province } from '../../interfaces/province';
import { BusinessSearchResultRepositoryService } from '../../services/business-search-results/business-search-result-repository.service';
import { BusinessSearchResult } from '../../interfaces/business-search-results/business-search-result';
import { ListComponent } from '../list-component';
import { animate, query, state, style, transition, trigger, sequence } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';
import { Router } from '@angular/router';
import { eOrderType } from '../../enums';

@Component({
  selector: 'lib-business-search-results',
  templateUrl: './business-search-results.component.html',
  styleUrls: ['./business-search-results.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('loadingToLoaded', [
      transition('* => Complete', [
        sequence([
          // Animate the spinner out first
          query('.spinner-animation', [
            style({ height: '*', opacity: 1 }),
            animate('300ms ease-in', style({ height: '0', opacity: 0 }))
          ], { optional: true }),
    
          // After spinner is fully gone, animate results in
          query('.results-animation', [
            style({ height: '0', opacity: 0, overflow: 'hidden' }),
            animate('300ms ease-out', style({ height: '*', opacity: 1 }))
          ], { optional: true })
        ])
      ])
    ])
  ],
})
export class BusinessSearchResultsComponent extends ListComponent<BusinessSearchResult> implements OnInit, OnDestroy {
  @Input() showOrderReportButton: boolean = true;
  order!: Order
  businessSearchResults!: BusinessSearchResult[];
  protected _expandedResult: BusinessSearchResult | null = null;
  displayedColumns: string[] = ['name', 'number', 'jurisdiction', 'status', 'actions'];
  columnsToDisplayWithView = [...this.displayedColumns, 'indicator'];
  showFilters = false;
  protected baseOrderDetails!: FormGroup;
  protected isSaving: boolean = false;

  provinces: Province[] = [
    { value: 'AB', viewValue: 'AB' },
    { value: 'BC', viewValue: 'BC' },
    { value: 'MB', viewValue: 'MB' },
    { value: 'NB', viewValue: 'NB' },
    { value: 'NL', viewValue: 'NL' },
    { value: 'NS', viewValue: 'NS' },
    { value: 'ON', viewValue: 'ON' },
    { value: 'PE', viewValue: 'PE' },
    { value: 'QC', viewValue: 'QC' },
    { value: 'SK', viewValue: 'SK' },
    { value: 'NT', viewValue: 'NT' },
    { value: 'NU', viewValue: 'NU' },
    { value: 'YT', viewValue: 'YT' },
    { value: 'CW', viewValue: 'FED' }
  ]

  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private router: Router,
    repo: BusinessSearchResultRepositoryService
  ) {
    super(repo)
  }

  ngOnInit(): void {
    this.order = this.orderManager.currentOrder.value;
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => {
      this.order = order;
      this.repo.populateBaseLink([this.order.id]);
      this.repo.getFirstPage();
    });

    if (this.showOrderReportButton == false) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'actions');
      this.columnsToDisplayWithView = this.columnsToDisplayWithView.filter(column => column !== 'actions');
      console.log("test");
    }
  }

  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onLoadingToLoadedDone() {
    // All animations that are part of loadingToLoaded are complete
    console.log('Animation completed'); // Ensure this logs

    this.showFilters = true;
  }

  normalizeJurisdiction(jurisdiction: string) {
    if (jurisdiction == 'CD') {
      return 'CW';
    }
    return jurisdiction;
  }

  orderReport(result: BusinessSearchResult) {
    this.isSaving = true;
    const jurisdiction = this.normalizeJurisdiction(result.jurisdiction ?? '');

    this.baseOrderDetails = this.formBuilder.group({
        referenceNumber: [this.order.referenceNumber, Validators.required],
        country: [this.order.country, Validators.required],
        jurisdiction: [jurisdiction, Validators.required],
        orderTypeID: [eOrderType.businessReport, Validators.required],
        orderGroupID: [this.order.orderGroup!.id, Validators.required],
        businessSearchResultID: [result.id, Validators.required],
        businessReportCriteria: this.formBuilder.group({
            number: [result.number]
        }),
    });

    this.ordersRepo.createNewOrder(this.baseOrderDetails.value)
      .subscribe(result => {
        const originatingOrderID = this.order.id;
        this.isSaving = false;
        const order = (result as any).resource;
        this.order = order;
        this.orderManager.updateOrder(order);
        if (this.order.id) {
          this.router.navigate(['new-business-search', order.id], { 
            queryParams: { originatingOrderID: originatingOrderID } 
          });
        }
      })
  }
}
