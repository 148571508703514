import { Component, Input } from '@angular/core';
import { Order, ValidationProblem } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';

@Component({
  selector: 'app-business-search-review',
  templateUrl: './business-search-review.component.html',
  styleUrls: ['./business-search-review.component.css']
})
export class BusinessSearchReviewComponent extends SteppedComponent {
  protected override init(order: Order): void {
    super.init(order);
    this.validate(this.order);
  }
  
  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }
}
