import { Component } from '@angular/core';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { ValidationProblem, Order } from 'reg-hub-common';

@Component({
  selector: 'app-order-changes',
  templateUrl: './order-changes.component.html',
  styleUrls: ['./order-changes.component.css']
})
export class OrderChangesComponent extends SteppedComponent {
  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }
}
