<div class="padding">
    <h1 class="left-box">My Account</h1>

    <div class="flex-container">
        <mat-card class="flex-item">
            <mat-card-content>
                <div class="flex-container">
                    <label class="flex-item">Username:</label>
                    <div class="flex-item-4">{{ username }}</div>
                </div>
                <button mat-raised-button color="primary" (click)="changePassword()">Change Password</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>