import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationState } from 'reg-hub-common';

@Component({
    selector: 'app-management-options',
    templateUrl: './management-options.component.html',
    styleUrls: ['./management-options.component.css']
})
export class ManagementOptionsComponent {
    @Input() public loading: boolean = false;
    selectedIndex: number = 0;
    hasUserAccess: boolean = false;
    hasPartyAccess: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
        private configurationState: ConfigurationState
    ) {}

    ngOnInit(): void {
        this.configurationState.hasUserManagementAccess().then(userAccess => {
            this.hasUserAccess = userAccess;
            this.configurationState.hasManageReusablePartyAccess().then(partyAccess => {
                this.hasPartyAccess = partyAccess;
                this.setDefaultTab();
            });
        })
    }

    setDefaultTab() {
        var defaultTab = this.activatedRoute.snapshot.queryParams['tab'];
        if (defaultTab !== null && defaultTab !== undefined)
        {
            switch(defaultTab)
            {
                case "reusableParties":
                    this.selectedIndex = 1;
                    break;
                case "userGroups":
                default:
                    this.selectedIndex = 0;
                    
            }
        }
    }
}