<div @expandHeight *ngIf="_events.length > 0" class="padding-sides-15 container">
    <div
      (click)="closeEventNotification()"
      role="alert"
      class="error flex-item"
    >
      <h3>
        <mat-icon aria-hidden="false" aria-label="Warning">warning</mat-icon>
        {{ _eventMessage }}
      </h3>
    </div>
    <button mat-icon-button class="close-button" (click)="closeEventNotification()">
      <mat-icon>close</mat-icon>
    </button>
</div>