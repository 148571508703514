<lib-parties-form
    partyTypeTitle="Debtor"
    [businessPartyType]="businessDebtor"
    [individualPartyType]="individualDebtor"
    [defaultPartyType]="defaultType"
    [party]="debtor"
    [errors$]="observableErrors$"
    [showSaveButton]="false"
    [showClearButton]="false"
    [showDeleteButton]="false"
    [uiConfiguration]="uiConfiguration.debtorsConfiguration!"
    (partySavedEvent)="handlePartySavedEvent($event)"
    (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
</lib-parties-form>