<div class="padding">

    <app-requested-reports-filter></app-requested-reports-filter>
    <div *ngIf="this.repo.resource$ | async as data">
        <div class="table-container" *ngIf="data.length > 0">
            <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows class="mat-elevation-z8"
                (matSortChange)="sort($event)" [class.table-loading]="this.repo.loading$ | async">

                <ng-container matColumnDef="reportName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="reportName"> Report </th>
                    <td mat-cell *matCellDef="let report"> {{report.reportName}} </td>
                </ng-container>

                <ng-container matColumnDef="added">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="added"> Requested Date
                    </th>
                    <td mat-cell *matCellDef="let report"> {{report.added | localizedDate: 'yyyy-MM-dd' }} </td>
                </ng-container>

                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef id="icon"> </th>
                    <td mat-cell *matCellDef="let report">
                        <div class="flex-container-center">
                            <mat-icon *ngIf="!isComplete(report); else pending">{{ getIcon(report) }}</mat-icon>
                            <ng-template #pending>
                                <div class="spinner-inline-container">
                                    <div class="spinner-inline"> </div>
                                </div>
                            </ng-template>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="state"> State </th>
                    <td mat-cell *matCellDef="let report"> {{report.state}} </td>
                </ng-container>

                <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef id="download">
                        <div class="flex-container-center">
                            Result
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let report">
                        <div class="flex-container-center">
                            <a mat-flat-button color="primary" (click)="downloadDocument(report)"
                                [disabled]="!report.document">Download</a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let report">
                        <button mat-icon-button color="accent" (click)="onDelete(report)" aria-label="Delete Report">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>

            <div class="padding center padding-top-bottom-50"
                *ngIf="((this.repo.loading$ | async) ?? false) && data.length === 0">
                &nbsp;
            </div>

            <!-- Paginator for the bottom -->
            <div class="spacer-small"></div>

            <mat-paginator *ngIf="data.length > 0" [pageSize]="this.repo.pageSize$ | async"
                [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
                [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons="true" (page)="page($event)"></mat-paginator>
        </div>
        <lib-list-issue [repo]="this.repo" [data]="data"></lib-list-issue>
    </div>
</div>