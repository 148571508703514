import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { base64StringToBlob } from 'blob-util';
import { Observable } from 'rxjs';
import { DocumentBinary } from '../../interfaces/documents/document-binary';
import { RegHubApiResult } from '../../interfaces/reghubapi-result';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
    providedIn: 'root'
})
export class DocumentsRepositoryService {
    protected _baseLink: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${this.config.rootUrl}/Documents`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public viewDocument(documentID: string) {
        this.getDocument(documentID).subscribe(doc => {
            const blob = base64StringToBlob(doc.base64, "application/pdf");
            const blobURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobURL;
            link.click();
        });
    }

    public viewAndDownloadDocumentWithType(documentID: string, contentType: string) {
        this.getDocument(documentID).subscribe(doc => {
            const blob = base64StringToBlob(doc.base64, contentType);
            const blobURL = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobURL;
            link.download = doc!.fileName;
            link.click();
        });
    }

    public viewAndDownloadDocument(documentID: string) {
        this.viewAndDownloadDocumentWithType(documentID, "application/pdf");
    }

    public getDocument(documentID: string): Observable<DocumentBinary> {
        return this.http.get<DocumentBinary>(`${this._baseLink}/${documentID}/Base64`);
    }

    public createDocumentBinary = (document: DocumentBinary) => {
        return this.http.post<RegHubApiResult>(
            `${this._baseLink}/Base64`,
            document,
            this.generateHeaders());
    }
}