import { Component, Input } from '@angular/core';
import { Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';

@Component({
  selector: 'app-debtor-search-review',
  templateUrl: './debtor-search-review.component.html',
  styleUrls: ['./debtor-search-review.component.css']
})
export class DebtorSearchReviewComponent extends SteppedComponent {
  @Input() criteriaVariations: eCriteriaVariation[] = []

  constructor(ordermanager: OrderManagerService,
    validationService: ValidationService
  ) {
    super(ordermanager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.validate(this.order);
  }
  
  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }
}
