import { Component } from '@angular/core';
import { Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-bc-lien-details',
  templateUrl: './bc-lien-details.component.html',
  styleUrls: ['./bc-lien-details.component.css']
})
export class BCLienDetailsComponent extends BaseLienDetailsComponent {
  protected surrenderDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);
    this.order.lien!.authorizationReceived = true;
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({ }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
      form.addControl('trustIndenture', this.formBuilder.control(this.order.lien?.trustIndenture ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showSurrenderDate) {
      form.addControl('surrenderDate', this.formBuilder.control(this.order.lien?.surrenderDate));
    }

    if (this.isHistorical) {
      form.addControl('registrationNumber', this.formBuilder.control(this.order.lien?.registrationNumber))
    }

    if (this.isHistorical) {
      form.addControl('registrationDate', this.formBuilder.control(this.order.lien?.registrationDate))
    }

    return form;
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.surrenderDateError$.next(errors?.filter(error => error.path.includes('/surrenderdate'))?.at(0)?.userFriendlyMessage);
  }
}