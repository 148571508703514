import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DocumentBinary} from '../../interfaces/documents/document-binary';
import { RegHubApiResult } from '../../interfaces/reghubapi-result';
import { SearchResult } from '../../interfaces/search-results/search-result';
import { SearchResultForUpdate } from '../../interfaces/search-results/search-result-for-update';
import { SearchResultForCreation } from '../../interfaces/search-results/search-result-for-creation';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';


@Injectable({
    providedIn: 'root'
})
export class SearchResultsRepositoryService {
    protected _baseLink: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${config.rootUrl}/Orders`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getSearchResult = (orderID: string) => {
        return this.http.get<SearchResult>(`${this._baseLink}/${orderID}/SearchResults`, this.generateHeaders());
    }

    public getSearchResultDocument = (orderID: string) => {
        return this.http.get<DocumentBinary>(`${this._baseLink}/${orderID}/SearchResults/Document`, this.generateHeaders());
    }

    public getSearchResultSummaryDocument = (orderID: string) => {
        return this.http.get<DocumentBinary>(`${this._baseLink}/${orderID}/SearchResults/Summary`, this.generateHeaders());
    }

    public getSearchResultRawDocument = (orderID: string) => {
        return this.http.get<DocumentBinary>(`${this._baseLink}/${orderID}/SearchResults/Raw`, this.generateHeaders());
    }

    public createSearchResult = (orderID: string, searchResult: SearchResultForCreation) => {
        return this.http.post<RegHubApiResult>(`${this._baseLink}/${orderID}/SearchResults`, searchResult, this.generateHeaders());
    }

    public updateSearchResult = (orderID: string, searchResult: SearchResultForUpdate) => {
        return this.http.put<RegHubApiResult>(`${this._baseLink}/${orderID}/SearchResults`, searchResult, this.generateHeaders());
    }
}