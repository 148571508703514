<div>
    <table *ngIf="(observableAssets$ | async)?.length ?? 0 > 0; else noAssets" mat-table [dataSource]="observableAssets$" multiTemplateDataRows>
        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ getNumber(asset) }}. </td>
        </ng-container>

        <ng-container matColumnDef="serial-number">
            <th mat-header-cell *matHeaderCellDef> Serial Number </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.serialNumber === '' ? 'New Asset' : asset.serialNumber }} </td>
        </ng-container>

        <ng-container matColumnDef="asset-type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.assetTypeID ? getAssetTypeString(asset) : '' }} </td>
        </ng-container>

        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef> Year </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.year }} </td>
        </ng-container>

        <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef> Make </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.make }} </td>
        </ng-container>

        <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef> Model </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.model }} </td>
        </ng-container>

        <ng-container matColumnDef="manufactured-home-registration-number">
            <th mat-header-cell *matHeaderCellDef> Manufactured Home Reg Num </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.manufacturedHomeRegistrationNumber }} </td>
        </ng-container>

        <ng-container matColumnDef="colour">
            <th mat-header-cell *matHeaderCellDef> Colour </th>
            <td mat-cell *matCellDef="let asset" (click)="onEditButtonClick(asset)"> {{ asset.color }} </td>
        </ng-container>

        <ng-container matColumnDef="actions" class="action-buttons-column">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let asset; let i = index">
                <!-- <div>
                    <button mat-icon-button color="primary" (click)="onEditButtonClick($event, asset)" aria-label="Edit asset button">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="onDeleteButtonClick($event, asset)" aria-label="Delete asset button">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div> -->
                <button color="primary" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditButtonClick(asset)" aria-label="Edit dealer">
                        <mat-icon color="primary">edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="onCopyButtonClick($event, asset)" aria-label="Copy dealer">
                        <mat-icon color="primary">content_copy</mat-icon>
                        <span>Copy</span>
                    </button>
                    <button mat-menu-item (click)="onDeleteButtonClick($event, asset)" aria-label="Delete dealer">
                        <mat-icon color="warn">delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let asset" [attr.colspan]="displayedColumns.length">
                <div class="expanded-entity-detail"
                    [@detailExpand]="asset == expandedAsset ? 'expanded' : 'collapsed'">
                    <div class="expanded-entity-information">
                        <div class="flex-container">
                            <lib-handle-asset
                                [order]="this.order"
                                [asset]="asset"
                                [uiConfiguration]="uiConfiguration"
                                [errors$]="errors$"
                                (assetSavedEvent)="handleAssetUpdateEvent($event)"
                                (assetRemovedEvent)="handleAssetRemovedEvent($event)"
                                (assetFormValueChangedEvent)="handleAssetFormValueChangedEvent($event)"
                                class="flex-item">
                            </lib-handle-asset>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let asset; columns: displayedColumns;" class="expandable-table-row"
            [class.example-expanded-row]="expandedAsset === asset">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
    </table>

    <ng-template #noAssets>
        <div class="margin-bottom-25 margin-top-25 text-faded" [ngClass]="isEditable ? 'flex-container-center' : ''">
            <span class="padding-sides-5">No assets found. </span><span *ngIf="isEditable">Please use the + button below to add
                some.</span>
        </div>
    </ng-template>

    <div *ngIf="isEditable" class="flex-container front margin-bottom-25">
        <div class="spacer"></div>
        <button (click)="addNewAsset()" mat-mini-fab color="primary" class="translate-down" aria-label="Example icon button with a menu icon">
        <mat-icon>add</mat-icon>
        </button>
        <div class="spacer"></div>
    </div>

    <div *ngIf="isLoading">
        <mat-spinner class="center"></mat-spinner>
    </div>
</div>