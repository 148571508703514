import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemEvent, SystemEventsRepositoryService } from 'reg-hub-common';
import { ListComponent } from "reg-hub-common";

@Component({
    selector: 'app-system-events-history',
    templateUrl: './system-events-history.component.html',
    styleUrls: ['./system-events-history.component.css']
})
export class SystemEventsHistoryComponent extends ListComponent<SystemEvent> {
    displayedColumns: string[] = [
        'systemEventTypeID',
        'message',
        'startDate',
        'endDate',
    ];

    constructor(
        repo: SystemEventsRepositoryService) {
        super(repo);
    }
}