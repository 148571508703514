import { Component, ViewChild } from '@angular/core';
import { OrderManagerService, eQCFormType, SigningLocationComponent, ValidationService, Order, Amendment, eAmendmentType } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { AmendmentDetailsComponent } from '../../amendment-details/amendment-details.component';

@Component({
  selector: 'app-qc-lien-details',
  templateUrl: './qc-lien-details.component.html',
  styleUrls: ['./qc-lien-details.component.css']
})
export class QCLienDetailsComponent extends BaseLienDetailsComponent {
  @ViewChild(SigningLocationComponent) signingLocationComponent!: SigningLocationComponent;
  @ViewChild(AmendmentDetailsComponent) amendmentDetailsComponent!: AmendmentDetailsComponent;

  protected isRHa: boolean = false;

  private orderToValidate: Order | null = null;

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    var qcFormTypeID = this.order.lien!.qcFormTypeID as unknown;
    const qcFormTypeIDEnum = eQCFormType[(qcFormTypeID as string) as keyof typeof eQCFormType];

    this.isRHa = qcFormTypeIDEnum == eQCFormType.RHa;

    this.lienDetails.get('sumOfHypothec')?.disable();
    this.lienDetails.get('amount')?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(amount => this.calculateSumOfHypothec(amount));

    this.calculateSumOfHypothec(this.order.lien?.amount);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningCountry) {
      this.lienDetails.setControl('signingCountry', this.signingLocationComponent.signingLocation.get('signingCountry'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningJurisdiction) {
      this.lienDetails.setControl('signingJurisdiction', this.signingLocationComponent.signingLocation.get('signingJurisdiction'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningCity) {
      this.lienDetails.setControl('signingCity', this.signingLocationComponent.signingLocation.get('signingCity'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningDate) {
      this.lienDetails.setControl('signingDate', this.signingLocationComponent.signingLocation.get('signingDate'));
    }
  }

  updateSigningLocation(order: Order) {
    if (this.signingLocationComponent) {
      order.lien!.signingDate = this.signingLocationComponent.signingDate;
      order.lien!.signingCity = this.signingLocationComponent.signingCity;
      order.lien!.signingJurisdiction = this.signingLocationComponent.signingJurisdiction;
      order.lien!.signingCountry = this.signingLocationComponent.signingCountry;
    }
  }

  calculateSumOfHypothec(amount: any) {
    if (this.isRHa) {
      if (amount !== null && !isNaN(this.order.lien!.amount!)) {
        const amountAsNumber = Number(amount);
        const sumOfHypothecAmount = amountAsNumber * 1.2;

        //MARK: If Sum of Hypothec wording changes in the GenerateSumOfHypothec step in the API, this will also need to be updated.
        this.lienDetails.get('sumOfHypothec')!.setValue(`${amountAsNumber.toLocaleString('fr-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} $ CAD avec intérêts de au taux de 20% l'an et incluant une hypothèque additionnelle de ${sumOfHypothecAmount.toLocaleString('fr-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} $ (20%) CAD.`);
      }
      else {
        this.lienDetails.get('sumOfHypothec')!.setValue(null);
      }
    }
  }

  handleSigningLocationFormValueChangedEvent() {
    this.updateSigningLocation(this.order);

    if (!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    this.validate(this.orderToValidate!);
  }

  handleAmendmentDetailsFormChangedEvent(amendment: Amendment) {
    if (!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    this.validate(this.orderToValidate!);
  }

  override onSaving(): void {
    super.onSaving();
    this.updateSigningLocation(this.order);

    if (this.uiConfiguration.amendmentConfiguration?.showCorrectionOrChange) {
      this.amendmentDetailsComponent.updateAmendment(this.order);
    }
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({});

    if (this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSumOfHypothec) {
      form.addControl('sumOfHypothec', this.formBuilder.control(this.order.lien?.sumOfHypothec));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    return form;
  }
}