import { Component, ViewChild } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { EmptyEntityService, ePartyType, Order, OrderManagerService, PartiesFormComponent, Party, PartyRepositoryService, UIConfigurationService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { PartiesComponent } from '../parties/parties.component';

@Component({
  selector: 'app-secured-party',
  templateUrl: './secured-party.component.html',
  styleUrls: ['./secured-party.component.css']
})
export class SecuredPartyComponent extends PartiesComponent {
  @ViewChild(PartiesFormComponent) securedPartyForm!: PartiesFormComponent;

  protected businessSecuredParty = ePartyType.businessSecuredParty;
  protected individualSecuredParty = ePartyType.individualSecuredParty;
  protected defaultType = ePartyType.businessSecuredParty;

  protected securedParty!: Party;

  constructor(
    partyRepository: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService,
    private emptyEntityService: EmptyEntityService) { 
    super(partyRepository, uiConfigurationService, orderManager, validationService);
  }

  public override init(order: Order): void {
    super.init(order);

    this.securedParty = order.parties?.find(party => Constants.isSecuredParty(party.partyTypeID)) || this.emptyEntityService.getEmptyParty(this.defaultType);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }

  protected override getParties(): Party[] {
    return this.order.parties!.filter(party => Constants.isSecuredParty(party.partyTypeID));
  }

  public override onSaving(): void {
    const securedParty = this.securedPartyForm.getParty();
    this.order.securedParty = securedParty;

    const nonSecuredParties = this.order.parties?.filter(party => party.partyTypeID !== ePartyType.businessSecuredParty && party.partyTypeID !== ePartyType.individualSecuredParty);
    this.order.parties = nonSecuredParties;
    this.order.parties?.push(securedParty);

    this.orderManager.updateOrder(this.order);
  }
}
