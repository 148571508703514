import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../interfaces/users/user';
import { UserForCreation } from '../../interfaces/users/user-for-creation';
import { SelectOption } from '../../interfaces/select-option';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';


@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService extends PaginatedRepository<User> {

  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      super(
        http,
        messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/UserGroups/[1]/Users`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
      return {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
    }

  // CRUD Operations ---------------
  public getUser = (corporationID: string, userGroupID: string, userID: string) => {
    // Get Users
    this.populateBaseLink([corporationID, userGroupID]);
    
    return this.http.get<User>(`${this._baseLink}/${userID}`);
  }

  public adminGetUser = (userID: string) => {
    // Get Users
    return this.http.get<User>(`${this.config.rootUrl}/Users/${userID}`);
  }

  public getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>(`${this.config.rootUrl}/User`);
  }

  public getUsers = (corporationID: string, userGroupID: string) => {
    this.populateBaseLink([corporationID, userGroupID]);

    return this.http.get<User[]>(this._baseLink);
  }

  public createUser = (corporationID: string, userGroupID: string, user: UserForCreation) => {
    this.populateBaseLink([corporationID, userGroupID]);

    return this.http.post<User>(
      this._baseLink,
      user,
      this.generateHeaders());
  }

  public updateUser = (corporationID: string, userGroupID: string, user: User) => {
    this.populateBaseLink([corporationID, userGroupID]);

    return this.http.put(`${this._baseLink}/${user.id}`, user, this.generateHeaders());
  }

  public deleteUser = (corporationID: string, userGroupID: string, userID: string) => {
    this.populateBaseLink([corporationID, userGroupID]);

    return this.http.delete(`${this._baseLink}/${userID}`);
  }

  public getUsersAsSelectOptions(corporationID: string, userGroupID: string): Observable<SelectOption[]> {
    this.populateBaseLink([corporationID, userGroupID]);

    return this.http.get<SelectOption[]>(`${this._baseLink}/SelectOptions`);
  } 
}
