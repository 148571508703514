import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';

// This should keep us from losing a day on parsed dates in datepickers.
@Injectable()
export class RegHubDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    const parsedDate = super.parse(value);
    if (parsedDate) {
      parsedDate.setHours(parsedDate.getHours() + 10);
    }
    return parsedDate;
  }

  // Format the date, according to CUSTOM_DATE_FORMATS
  override format(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-based month
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
}

export const REG_HUB_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};