import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService, Order } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../environment-url/environment-url.service';
import { ClientAuthService } from '../../auth/client-auth.service';
import { orderStatusTypeLookup } from 'reg-hub-common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OrderHubService {
  private isConnectionStarted = false;

  private hubUrl = "";
  private hubConnection!: signalR.HubConnection;

  private completedOrder = 'CompletedOrder';

  // Subjects for order events
  private completedOrderSubject = new Subject<Order>();
  public completedOrder$ = this.completedOrderSubject.asObservable();

  constructor(environmentUrl: EnvironmentUrlService,
              clientAuthService: ClientAuthService,
              private notificationService: NotificationService,
              private router: Router
  ) {
    this.hubUrl = environmentUrl.urlAddress + '/orderHub';
  }

  public startConnection(clientAuthService: ClientAuthService) {
    if (this.isConnectionStarted) {
      console.log('SignalR connection already started.');
      return;
    }
    this.isConnectionStarted = true;
  
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => clientAuthService.getAccessToken() ?? ''
      })
      .withAutomaticReconnect()
      .build();
  
    this.hubConnection
      .start()
      .then(() => console.log('SignalR connection established.'))
      .catch(err => {
        console.error('Error establishing SignalR connection: ', err);
        this.isConnectionStarted = false; // Reset flag on failure
      });
  }

  public registerOnCompletedOrderEvents() {
    this.hubConnection.on(this.completedOrder, (completedOrder) => {
      console.log('Order completed:', completedOrder);
      const currentUrl = this.router.url; 
      const completedOrderUrl = `/orders/${completedOrder.id}`;

      if (currentUrl === completedOrderUrl) {
        this.completedOrderSubject.next(completedOrder);
      } else {
        this.notificationService.addNotification(
          "Order Completed", 
          `A recently requested order has completed.`, 
          completedOrder.id
        );
      }

      this.leaveOrderGroup(completedOrder.id);
    });
  }

  public joinOrderGroup(orderId: string): Promise<void> {
    if (this.hubConnection?.state !== signalR.HubConnectionState.Connected) {
      console.error('HubConnection is not connected. Cannot join group.');
      return Promise.reject('HubConnection is not connected.');
    }
  
    return this.hubConnection.invoke('JoinOrderGroup', orderId)
      .then(() => console.log(`Successfully joined group for order ${orderId}`))
      .catch(err => console.error(`Error joining order group: ${err}`));
  }

  public leaveOrderGroup(orderId: string): Promise<void> {
    if (this.hubConnection?.state !== signalR.HubConnectionState.Connected) {
      console.error('HubConnection is not connected. Cannot leave group.');
      return Promise.reject('HubConnection is not connected.');
    }

    return this.hubConnection.invoke('LeaveOrderGroup', orderId)
      .then(() => console.log(`Successfully left group for order ${orderId}`))
      .catch(err => console.error(`Error leaving order group: ${err}`));
  }
}
