import { Component, Input, OnInit } from '@angular/core';
import { FiltersComponent, ProductPricing, SelectOption } from 'reg-hub-common';
import { EnumeratedTypesRepositoryService } from 'reg-hub-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { DisbursementPricingRepositoryService } from '../../services/disbursement-pricing/disbursement-pircing.service';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-disbursement-pricing-filter',
    templateUrl: './disbursement-pricing-filter.component.html',
    styleUrls: ['./disbursement-pricing-filter.component.css'],
    providers: [DatePipe]
})
export class DisbursementPricingFilterComponent extends FiltersComponent<ProductPricing> implements OnInit {
    @Input() isActiveDefault: boolean = true;
    protected _orderTypes: SelectOption[] = [];

    constructor(
        repo: DisbursementPricingRepositoryService,
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private environment: EnvironmentUrlService,
        formBuilder: FormBuilder,
        datePipe: DatePipe
    ) {
        super(repo, formBuilder, datePipe);
    }

    ngOnInit(): void {
        let requests = [
            this.enumerationRepo.getOrderTypesAsSelectOptions(this.environment.urlAddress),
        ]
      
        forkJoin(requests).subscribe(values => {
            this._orderTypes = values[0]
        })

        this._filterForm = this.formBuilder.group({
            orderTypeID: [null],
            jurisdiction: [null]
        });

        this._defaultParams = new HttpParams().set('isActive', this.isActiveDefault);
        this._defaultParams = this._defaultParams.append('feeTypeID','Disbursement');
        
        this.repo.getFirstPage(this._defaultParams);
    }

    protected jurisdictionFormChanges(event: FormGroup) {
        this._filterForm.setControl('jurisdiction', event);
      }
}