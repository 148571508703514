import { Injectable } from '@angular/core';
import { eAssetType, ePartyType } from '../../enums';
import { Party } from '../../interfaces/parties/party';
import { Asset } from '../../interfaces/assets/asset';

@Injectable({
  providedIn: 'root'
})
export class EmptyEntityService {

  constructor() { }

  public getEmptyParty(type: ePartyType, orderId: string = ''): Party {
    return {
      id: "",
      added: new Date(),
      orderID: orderId,
      busName: "",
      busNumber: "",
      firstName: "",
      middleName: "",
      lastName: "",
      isEstate: null,
      dateOfBirth: null,
      priority: 0,
      reusableRegistryCode: null,
      partyTypeID: type,
      partyType: {
        id: 0,
        name: ""
      },
      generationID: null,
      contactDetails: {
        id: "",
        email: "",
        phoneNumber: "",
        faxNumber: "",
        address: {
          id: "",
          streetName: "",
          streetNumber: "",
          unit: "",
          city: "",
          jurisdiction: "",
          countryCode: "",
          postalCode: ""
        }
      },
      externalEntity: null,

      isIndividual() {
        return this.partyTypeID.toString().includes('individual');
      },
      getName() {
        if (this.isIndividual()) {
          if (this.middleName == null || this.middleName == "") {
            return this.firstName + " " + this.lastName;
          } else {
            return this.firstName + " " + this.middleName + " " + this.lastName;
          }
        } else {
          return this.busName ?? "";
        }
      }
    }
  }

  public getEmptyAsset(orderId?: string): Asset {

    const asset = {
      id: '',
      added: new Date(),
      serialNumber: '',
      make: '',
      model: '',
      color: '',
      year: null,
      priority: 0,
    } as Asset;

    if(orderId) {
      asset.orderID = orderId;
    }

    return asset;
  }

  public getEmptyAssetWithType(type: eAssetType, orderId?: string) {
    const asset = this.getEmptyAsset(orderId);

    asset.assetTypeID = type;
    asset.assetType = {
      id: type as number,
      name: ''
    }

    return asset;
  }
}
