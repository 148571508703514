<table mat-table [dataSource]="this.order.assets!">
    <ng-container matColumnDef="serial-number">
        <th mat-header-cell *matHeaderCellDef> Serial Number </th>
        <td mat-cell *matCellDef="let asset"> {{asset.serialNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="asset-type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let asset"> {{asset.assetTypeID}} </td>
    </ng-container>

    <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef> Make </th>
        <td mat-cell *matCellDef="let asset"> {{asset.make}} </td>
    </ng-container>

    <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell *matCellDef="let asset"> {{asset.model}} </td>
    </ng-container>

    <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef> Year </th>
        <td mat-cell *matCellDef="let asset"> {{asset.year}} </td>
    </ng-container>

    <ng-container matColumnDef="colour">
        <th mat-header-cell *matHeaderCellDef> Colour </th>
        <td mat-cell *matCellDef="let asset"> {{asset.color}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>