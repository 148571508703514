import { Component } from '@angular/core';
import { OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-acol-lien-details',
  templateUrl: './acol-lien-details.component.html',
  styleUrls: ['./acol-lien-details.component.css']
})
export class ACOLLienDetailsComponent extends BaseLienDetailsComponent {
  dateOfLienError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({}) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showDateOfLien) {
      form.addControl('dateOfLien', this.formBuilder.control(this.order.lien?.dateOfLien));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    return form;
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.dateOfLienError$.next(errors?.filter(error => error.path.includes('/dateoflien'))?.at(0)?.userFriendlyMessage);
  }
}