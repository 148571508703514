<form [formGroup]="addressDetails">
    <div>
        <h3>Address</h3>
        <div class="flex-container">
            <div class="validated-form-field-container flex-container padding-sides-5">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Street Number</mat-label>
                    <input matInput name="street-number" [formControlName]="'streetNumber'"
                        placeholder="Street Number" />
                </mat-form-field>
                <lib-error-bubble [control]="addressDetails.controls['streetNumber']"
                    [message]="streetNumberError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Street Name</mat-label>
                    <input matInput name="street-name" [formControlName]="'streetName'" placeholder="Street Name" />
                </mat-form-field>
                <lib-error-bubble [control]="addressDetails.controls['streetName']"
                    [message]="streetNameError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Unit</mat-label>
                    <input matInput name="street-unit" [formControlName]="'unit'" placeholder="Unit" />
                </mat-form-field>
                <lib-error-bubble [control]="addressDetails.controls['unit']" [message]="unitError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>City</mat-label>
                    <input matInput name="city" [formControlName]="'city'" placeholder="City" />
                </mat-form-field>
                <lib-error-bubble [control]="addressDetails.controls['city']" [message]="cityError$"></lib-error-bubble>
            </div>

            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Postal Code</mat-label>
                    <input matInput name="postal-code" [formControlName]="'postalCode'" placeholder="Postal Code" />
                </mat-form-field>
                <lib-error-bubble [control]="addressDetails.controls['postalCode']"
                    [message]="postalCodeError$"></lib-error-bubble>
            </div>
        </div>

        <lib-jurisdiction-selection
            [allowInternationalAddressJurisdiction]="uiConfiguration?.allowInternationalAddressJurisdiction ?? false"
            [appearance]="'outline'" [countryFieldName]="'Country'" [jurisdictionFieldName]="'Jurisdiction'"
            [defaultCountry]="'CA'" [address]="address">
        </lib-jurisdiction-selection>
    </div>
</form>