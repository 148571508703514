<div [@slideDown]="showNotices ? 'visible' : 'hidden'" class="validation-problems notices">
    <span class="heading"><mat-icon class="icon">info</mat-icon><h4>Notices</h4></span>
    <ul *ngFor="let notice of validation?.notices">
        <li *ngIf="notice.userFriendlyMessage">{{ getMessage(notice) }}</li>
    </ul>
</div>

<div [@slideDown]="showWarnings ? 'visible' : 'hidden'" class="validation-problems warnings">
    <span class="heading"><mat-icon class="icon">warning</mat-icon><h4>Warnings</h4></span>
    <ul *ngFor="let warning of validation?.warnings">
        <li *ngIf="warning.userFriendlyMessage">{{ getMessage(warning) }}</li>
    </ul>
</div>

<div [@slideDown]="showErrors ? 'visible' : 'hidden'" class="validation-problems errors">
    <span class="heading"><mat-icon class="icon">error</mat-icon><h4>Errors</h4></span>
    <ul *ngFor="let error of validation?.errors">
        <li *ngIf="error.userFriendlyMessage">{{ getMessage(error) }}</li>
    </ul>
</div>