import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { Notification, NotificationService } from 'reg-hub-common';
import { ClientAuthService } from '../../services/auth/client-auth.service';
import { NavigationHistoryService } from '../../services/navigation-history-service/navigation-history.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.css']
})
export class WrapperComponent implements OnInit, OnDestroy {
  isSidenavOpen = true;
  notifications: Notification[] = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    private authService: ClientAuthService,
    private router: Router,
    private notificationService: NotificationService,
    private navigationService: NavigationHistoryService) { }

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });

    this.router.events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.navigationService.add(event.urlAfterRedirects);
        }
    });
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
