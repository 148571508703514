import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Discharge } from '../../interfaces/discharges/discharge';
import { DischargeDetailsUIConfiguration } from '../../interfaces/ui-configurations/discharge-details-ui-configuration';

@Component({
  selector: 'lib-discharge-details',
  templateUrl: './discharge-details.component.html',
  styleUrls: ['./discharge-details.component.css']
})
export class DischargeDetailsComponent {
  @Input() order!: Order;
  @Input() uiConfiguration!: DischargeDetailsUIConfiguration;
}