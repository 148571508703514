import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { SystemEvent, SystemEventForCreation } from '../../interfaces/system_events/system-event';

@Injectable({
  providedIn: 'root'
})
export class SystemEventsRepositoryService extends PaginatedRepository<SystemEvent> {

  protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(http, messageService);

    this._baseLink = `${this.config.rootUrl}/SystemEvents`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public getSystemEvent = (systemEventID: string) => {
    return this.http.get<SystemEvent>(`${this._baseLink}/${systemEventID}`, this.generateHeaders());
  }

  public createSystemEvent(newEvent: SystemEventForCreation): Observable<SystemEvent> {
    return this.http.post<SystemEvent>(`${this._baseLink}`, newEvent);
  }

  public updateSystemEvent(systemEventID: string, systemEvent: SystemEventForCreation): Observable<SystemEvent> {
    return this.http.put<SystemEvent>(`${this._baseLink}/${systemEventID}`, systemEvent);
  }
}
