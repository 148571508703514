<h3>{{ isFormEnabled ? "Enter Search Criteria" : "Search Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'businessSearchCriteriaTypeID'" name="business-search-criteria-type"
                    aria-label="Name or Number Search radio option">
                    <mat-radio-button class="radio-button" [value]="nameSearch">Name Search</mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="numberSearch">Number Search</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="flex-container">
            <div *ngIf="displayNameSearchFields" class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput [formControlName]="'name'" name="name" placeholder="Name">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('name')!"
                    [message]="nameError$"></lib-error-bubble>
            </div>
        
            <div *ngIf="displayNumberSearchFields" class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Number</mat-label>
                    <input matInput [formControlName]="'number'" name="number" placeholder="Number">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('number')!"
                    [message]="numberError$"></lib-error-bubble>
            </div>
        </div>
        <mat-expansion-panel
            [class.mat-elevation-z1]="!panel.expanded" [class.mat-elevation-z0]="panel.expanded"
            #panel="matExpansionPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>Show results from</h4>
                    <span class="spacer"></span>
                    <mat-chip-set *ngIf="getSelectedJurisdictions().length == jurisdictions.length">
                        <mat-chip class="jurisdiction-chip">
                            All Jurisdictions
                        </mat-chip>
                    </mat-chip-set>
                    <mat-chip-set *ngIf="getSelectedJurisdictions().length != jurisdictions.length">
                        <mat-chip *ngFor="let selected of getSelectedJurisdictions()" class="jurisdiction-chip">
                            {{ getChipLabel(selected) }}
                        </mat-chip>
                    </mat-chip-set>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <button color="primary" [disabled]="getSelectedJurisdictions().length == jurisdictions.length" class="padding-bottom" mat-stroked-button (click)="selectAllToggles()">Select All</button>
            <button [disabled]="getSelectedJurisdictions().length == 0" mat-stroked-button (click)="deselectAllToggles()" class="padding-left-5">Deselect All</button>

            <div class="jurisdiction-container padding-top">
                <div *ngFor="let jurisdiction of jurisdictions" class="jurisdiction-item">
                    <mat-slide-toggle
                        [checked]="isChecked(jurisdiction.jurisdictionCode)"
                        (change)="onCheckedChange($event, jurisdiction)">
                        &nbsp;&nbsp;&nbsp;{{ getSlideToggleLabel(jurisdiction.jurisdictionFullName) }}
                    </mat-slide-toggle>
                </div>
            </div>
            <lib-error-bubble [control]="searchCriteriaDetails.get('searchFromDate')!"
                [message]="jurisdictionsError$"></lib-error-bubble>
            <br />
        </mat-expansion-panel>
    </form>
</div>