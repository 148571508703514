import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ListComponent, UserGroup, User, UserRepositoryService } from 'reg-hub-common';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.css']
})
export class UsersListComponent extends ListComponent<User> implements OnChanges {
    @Input() userGroup!: UserGroup;
    activeUsers: boolean = true;

    displayedColumns: string[] = ['userName',
    'name',
    'email',
    'type',
    'configuration'];
    
    constructor(repo: UserRepositoryService,
        private router: Router) {
          super(repo);
    }

    ngOnChanges(){
        this.getUsers();
    }

    navigateToAddUser() {
        this.router.navigate(['management', 'userGroups', this.userGroup.id, 'users', 'add']);
    }

    getUsers() {
        if (this.userGroup != null) {
            var filterParams = new HttpParams().set('IsActive', this.activeUsers);
            this.repo.populateBaseLink([this.userGroup!.corporationID, this.userGroup!.id]);
            this.repo.getFirstPage(filterParams);
        }
    }
}