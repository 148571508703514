import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { UserGroupAccess } from '../../interfaces/user-group-access';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class UserGroupAccessRepositoryService {

  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {}

  // CRUD Operations ---------------
  public getUserGroupAccess = (route: string, userGroupAccessID: string) => {
    return this.http.get<UserGroupAccess>(
      this.createCompleteRoute(route + "/" + userGroupAccessID, this.config.rootUrl));
  }

  public getUserGroupAccesses = (route: string) => {
    return this.http.get<UserGroupAccess[]>(
      this.createCompleteRoute(route, this.config.rootUrl));
  }

  public getReadableUserAccesses = () => {
      return this.http.get<UserGroupAccess[]>(
        this.createCompleteRoute(`UserGroupAccess?CanRead=true`, this.config.rootUrl)
      );
  }

  public createUserGroupAccess = (route: string, userGroup: UserGroupAccess) => {
    return this.http.post<UserGroupAccess>(
      this.createCompleteRoute(route, this.config.rootUrl),
      userGroup,
      this.generateHeaders());
  }

  public updateUserGroupAccess = (route: string, userGroup: UserGroupAccess) => {
    return this.http.put(this.createCompleteRoute(route, this.config.rootUrl), userGroup, this.generateHeaders());
  }
  public deleteUserGroupAccess = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this.config.rootUrl));
  }
  //-------------------------------

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
