import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { OrderMessage } from '../../interfaces/order-messages/order-messasge';
import { OrderMessageForCreation } from '../../interfaces/order-messages/order-message-for-creation';
import { OrderMessageForUpdate } from '../../interfaces/order-messages/order-message-for-update';

@Injectable({
    providedIn: 'root'
})
export class OrderMesssagesRepositoryService extends PaginatedRepository<OrderMessage> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Orders/[0]/Messages`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    }

    public getOrderMessage = (orderID: string, orderMessageID: string) => {
        this.populateBaseLink([orderID]);

        return this.http.get<OrderMessage>(`${this._baseLink}/${orderMessageID}`, this.generateHeaders());
    }

    public createOrderMessage = (orderID: string, orderMessage: OrderMessageForCreation) => {
        this.populateBaseLink([orderID]);

        return this.http.post<OrderMessage>(`${this._baseLink}`, orderMessage, this.generateHeaders());
    }

    public updateOrderMessage = (orderID: string, orderMessageID: string, orderMessage: OrderMessageForUpdate) => {
        this.populateBaseLink([orderID]);

        return this.http.put<OrderMessage>(`${this._baseLink}/${orderMessageID}`, orderMessage, this.generateHeaders());
    }

    public deleteOrderMessage = (orderID: string, orderMessageID: string) => {
        this.populateBaseLink([orderID]);

        return this.http.delete<OrderMessage>(`${this._baseLink}/${orderMessageID}`, this.generateHeaders());
    }
}