<div class="padding">
    <mat-card>
        <mat-card-content>
            <!-- <div class="content">
                <span class="spacer"></span>
                <button (click)="navigateToAddUserGroup()"
                        mat-fab
                        matTooltip="Add User Group"
                        aria-label="Button that navigates to add new user group page."
                        color="accent">
                    <mat-icon>add</mat-icon>
                </button>
            </div> -->
            <table mat-table matSort *ngIf="!(this.repo.loading$ | async)" [dataSource]="this.repo.resource$"
                (matSortChange)="sort($event)">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let userGroup"> {{userGroup?.name}} </td>
                </ng-container>

                <!-- Address Column -->
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let userGroup"> {{userGroup.contactDetails?.address?.streetNumber}}
                        {{userGroup.contactDetails?.address?.streetName}} {{userGroup.contactDetails?.address?.city}}
                        {{userGroup.contactDetails?.address?.jurisdiction}} </td>
                </ng-container>

                <!-- Billing Column -->
                <ng-container matColumnDef="billingCentre">
                    <th mat-header-cell *matHeaderCellDef> Billing Centre </th>
                    <td mat-cell *matCellDef="let userGroup"> {{userGroup.billingDetails?.name}} </td>
                </ng-container>

                <!-- Configuration Column -->
                <ng-container matColumnDef="configuration">
                    <th mat-header-cell *matHeaderCellDef> Configuration </th>
                    <td mat-cell *matCellDef="let userGroup"> {{ userGroup.configurationDetails.name }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [routerLink]="['./userGroups/', row.id]">
                </tr>
            </table>

            <div *ngIf="this.repo.loading$ | async">
                <mat-spinner class="center"></mat-spinner>
            </div>
        </mat-card-content>
        <mat-paginator *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
            [pageSizeOptions]="[10, 25, 50, 100]" (page)="page($event)"></mat-paginator>
    </mat-card>
</div>