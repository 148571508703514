import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { OrderPreviewComponent } from '../order-preview/order-preview-component';

@Component({
  selector: 'lib-renewal-order-preview',
  templateUrl: './renewal-order-preview.component.html',
  styleUrls: ['./renewal-order-preview.component.css']
})
export class RenewalOrderPreviewComponent extends OrderPreviewComponent {
  @Input() order!: Order;
}
