import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent {
  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;
  @Input() displayedColumns = [
    'referenceNumber',
    'orderTypeID',
    'jurisdiction',
    'orderStatusTypeID',
    'messages'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  protected onViewAll() {
    let params = {
      status: 'Pending',
      isVisible: 'true',
      sortColumn: 'priority',
      sortDirection: 'asc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  protected navigateToOrder(orderID: string) {
    this.router.navigate(['orders', orderID]);
  }
}
