<div>
    <h3>Signing Location</h3>
    <form [formGroup]="signingLocation">
        <lib-jurisdiction-selection [appearance]="'outline'" 
            [defaultCountry]="signingCountry" 
            [defaultJurisdiction]="signingJurisdiction"
            [countryFieldName]="'Country'" [jurisdictionFieldName]="'Jurisdiction'" 
            countryFieldToFill="signingCountry" jurisdictionFieldToFill="signingJurisdiction"
            [enabled]="!isDisabled"></lib-jurisdiction-selection>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Signing City</mat-label>
                    <input matInput [disabled]="isDisabled" formControlName="signingCity" placeholder="Signing City"
                        name="signing-city">
                </mat-form-field>
                <lib-error-bubble [control]="signingLocation.controls['signingCity']"
                    [message]="signingCityError$"></lib-error-bubble>
            </div>
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Signing Date</mat-label>
                    <input matInput [disabled]="isDisabled" [matDatepicker]="signingDate" formControlName="signingDate"
                        name="signing-date" placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="signingDate"></mat-datepicker-toggle>
                    <mat-datepicker #signingDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="signingLocation.controls['signingDate']"
                    [message]="signingDateError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>