import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OrderStatus } from '../../interfaces/order-state/order-status';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Party } from '../../interfaces/parties/party';
import { PartyForCreation } from '../../interfaces/parties/party-for-creation';

@Injectable({
  providedIn: 'root'
})
export class PartyRepositoryService {

  constructor(private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  // CRUD Operations ---------------
  public getParty = (route: string, orderID: string) => {
    return this.http.get<OrderStatus>(
      this.createCompleteRoute(route, this.config.rootUrl));
  }

  public createParty = (route: string, party: Party) => {
    return this.http.post<Party>(
      this.createCompleteRoute(route, this.config.rootUrl),
      party,
      this.generateHeaders());
  }

  public createNewParty = (route: string, party: PartyForCreation) => {
    return this.http.post<Party>(
      this.createCompleteRoute(route, this.config.rootUrl),
      party,
      this.generateHeaders());
  }

  public updateParty = (route: string, party: Party) => {
    return this.http.put(this.createCompleteRoute(route, this.config.rootUrl), party, this.generateHeaders());
  }
  public deleteParty = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this.config.rootUrl));
  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
