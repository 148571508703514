<h3>Collateral Classifications</h3>

<form [formGroup]="ontarioCollateral">
    <!-- Checkbox list for various item categories -->
    <div class="flex-container flex-item padding-top" name="collateral-classifications">
        <mat-checkbox *ngIf="uiConfiguration.showConsumerGoods" class="flex-item" name="consumer-goods" [formControlName]="'consumerGoods'">Consumer
            Goods</mat-checkbox>
        <mat-checkbox *ngIf="uiConfiguration.showInventory" class="flex-item" name="inventory" [formControlName]="'inventory'">Inventory</mat-checkbox>
        <mat-checkbox *ngIf="uiConfiguration.showEquipment" class="flex-item" name="equipment" [formControlName]="'equipment'">Equipment</mat-checkbox>
    </div>

    <div class="flex-container flex-item padding-top">
        <div class="validated-form-field-container flex-container">
            <mat-checkbox *ngIf="uiConfiguration.showAccounts" class="flex-item" name="accounts" [formControlName]="'accounts'">Accounts</mat-checkbox>
            <mat-checkbox *ngIf="uiConfiguration.showOther" class="flex-item" name="other" [formControlName]="'other'">Other</mat-checkbox>
            <mat-checkbox *ngIf="uiConfiguration.showMVIncluded" class="flex-item" name="mv-included" [formControlName]="'mvIncluded'">Vehicle
                Included</mat-checkbox>
            <lib-error-bubble [control]="ontarioCollateral" [message]="collateralClassificationsError$"></lib-error-bubble>
        </div>
    </div>
</form>