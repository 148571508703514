import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { EnumeratedTypesRepositoryService, SystemEvent, SystemEventsRepositoryService } from 'reg-hub-common';
import { FiltersComponent, SelectOption } from 'reg-hub-common';
import { EnvironmentUrlService } from '../services/environment-url/environment-url.service';

@Component({
    selector: 'app-system-events-history-filter',
    templateUrl: './system-events-history-filter.component.html',
    styleUrls: ['./system-events-history-filter.component.css'],
    providers: [DatePipe]
})
export class SystemEventsHistoryFilterComponent extends FiltersComponent<SystemEvent> implements OnInit {
    protected _systemEventTypes: SelectOption[] = [];
    protected _orderTypes: SelectOption[] = [];
    protected autoExpand: boolean = false;

    constructor(
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private route: ActivatedRoute,
        private environmentUrl: EnvironmentUrlService,
        repo: SystemEventsRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe,
    ) {
        super(
            repo,
            formBuilder,
            datePipe);
    }

    ngOnInit(): void {
        let requests = [
            this.enumerationRepo.getSystemEventTypesAsSelectOptions(this.environmentUrl.urlAddress),
            this.enumerationRepo.getOrderTypesAsSelectOptions(this.environmentUrl.urlAddress)
        ]

        forkJoin(requests).subscribe(values => {
            this._systemEventTypes = values[0];
            this._orderTypes = values[1];
        })

        this._filterForm = this.formBuilder.group({
            systemEventTypeIDs: [null],
            orderTypeID: [null],
            jurisdiction: [null],
            isCustomerVisible: [true],
            addedFrom: [null],
            addedTo: [null],
            startDateFrom: [null],
            startDateTo: [null],
            endDateFrom: [null],
            endDateTo: [null]
        });

        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
            const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

            if (routeQueryStringParamKeys.length > 0) {
                let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                routeQueryStringParamKeys.forEach(key => {
                    const control = this._filterForm.get(key);

                    if (control) {
                        control.setValue(routeQueryStringParams[key]);
                        this._filterForm.markAsDirty();
                        this.filtered = true;
                    }
                })

                this.repo.getFirstPage(preFilteredParams);
            } else {
                this.repo.getFirstPage(this._defaultParams);
            }
        });
    }

    protected jurisdictionFormChanges(event: FormGroup) {
        this._filterForm.setControl('jurisdiction', event);
    }
}