<h2>Reusable Parties</h2>
<mat-card>
    <mat-card-content>
        <div class="content">
            <mat-checkbox [(ngModel)]="this.activeUsers" (ngModelChange)="getParties()" class="checkbox mat-form-field-no-padding">Active</mat-checkbox>
            <span class="spacer"></span>
            <button *ngIf="hasAccess" (click)="navigateToAddReusableParty()" mat-fab matTooltip="Add Reusable Party"
                aria-label="Button that navigates to add new reusable party." color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <table mat-table matSort *ngIf="!(this.repo.loading$ | async)" [dataSource]="this.repo.resource$"
            (matSortChange)="sort($event)">

            <ng-container matColumnDef="partyTypeID">
                <th mat-header-cell *matHeaderCellDef id="partyTypeID"> Party Type </th>
                <td mat-cell *matCellDef="let party"> {{party.partyType.name}} </td>
            </ng-container>
            
            <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef id="fullName"> Name </th>
                <td mat-cell *matCellDef="let party"> {{party.fullName}} </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef id="address"> Address </th>
                <td mat-cell *matCellDef="let party"> {{ formatAddress(party) }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [routerLink]="['./reusableParties/', row.id]">
            </tr>
        </table>

        <div *ngIf="this.repo.loading$ | async">
            <mat-spinner class="center"></mat-spinner>
        </div>
    </mat-card-content>
    <router-outlet></router-outlet>
    <mat-paginator *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
        [pageSizeOptions]="[10, 25, 50, 100]" (page)="page($event)"></mat-paginator>
</mat-card>