import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Corporation } from '../../interfaces/corporations/corporation';
import { CorporationForCreation } from '../../interfaces/corporations/corporation-for-creation';
import { CorporationForUpdate } from '../../interfaces/corporations/corporation-for-update';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { SelectOption } from '../../interfaces/select-option';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class CorporationRepositoryService extends PaginatedRepository<Corporation> {

  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this._baseLink = `${config.rootUrl}/corporations`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public getCorporation = (corporationID: string) => {
    return this.http.get<Corporation>(`${this._baseLink}/${corporationID}`);
  }

  public getCorporations(): Observable<Corporation[]> {
    return this.http.get<Corporation[]>(this._baseLink);
  }

  public createCorporation = (corporationForCreation: CorporationForCreation) => {
    return this.http.post<Corporation>(
      this._baseLink,
      corporationForCreation,
      this.generateHeaders());
  }

  public updateCorporation = (corporationID: string, corporation: CorporationForUpdate) => {
    return this.http.put(`${this._baseLink}/${corporationID}`, corporation, this.generateHeaders());
  }

  // public deleteCorporation = (corporationID: string) => {
  //   return this.http.delete(`${this._baseLink}/${corporationID}`);
  // }

  public getCorporationsAsSelectOptions(): Observable<SelectOption[]> {
    return this.http.get<SelectOption[]>(`${this._baseLink}/SelectOptions`);
  }
}
