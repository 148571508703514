import { Injectable } from '@angular/core';
import { UserManager, User, UserManagerSettings } from 'oidc-client-ts';
import { Subject } from 'rxjs';

export abstract class AuthService {

  private userManager?: UserManager;
  private user: User | null = null;

  constructor() {}

  protected setClientInfo(authConfig: UserManagerSettings) {
    this.userManager = new UserManager(authConfig);

    this.userManager.getUser().then(user => {
      this.user = user;
    });

    this.userManager.events.addUserLoaded(user => {
      this.user = user;
    });

    this.userManager.events.addUserUnloaded(() => {
      this.user = null;
    });
  }

  public logIn() {
    this.userManager!.signinRedirect();
  }

  public logOut() {
    this.userManager!.signoutRedirect();
  }

  public isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  public getUser(): User | null {
    return this.user;
  }

  public getAccessToken(): string | null {
    return this.user ? this.user.access_token : null;
  }

  public async completeAuthentication() {
    this.user = await this.userManager!.signinRedirectCallback();
  }

  public getClaim(claimName: string): string | null {
    if (this.user && this.user.profile) {
      return this.user.profile[claimName]?.toString() || null;
    }
    return null;
  }

  public getUserRole(): string | null {
    return this.getClaim('role');
  }

  public decodeJwt(): any {
    // Split the token into its parts (header, payload, signature)
    if (this.isLoggedIn()) {
      const payload = (this.getAccessToken() ?? "").split('.')[1];
    
      // Decode the Base64Url-encoded payload
      const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
    
      // Parse the JSON string into an object
      return JSON.parse(decodedPayload);
    }
  }

  public getRoleFromToken(): string | null {
    const decodedToken = this.decodeJwt();
  
    // Access the role claim from the decoded token
    const role = decodedToken?.role;
  
    // Return the role or null if not found
    return role || null;
  }

  public getUserNameFromToken() {
    const decodedToken = this.decodeJwt();
  
    // Access the role claim from the decoded token
    const name = decodedToken?.name;
  
    // Return the role or null if not found
    return name || null;
  }

  public getEmailFromToken() {
    const decodedToken = this.decodeJwt();
  
    // Access the role claim from the decoded token
    const email = decodedToken.email;
  
    // Return the role or null if not found
    return email || null;
  }
}