<div class="padding">
    <form [formGroup]="lienDetails">
        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$" [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput name="amount" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture" class="flex-container flex-item padding-top">
            <div class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'trustIndenture'" name="trust-indenture">
                    Trust Indenture
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['trustIndenture']"
                    [message]="trustIndentureError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showSurrenderDate" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Surrender Date</mat-label>
                    <input matInput [matDatepicker]="surrenderDate" name="surrender-date" formControlName="surrenderDate"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="surrenderDate"></mat-datepicker-toggle>
                    <mat-datepicker #surrenderDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('surrenderDate')!"
                    [message]="surrenderDateError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>