import { Step } from "../../../../interfaces/step";
import { Stepper } from "../../../../interfaces/stepper";

export enum eCriteriaVariation {
    FirstMiddleLast,
    FirstMiddleInitialLast,
    ExactSimilar,
    DateOfBirth,
    SearchFromDate,
    SpecificNonSpecific,
    AssetType,
    PostalCode
}

export class SearchStepper implements Stepper {
    steps: Step[] = [];
    configuration?: eCriteriaVariation[] = [];
}