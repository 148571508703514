import { Step, StepValidationState } from "../../../../interfaces/step";
import { Stepper } from "../../../../interfaces/stepper";
import { BaseOrderInformationComponent } from "../../../orders/base-order-information/base-order-information.component";

export class BaseInfoStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Order Info", content: BaseOrderInformationComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}