<!-- Orders table -->
<table mat-table [dataSource]="data" multiTemplateDataRows *ngIf="data.length > 0">

    <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef id="referenceNumber"> Ref No. </th>
        <td mat-cell *matCellDef="let order"> {{order.referenceNumber}} </td>
        <td mat-footer-cell *matFooterCellDef class="bold"> Total: </td>
    </ng-container>

    <ng-container matColumnDef="jurisdiction">
        <th mat-header-cell *matHeaderCellDef id="jurisdiction"> Juris. </th>
        <td mat-cell *matCellDef="let order"> {{order.jurisdiction}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="orderTypeID">
        <th mat-header-cell *matHeaderCellDef id="orderTypeID"> Type </th>
        <td mat-cell *matCellDef="let order"> {{order.orderType.name}} </td>
        <td mat-footer-cell *matFooterCellDef class="bold"> {{ this.total }} </td>
    </ng-container>

    <ng-container matColumnDef="orderStatusTypeID">
        <th mat-header-cell *matHeaderCellDef id="orderStatusTypeID"> Status </th>
        <td mat-cell *matCellDef="let order"> {{order.orderStatusTypeID}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="messages">
        <th mat-header-cell *matHeaderCellDef id="messages"></th>
        <td mat-cell *matCellDef="let order"> 
            <mat-icon color="accent" *ngIf="order.hasUnreadCustomerMessages">mark_unread_chat_alt</mat-icon>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToOrder(row.id)" class="clickable-table-row"></tr>
    <!-- [routerLink]="['/orders', row.id]" -->
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
<!-- End of orders table-->
