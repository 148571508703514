<h3>{{ isFormEnabled ? "Enter Report Criteria" : "Report Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Number</mat-label>
                    <input matInput [formControlName]="'number'" name="number" placeholder="Number">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('number')!"
                    [message]="numberError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>