import { PageNotFoundComponent, SearchBarComponent } from "reg-hub-common";
import { Step, StepValidationState } from "../../../../interfaces/step";
import { Stepper } from "../../../../interfaces/stepper";

export class NotFoundStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Failed to Fetch Steps", content: PageNotFoundComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}