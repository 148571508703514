import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ReportTemplate } from '../../interfaces/report-templates/report-template';
import { ReportTemplateForCreation } from '../../interfaces/report-templates/report-template-for-creation';
import { ReportTemplateForUpdate } from '../../interfaces/report-templates/report-template-for-update';

@Injectable({
    providedIn: 'root'
})
export class ReportTemplatesRepositoryService extends PaginatedRepository<ReportTemplate> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        
        super(http,messageService);

        this._currentParams = new HttpParams()
            .set('pageSize', 10);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/ReportTemplates`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public createReportTemplate = (corporationID: string, reportTemplate: ReportTemplateForCreation) => {
        this.populateBaseLink([corporationID]);

        return this.http.post<ReportTemplate>(
            this._baseLink,
            reportTemplate,
            this.generateHeaders());
    }

    public getReportTemplate = (corporationID: string, reportTemplateID: string) => {
        this.populateBaseLink([corporationID]);

        return this.http.get<ReportTemplate>(
            `${this._baseLink}/${reportTemplateID}`,
            this.generateHeaders());
    }

    public updateReportTemplate = (corporationID: string, reportTemplateID: string, reportTemplate: ReportTemplateForUpdate) => {
        this.populateBaseLink([corporationID]);
  
        return this.http.put<ReportTemplate>(
            `${this._baseLink}/${reportTemplateID}`,
            reportTemplate,
            this.generateHeaders());
    }

    public deleteReportTemplate = (corporationID: string, reportTemplateID: string) => {
        this.populateBaseLink([corporationID]);
  
        return this.http.delete(
            `${this._baseLink}/${reportTemplateID}`,
            this.generateHeaders());
    }
}