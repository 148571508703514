<div class="padding" *ngIf="!loading">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex">
                    <mat-tab label="User Groups" *ngIf="hasUserAccess">
                        <app-user-groups-list></app-user-groups-list>
                    </mat-tab>
                    <mat-tab label="Parties" *ngIf="hasPartyAccess">
                        <app-reusable-party-list></app-reusable-party-list>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>