export class DateUtils {
    public static getDateNoTimestamp(dateValue: string): Date | null {
        var date = dateValue ? new Date(dateValue) : null;

        if(!date) {
          return null;
        }

        let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return newDate;
    }
}