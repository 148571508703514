import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Invoice, InvoicesRepositoryService, ListComponent } from "reg-hub-common";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoicesComponent extends ListComponent<Invoice> {
  displayedColumns: string[] = [
    'invoiceNumber',
    'billingCycle',
    'dateOpened',
    'dateClosed',
    'dateExported',
    'datePaid'
  ];

  constructor(
    repo: InvoicesRepositoryService) {
    super(repo);
  }

  ngOnInit(): void {

  }
}
