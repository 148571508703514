import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReusablePartyRepositoryService, ReusableParty, ReusablePartyForUpdate, ConfigurationState, UserGroupRepositoryService } from 'reg-hub-common';
import { EnumeratedTypesRepositoryService, SelectOption } from 'reg-hub-common';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-reusable-party-info',
    templateUrl: './reusable-party-info.component.html',
    styleUrls: ['./reusable-party-info.component.css']
})
export class ReussablePartyInfoComponent {
    protected hasAccess: boolean = false;
    protected isEditing: boolean = false;
    protected isEditable: boolean = true;
    protected reusablePartyID: string | null = null;
    protected corporationID: string | null = null;
    protected fullName: string = "";
    protected partyTypes: SelectOption[] = [
        { label: "Business Secured Party", value: "BusinessSecuredParty" },
        { label: "Individual Secured Party", value: "IndividualSecuredParty" }
    ];
    protected reusableParty: ReusableParty | ReusablePartyForUpdate | null = null;
    protected displayedPartyCodeColumns: string[] = ['country', 'jurisdiction', 'code'];

    constructor(private activatedRoute: ActivatedRoute,
        private reusablePartyService: ReusablePartyRepositoryService,
        private userGroupRepo: UserGroupRepositoryService,
        private configurationState: ConfigurationState,
        private snackBar: MatSnackBar,
        private messageService: ToastrService,
        private router: Router) { }

    ngOnInit(): void {
        this.configurationState.hasManageReusablePartyAccess().then(partyAccess => {
            this.hasAccess = partyAccess;
            if (!this.hasAccess) {
                //How did you get here?
                this.messageService.error("Access Denied");
            }
            else {
                this.configurationState.getUserGroupID().then(userGroupID => {
                    this.getUserGroup(userGroupID);
                });
            }
        });
    }

    private getUserGroup = (userGroupID: string) => {
        this.userGroupRepo.getUserGroup(userGroupID)
            .subscribe(userGroup => {
                this.corporationID = userGroup.corporationID;
                this.activatedRoute.paramMap.subscribe(reusablePartyParams => {
                    // check current slug for user group id
                    this.reusablePartyID = reusablePartyParams.get('reusablePartyID')!;
                    this.getReusableParty();
                });
            });
    }

    private getReusableParty = () => {
        // this wil call api/v1/corporations
        this.reusablePartyService.getReusableParty(this.corporationID!, this.reusablePartyID!)
            .subscribe(party => {
                this.reusableParty = party;
                this.fullName = party.fullName!;

                this.isEditable = true;

                if (party.partyTypeID.toString() == 'BusinessRegisteringAgent' 
                    || party.partyTypeID.toString() == 'IndividualRegisteringAgent') {
                        this.isEditable = false;
                        this.messageService.error("Registering Agents are not editable. Please contact an administrator.");
                    }

                if (party.reusablePartyCodes?.length ?? 0 > 0) {
                    this.isEditable = false;
                    this.messageService.error("This party has associated registry party codes. It cannot be changed as it must match the party on file at the registry exactly. Please contact an administrator.");
                }
            })
    }

    cancel() {
        this.router.navigate(['management']);
    }

    updateReusableParty() {
        this.reusablePartyService.updateReusableParty(this.corporationID!, this.reusablePartyID!, this.reusableParty as ReusablePartyForUpdate)
            .subscribe({
                next: () => {
                    this.openSnackBar("Save successful");
                    this.router.navigate(['management'], { queryParams: { tab: 'reusableParties' } });
                },
                error: () => {
                    this.openSnackBar("Failed to Save changes");
                }
            })
    }

    isBusiness(): boolean {
        return (this.reusableParty?.partyTypeID.toString() == "BusinessDebtor" ||
            this.reusableParty?.partyTypeID.toString() == "BusinessRegisteringAgent" ||
            this.reusableParty?.partyTypeID.toString() == "BusinessSecuredParty" ||
            this.reusableParty?.partyTypeID.toString() == "BusinessDealer");
    }

    openSnackBar(message: string) {
        const config = new MatSnackBarConfig();
        config.duration = 5000; // Set the duration in milliseconds (2 seconds)

        this.snackBar.open(message, 'Close', config);
    }
}