import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-mb-lien-details',
  templateUrl: './mb-lien-details.component.html',
  styleUrls: ['./mb-lien-details.component.css']
})
export class MBLienDetailsComponent extends BaseLienDetailsComponent {
  perfectionInAnotherJurisdictionError$ = new BehaviorSubject<string | null | undefined>(null);
  purchaseMoneySecurityInterestError$ = new BehaviorSubject<string | null | undefined>(null);
  receiverAppointmentError$ = new BehaviorSubject<string | null | undefined>(null);
  stillHavePossessionError$ = new BehaviorSubject<string | null | undefined>(null);
  surrenderDateError$ = new BehaviorSubject<string | null | undefined>(null);
  serviceLastRenderedError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.perfectionInAnotherJurisdictionError$.next(errors?.filter(error => error.path.includes('perfection'))?.at(0)?.userFriendlyMessage);
    this.purchaseMoneySecurityInterestError$.next(errors?.filter(error => error.path.includes('purchasemoneysecurityinterest'))?.at(0)?.userFriendlyMessage);
    this.receiverAppointmentError$.next(errors?.filter(error => error.path.includes('receiverappointment'))?.at(0)?.userFriendlyMessage);
    this.stillHavePossessionError$.next(errors?.filter(error => error.path.includes('stillhavepossession'))?.at(0)?.userFriendlyMessage);
    this.surrenderDateError$.next(errors?.filter(error => error.path.includes('surrenderdate'))?.at(0)?.userFriendlyMessage);
    this.serviceLastRenderedError$.next(errors?.filter(error => error.path.includes('servicelastrenderederror'))?.at(0)?.userFriendlyMessage);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({ }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
      form.addControl('trustIndenture', this.formBuilder.control(this.order.lien?.trustIndenture ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction) {
      form.addControl('perfectionInAnotherJurisdiction', this.formBuilder.control(this.order.lien?.perfectionInAnotherJurisdiction ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest) {
      form.addControl('purchaseMoneySecurityInterest', this.formBuilder.control(this.order.lien?.purchaseMoneySecurityInterest ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment) {
      form.addControl('receiverAppointment', this.formBuilder.control(this.order.lien?.receiverAppointment ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showStillHavePossession) {
      form.addControl('stillHavePossession', this.formBuilder.control(this.order.lien?.stillHavePossession ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showSurrenderDate) {
      const surrenderDateControl = this.formBuilder.control(this.order.lien?.surrenderDate);

      if(this.order.lien?.stillHavePossession) {
        surrenderDateControl.disable();
      }

      form.addControl('surrenderDate', surrenderDateControl);
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showServiceLastRendered) {
      form.addControl('serviceLastRendered', this.formBuilder.control(this.order.lien?.serviceLastRendered));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showStillHavePossession &&
      this.uiConfiguration.lienDetailsConfiguration?.showSurrenderDate) {
        const stillHavePossessionControl = form.get('stillHavePossession');

        stillHavePossessionControl?.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(value => {
            const surrenderDateControl = form.get('surrenderDate');

            if (value === true) {
              surrenderDateControl?.reset();
              surrenderDateControl?.disable();
            } else {

              surrenderDateControl?.enable();
            }
          })
      }

    return form;
  }
}