import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { SystemEvent, SystemEventForCreation } from '../../interfaces/system_events/system-event';

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationsRepositoryService extends PaginatedRepository<SystemEvent> {

  protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(http, messageService);

    this._currentParams = new HttpParams().set('isOpen', true);
    this._baseLink = `${this.config.rootUrl}/SystemEvents`;
    this._currentParams
  }
}
