import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.css']
})
export class PlaceholderComponent implements OnInit {
  @Input() message: string = '';
  @Input() condition: boolean = false;
  @Input() iconClass: string = 'icon-info';
  @Input() iconName: string = 'warning';

  ngOnInit(): void {
    this.iconClass += ' icon-display'
  }
}
