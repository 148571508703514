import { ABAssetSearchStepper } from "./steps/asset-search/ab-asset-search-stepper";
import { BCAssetSearchStepper } from "./steps/asset-search/bc-asset-search-stepper";
import { MBAssetSearchStepper } from "./steps/asset-search/mb-asset-search-stepper";
import { NBAssetSearchStepper } from "./steps/asset-search/nb-asset-search-stepper";
import { NLAssetSearchStepper } from "./steps/asset-search/nl-asset-search-stepper";
import { NSAssetSearchStepper } from "./steps/asset-search/ns-asset-search-stepper";
import { NTAssetSearchStepper } from "./steps/asset-search/nt-asset-search-stepper";
import { NUAssetSearchStepper } from "./steps/asset-search/nu-asset-search-stepper";
import { ONAssetSearchStepper } from "./steps/asset-search/on-asset-search-stepper";
import { PEAssetSearchStepper } from "./steps/asset-search/pe-asset-search-stepper";
import { QCAssetSearchStepper } from "./steps/asset-search/qc-asset-search-stepper";
import { SKAssetSearchStepper } from "./steps/asset-search/sk-asset-search-stepper";
import { YTAssetSearchStepper } from "./steps/asset-search/yt-asset-search-stepper";
import { BaseInfoStepper } from "./steps/base-info-stepper";
import { ABDebtorSearchStepper } from "./steps/debtor-search/ab-debtor-search-stepper";
import { BCDebtorSearchStepper } from "./steps/debtor-search/bc-debtor-search-stepper";
import { MBDebtorSearchStepper } from "./steps/debtor-search/mb-debtor-search-stepper";
import { NBDebtorSearchStepper } from "./steps/debtor-search/nb-debtor-search-stepper";
import { NLDebtorSearchStepper } from "./steps/debtor-search/nl-debtor-search-stepper";
import { NSDebtorSearchStepper } from "./steps/debtor-search/ns-debtor-search-stepper";
import { NTDebtorSearchStepper } from "./steps/debtor-search/nt-debtor-search-stepper";
import { NUDebtorSearchStepper } from "./steps/debtor-search/nu-debtor-search-stepper";
import { ONDebtorSearchStepper } from "./steps/debtor-search/on-debtor-search-stepper";
import { PEDebtorSearchStepper } from "./steps/debtor-search/pe-debtor-search-stepper";
import { QCDebtorSearchStepper } from "./steps/debtor-search/qc-debtor-search-stepper";
import { SKDebtorSearchStepper } from "./steps/debtor-search/sk-debtor-search-stepper";
import { YTDebtorSearchStepper } from "./steps/debtor-search/yt-debtor-search-stepper";
import { QCCertifiedDebtorSearchStepper } from "./steps/certified-debtor-search/qc-certified-debtor-search-stepper";
import { DischargeStepper } from "./steps/discharge/discharge-stepper";
import { ABLienStepper } from "./steps/lien/ab-lien-stepper";
import { BCLienStepper } from "./steps/lien/bc-lien-stepper";
import { MBLienStepper } from "./steps/lien/mb-lien-stepper";
import { NBLienStepper } from "./steps/lien/nb-lien-stepper";
import { NLLienStepper } from "./steps/lien/nl-lien-stepper";
import { NSLienStepper } from "./steps/lien/ns-lien-stepper";
import { NTLienStepper } from "./steps/lien/nt-lien-stepper";
import { NULienStepper } from "./steps/lien/nu-lien-stepper";
import { ONLienStepper } from "./steps/lien/on-lien-stepper";
import { PELienStepper } from "./steps/lien/pe-lien-stepper";
import { QCRDdLienStepper } from "./steps/lien/qc-rdd-lien-stepper";
import { QCRDeLienStepper } from "./steps/lien/qc-rde-lien-stepper";
import { QCRDfLienStepper } from "./steps/lien/qc-rdf-lien-stepper";
import { QCRHaLienStepper } from "./steps/lien/qc-rha-lien-stepper";
import { SKLienStepper } from "./steps/lien/sk-lien-stepper";
import { YTLienStepper } from "./steps/lien/yt-lien-stepper";
import { ABAmendmentStepper } from "./steps/amendment/ab-amendment-stepper";
import { BCAmendmentStepper } from "./steps/amendment/bc-amendment-stepper";
import { MBAmendmentStepper } from "./steps/amendment/mb-amendment-stepper";
import { NBAmendmentStepper } from "./steps/amendment/nb-amendment-stepper";
import { NLAmendmentStepper } from "./steps/amendment/nl-amendment-stepper";
import { NSAmendmentStepper } from "./steps/amendment/ns-amendment-stepper";
import { NTAmendmentStepper } from "./steps/amendment/nt-amendment-stepper";
import { NUAmendmentStepper } from "./steps/amendment/nu-amendment-stepper";
import { ONAmendmentStepper } from "./steps/amendment/on-amendment-stepper";
import { PEAmendmentStepper } from "./steps/amendment/pe-amendment-stepper";
import { SKAmendmentStepper } from "./steps/amendment/sk-amendment-stepper";
import { YTAmendmentStepper } from "./steps/amendment/yt-amendment-stepper";
import { RenewalStepper } from "./steps/renewal/renewal-stepper";
import { ABRepairLienStepper } from "./steps/repair-lien/ab-repair-lien-stepper";
import { BCRepairLienStepper } from "./steps/repair-lien/bc-repair-lien-stepper";
import { SKRepairLienStepper } from "./steps/repair-lien/sk-repair-lien-stepper";
import { MBRepairLienStepper } from "./steps/repair-lien/mb-repair-lien-stepper";
import { NTRepairLienStepper } from "./steps/repair-lien/nt-repair-lien-stepper";
import { NURepairLienStepper } from "./steps/repair-lien/nu-repair-lien-stepper";
import { YTRepairLienStepper } from "./steps/repair-lien/yt-repair-lien-stepper";
import { QCRDdAmendmentStepper } from "./steps/amendment/qc-rdd-amendment-stepper";
import { QCRDeAmendmentStepper } from "./steps/amendment/qc-rde-amendment-stepper";
import { QCRDfAmendmentStepper } from "./steps/amendment/qc-rdf-amendment-stepper";
import { QCRHaAmendmentStepper } from "./steps/amendment/qc-rha-amendment-stepper";
import { QCDischargeStepper } from "./steps/discharge/qc-discharge-stepper";
import { HistoricalBCLienStepper } from "./steps/historical-lien/historical-bc-lien-stepper";
import { HistoricalABLienStepper } from "./steps/historical-lien/historical-ab-lien-stepper";
import { HistoricalMBLienStepper } from "./steps/historical-lien/historical-mb-lien-stepper";
import { HistoricalNBLienStepper } from "./steps/historical-lien/historical-nb-lien-stepper";
import { HistoricalNLLienStepper } from "./steps/historical-lien/historical-nl-lien-stepper";
import { HistoricalNSLienStepper } from "./steps/historical-lien/historical-ns-lien-stepper";
import { HistoricalNTLienStepper } from "./steps/historical-lien/historical-nt-lien-stepper";
import { HistoricalNULienStepper } from "./steps/historical-lien/historical-nu-lien-stepper";
import { HistoricalONLienStepper } from "./steps/historical-lien/historical-on-lien-stepper";
import { HistoricalPELienStepper } from "./steps/historical-lien/historical-pe-lien-stepper";
import { HistoricalQCRDdLienStepper } from "./steps/historical-lien/historical-qc-rdd-lien-stepper";
import { HistoricalQCRDeLienStepper } from "./steps/historical-lien/historical-qc-rde-lien-stepper";
import { HistoricalQCRDfLienStepper } from "./steps/historical-lien/historical-qc-rdf-lien-stepper";
import { HistoricalQCRHaLienStepper } from "./steps/historical-lien/historical-qc-rha-lien-stepper";
import { HistoricalSKLienStepper } from "./steps/historical-lien/historical-sk-lien-stepper";
import { HistoricalYTLienStepper } from "./steps/historical-lien/historical-yt-lien-stepper";
import { HistoricalABRepairLienStepper } from "./steps/historical-repair-lien/historical-ab-repair-lien-stepper";
import { HistoricalBCRepairLienStepper } from "./steps/historical-repair-lien/historical-bc-repair-lien-stepper";
import { HistoricalMBRepairLienStepper } from "./steps/historical-repair-lien/historical-mb-repair-lien-stepper";
import { HistoricalNTRepairLienStepper } from "./steps/historical-repair-lien/historical-nt-repair-lien-stepper";
import { HistoricalNURepairLienStepper } from "./steps/historical-repair-lien/historical-nu-repair-lien-stepper";
import { HistoricalONRepairLienStepper } from "./steps/historical-repair-lien/historical-on-repair-lien-stepper";
import { HistoricalSKRepairLienStepper } from "./steps/historical-repair-lien/historical-sk-repair-lien-stepper";
import { HistoricalYTRepairLienStepper } from "./steps/historical-repair-lien/historical-yt-repair-lien-stepper";
import { BasicDischargeStepper} from './steps/basic-discharge/basic-discharge-stepper';
import { ABBasicDischargeStepper } from "./steps/basic-discharge/ab-basic-discharge-stepper";
import { MBBasicDischargeStepper } from "./steps/basic-discharge/mb-basic-discharge-stepper";
import { ONBasicDischargeStepper } from "./steps/basic-discharge/on-basic-discharge-stepper";
import { BCBasicDischargeStepper } from "./steps/basic-discharge/bc-basic-discharge-stepper";
import { QCBasicDischargeStepper } from "./steps/basic-discharge/qc-basic-discharge-stepper";
import { BasicRenewalStepper } from "./steps/basic-renewal/basic-renewal-stepper";
import { ABBasicRenewalStepper } from "./steps/basic-renewal/ab-basic-renewal-stepper";
import { BCBasicRenewalStepper } from "./steps/basic-renewal/bc-basic-renewal-stepper";
import { MBBasicRenewalStepper } from "./steps/basic-renewal/mb-basic-renewal-stepper";
import { ONBasicRenewalStepper } from "./steps/basic-renewal/on-basic-renewal-stepper";
import { QCBasicRenewalStepper } from "./steps/basic-renewal/qc-basic-renewal-stepper";
import { CWBusinessSearchStepper } from "./steps/business-search/cw-business-search-stepper";
import { ABBusinessReportStepper } from "./steps/business-report/ab-asset-search-stepper";
import { BCBusinessReportStepper } from "./steps/business-report/bc-asset-search-stepper";
import { MBBusinessReportStepper } from "./steps/business-report/mb-asset-search-stepper";
import { NBBusinessReportStepper } from "./steps/business-report/nb-asset-search-stepper";
import { NLBusinessReportStepper } from "./steps/business-report/nl-asset-search-stepper";
import { NSBusinessReportStepper } from "./steps/business-report/ns-asset-search-stepper";
import { NTBusinessReportStepper } from "./steps/business-report/nt-asset-search-stepper";
import { NUBusinessReportStepper } from "./steps/business-report/nu-asset-search-stepper";
import { ONBusinessReportStepper } from "./steps/business-report/on-asset-search-stepper";
import { PEBusinessReportStepper } from "./steps/business-report/pe-asset-search-stepper";
import { QCBusinessReportStepper } from "./steps/business-report/qc-asset-search-stepper";
import { SKBusinessReportStepper } from "./steps/business-report/sk-asset-search-stepper";
import { YTBusinessReportStepper } from "./steps/business-report/yt-asset-search-stepper";
import { CWBusinessReportStepper } from "./steps/business-report/cw-business-search-stepper";

interface ClassConstructor {
    new (...args: any[]): any;
}

// Create a mapping object
const classMap: { [className: string]: ClassConstructor } = {
    // Base Info, alwys used for initial request page for file number / province / service
    'base-info-stepper': BaseInfoStepper,

    // Lien Steppers
    'ab-lien-stepper': ABLienStepper,
    'bc-lien-stepper': BCLienStepper,
    'mb-lien-stepper': MBLienStepper,
    'nb-lien-stepper': NBLienStepper,
    'nl-lien-stepper': NLLienStepper,
    'ns-lien-stepper': NSLienStepper,
    'nt-lien-stepper': NTLienStepper,
    'nu-lien-stepper': NULienStepper,
    'on-lien-stepper': ONLienStepper,
    'pe-lien-stepper': PELienStepper,
    'qc-rdd-lien-stepper': QCRDdLienStepper,
    'qc-rde-lien-stepper': QCRDeLienStepper,
    'qc-rdf-lien-stepper': QCRDfLienStepper,
    'qc-rha-lien-stepper': QCRHaLienStepper,
    'sk-lien-stepper': SKLienStepper,
    'yt-lien-stepper': YTLienStepper,

    //Historical Lien Steppers
    'historical-ab-lien-stepper': HistoricalABLienStepper,
    'historical-bc-lien-stepper': HistoricalBCLienStepper,
    'historical-mb-lien-stepper': HistoricalMBLienStepper,
    'historical-nb-lien-stepper': HistoricalNBLienStepper,
    'historical-nl-lien-stepper': HistoricalNLLienStepper,
    'historical-ns-lien-stepper': HistoricalNSLienStepper,
    'historical-nt-lien-stepper': HistoricalNTLienStepper,
    'historical-nu-lien-stepper': HistoricalNULienStepper,
    'historical-on-lien-stepper': HistoricalONLienStepper,
    'historical-pe-lien-stepper': HistoricalPELienStepper,
    'historical-qc-rdd-lien-stepper': HistoricalQCRDdLienStepper,
    'historical-qc-rde-lien-stepper': HistoricalQCRDeLienStepper,
    'historical-qc-rdf-lien-stepper': HistoricalQCRDfLienStepper,
    'historical-qc-rha-lien-stepper': HistoricalQCRHaLienStepper,
    'historical-sk-lien-stepper': HistoricalSKLienStepper,
    'historical-yt-lien-stepper': HistoricalYTLienStepper,

    // Repair Lien Steppers
    'ab-repairlien-stepper': ABRepairLienStepper,
    'bc-repairlien-stepper': BCRepairLienStepper,
    'mb-repairlien-stepper': MBRepairLienStepper,
    'nt-repairlien-stepper': NTRepairLienStepper,
    'nu-repairlien-stepper': NURepairLienStepper,
    'on-repairlien-stepper': ONLienStepper,
    'sk-repairlien-stepper': SKRepairLienStepper,
    'yt-repairlien-stepper': YTRepairLienStepper,

    // Repair Lien Steppers
    'historical-ab-repairlien-stepper': HistoricalABRepairLienStepper,
    'historical-bc-repairlien-stepper': HistoricalBCRepairLienStepper,
    'historical-mb-repairlien-stepper': HistoricalMBRepairLienStepper,
    'historical-nt-repairlien-stepper': HistoricalNTRepairLienStepper,
    'historical-nu-repairlien-stepper': HistoricalNURepairLienStepper,
    'historical-on-repairlien-stepper': HistoricalONRepairLienStepper,
    'historical-sk-repairlien-stepper': HistoricalSKRepairLienStepper,
    'historical-yt-repairlien-stepper': HistoricalYTRepairLienStepper,

    // Amendment Steppers
    'ab-amendment-stepper': ABAmendmentStepper,
    'bc-amendment-stepper': BCAmendmentStepper,
    'mb-amendment-stepper': MBAmendmentStepper,
    'nb-amendment-stepper': NBAmendmentStepper,
    'nl-amendment-stepper': NLAmendmentStepper,
    'ns-amendment-stepper': NSAmendmentStepper,
    'nt-amendment-stepper': NTAmendmentStepper,
    'nu-amendment-stepper': NUAmendmentStepper,
    'on-amendment-stepper': ONAmendmentStepper,
    'pe-amendment-stepper': PEAmendmentStepper,
    'sk-amendment-stepper': SKAmendmentStepper,
    'yt-amendment-stepper': YTAmendmentStepper,
    'qc-rdd-amendment-stepper': QCRDdAmendmentStepper,
    'qc-rde-amendment-stepper': QCRDeAmendmentStepper,
    'qc-rdf-amendment-stepper': QCRDfAmendmentStepper,
    'qc-rha-amendment-stepper': QCRHaAmendmentStepper,

    // Renewal Steppers
    'ab-renewal-stepper': RenewalStepper,
    'bc-renewal-stepper': RenewalStepper,
    'mb-renewal-stepper': RenewalStepper,
    'nb-renewal-stepper': RenewalStepper,
    'nl-renewal-stepper': RenewalStepper,
    'ns-renewal-stepper': RenewalStepper,
    'nt-renewal-stepper': RenewalStepper,
    'nu-renewal-stepper': RenewalStepper,
    'on-renewal-stepper': RenewalStepper,
    'pe-renewal-stepper': RenewalStepper,
    'qc-rg41-renewal-stepper': RenewalStepper,
    'qc-rhf-renewal-stepper': RenewalStepper,
    'sk-renewal-stepper': RenewalStepper,
    'yt-renewal-stepper': RenewalStepper,

    // Discharge Steppers
    'ab-discharge-stepper': DischargeStepper,
    'bc-discharge-stepper': DischargeStepper,
    'mb-discharge-stepper': DischargeStepper,
    'nb-discharge-stepper': DischargeStepper,
    'nl-discharge-stepper': DischargeStepper,
    'ns-discharge-stepper': DischargeStepper,
    'nt-discharge-stepper': DischargeStepper,
    'nu-discharge-stepper': DischargeStepper,
    'on-discharge-stepper': DischargeStepper,
    'pe-discharge-stepper': DischargeStepper,
    'qc-rv-discharge-stepper': QCDischargeStepper,
    'sk-discharge-stepper': DischargeStepper,
    'yt-discharge-stepper': DischargeStepper,

    // Debtor Search Steppers
    'ab-debtorsearch-stepper': ABDebtorSearchStepper,
    'bc-debtorsearch-stepper': BCDebtorSearchStepper,
    'mb-debtorsearch-stepper': MBDebtorSearchStepper,
    'nb-debtorsearch-stepper': NBDebtorSearchStepper,
    'nl-debtorsearch-stepper': NLDebtorSearchStepper,
    'ns-debtorsearch-stepper': NSDebtorSearchStepper,
    'nt-debtorsearch-stepper': NTDebtorSearchStepper,
    'nu-debtorsearch-stepper': NUDebtorSearchStepper,
    'on-debtorsearch-stepper': ONDebtorSearchStepper,
    'pe-debtorsearch-stepper': PEDebtorSearchStepper,
    'qc-debtorsearch-stepper': QCDebtorSearchStepper,
    'sk-debtorsearch-stepper': SKDebtorSearchStepper,
    'yt-debtorsearch-stepper': YTDebtorSearchStepper,

    // Asset Search Steppers
    'ab-assetsearch-stepper': ABAssetSearchStepper,
    'bc-assetsearch-stepper': BCAssetSearchStepper,
    'mb-assetsearch-stepper': MBAssetSearchStepper,
    'nb-assetsearch-stepper': NBAssetSearchStepper,
    'nl-assetsearch-stepper': NLAssetSearchStepper,
    'ns-assetsearch-stepper': NSAssetSearchStepper,
    'nt-assetsearch-stepper': NTAssetSearchStepper,
    'nu-assetsearch-stepper': NUAssetSearchStepper,
    'on-assetsearch-stepper': ONAssetSearchStepper,
    'pe-assetsearch-stepper': PEAssetSearchStepper,
    'qc-assetsearch-stepper': QCAssetSearchStepper,
    'sk-assetsearch-stepper': SKAssetSearchStepper,
    'yt-assetsearch-stepper': YTAssetSearchStepper,

    // Certified Debtor Search Steppers
    'qc-certifieddebtorsearch-stepper': QCCertifiedDebtorSearchStepper,

    //Basic Discharge
    'ab-basicdischarge-stepper': ABBasicDischargeStepper,
    'bc-basicdischarge-stepper': BCBasicDischargeStepper,
    'sk-basicdischarge-stepper': BasicDischargeStepper,
    'mb-basicdischarge-stepper': MBBasicDischargeStepper,
    'on-basicdischarge-stepper': ONBasicDischargeStepper,
    'qc-rv-basicdischarge-stepper': QCBasicDischargeStepper,
    'nb-basicdischarge-stepper': BasicDischargeStepper,
    'ns-basicdischarge-stepper': BasicDischargeStepper,
    'pe-basicdischarge-stepper': BasicDischargeStepper,
    'nl-basicdischarge-stepper': BasicDischargeStepper,
    'nu-basicdischarge-stepper': BasicDischargeStepper,
    'nt-basicdischarge-stepper': BasicDischargeStepper,
    'yt-basicdischarge-stepper': BasicDischargeStepper,

    //Basic Renewal
    'ab-basicrenewal-stepper': ABBasicRenewalStepper,
    'bc-basicrenewal-stepper': BCBasicRenewalStepper,
    'sk-basicrenewal-stepper': BasicRenewalStepper,
    'mb-basicrenewal-stepper': MBBasicRenewalStepper,
    'on-basicrenewal-stepper': ONBasicRenewalStepper,
    'qc-rhf-basicrenewal-stepper': QCBasicRenewalStepper,
    'qc-rg41-basicrenewal-stepper': QCBasicRenewalStepper,
    'nb-basicrenewal-stepper': BasicRenewalStepper,
    'ns-basicrenewal-stepper': BasicRenewalStepper,
    'pe-basicrenewal-stepper': BasicRenewalStepper,
    'nl-basicrenewal-stepper': BasicRenewalStepper,
    'nu-basicrenewal-stepper': BasicRenewalStepper,
    'nt-basicrenewal-stepper': BasicRenewalStepper,
    'yt-basicrenewal-stepper': BasicRenewalStepper,

    // Business Search Steppers
    'cw-businesssearch-stepper': CWBusinessSearchStepper,

    // Business Report Steppers
    'ab-businessreport-stepper': ABBusinessReportStepper,
    'bc-businessreport-stepper': BCBusinessReportStepper,
    'mb-businessreport-stepper': MBBusinessReportStepper,
    'nb-businessreport-stepper': NBBusinessReportStepper,
    'nl-businessreport-stepper': NLBusinessReportStepper,
    'ns-businessreport-stepper': NSBusinessReportStepper,
    'nt-businessreport-stepper': NTBusinessReportStepper,
    'nu-businessreport-stepper': NUBusinessReportStepper,
    'on-businessreport-stepper': ONBusinessReportStepper,
    'pe-businessreport-stepper': PEBusinessReportStepper,
    'qc-businessreport-stepper': QCBusinessReportStepper,
    'sk-businessreport-stepper': SKBusinessReportStepper,
    'yt-businessreport-stepper': YTBusinessReportStepper,
    'cw-businessreport-stepper': CWBusinessReportStepper,
};

// Function to instantiate a class based on a string name
export function instantiate(className: string) {
    const ClassReference = classMap[className];
    if (ClassReference) {
        return new ClassReference();
    } else {
        throw new Error(`Class not found: ${className}`);
    }
}