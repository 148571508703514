import { Component, ViewChild } from '@angular/core';
import { OrderManagerService, eQCFormType, SigningLocationComponent, ValidationService, Order, Amendment, eAmendmentType } from 'reg-hub-common';
import { BaseHistoricalLienDetailsComponent } from '../base-historical-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { AmendmentDetailsComponent } from '../../amendment-details/amendment-details.component';

@Component({
  selector: 'app-historical-qc-lien-details',
  templateUrl: './historical-qc-lien-details.component.html',
  styleUrls: ['./historical-qc-lien-details.component.css']
})
export class HistoricalQCLienDetailsComponent extends BaseHistoricalLienDetailsComponent {
  @ViewChild(SigningLocationComponent) signingLocationComponent!: SigningLocationComponent;
  @ViewChild(AmendmentDetailsComponent) amendmentDetailsComponent!: AmendmentDetailsComponent;

  protected isRHa: boolean = false;

  private orderToValidate: Order | null = null;

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    var qcFormTypeID = this.order.lien!.qcFormTypeID as unknown;
    const qcFormTypeIDEnum = eQCFormType[(qcFormTypeID as string) as keyof typeof eQCFormType];

    this.isRHa = qcFormTypeIDEnum == eQCFormType.RHa;
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningCountry) {
      this.lienDetails.setControl('signingCountry', this.signingLocationComponent.signingLocation.get('signingCountry'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningJurisdiction) {
      this.lienDetails.setControl('signingJurisdiction', this.signingLocationComponent.signingLocation.get('signingJurisdiction'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningCity) {
      this.lienDetails.setControl('signingCity', this.signingLocationComponent.signingLocation.get('signingCity'));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSigningDate) {
      this.lienDetails.setControl('signingDate', this.signingLocationComponent.signingLocation.get('signingDate'));
    }
  }

  updateSigningLocation(order: Order) {
    if (this.signingLocationComponent) {
      order.lien!.signingDate = this.signingLocationComponent.signingDate;
      order.lien!.signingCity = this.signingLocationComponent.signingCity;
      order.lien!.signingJurisdiction = this.signingLocationComponent.signingJurisdiction;
      order.lien!.signingCountry = this.signingLocationComponent.signingCountry;
    }
  }

  handleSigningLocationFormValueChangedEvent() {
    this.updateSigningLocation(this.order);

    if (!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    this.validate(this.orderToValidate!);
  }

  handleAmendmentDetailsFormChangedEvent(amendment: Amendment) {
    if (!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    this.validate(this.orderToValidate!);
  }

  override onSaving(): void {
    super.onSaving();
    this.updateSigningLocation(this.order);

    if (this.uiConfiguration.amendmentConfiguration?.showCorrectionOrChange) {
      this.amendmentDetailsComponent.updateAmendment(this.order);
    }
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({});

    if (this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showSumOfHypothec) {
      form.addControl('sumOfHypothec', this.formBuilder.control(this.order.lien?.sumOfHypothec));
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', this.formBuilder.control(this.order.lien?.additionalInformation));
    }

    return form;
  }
}