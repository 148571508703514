export enum StepValidationState {
    DefaultState = 0,
    ValidationSuccess = 1,
    ValidationError = 2
}

export interface Step {
    title: string;
    content: any; // This could be a component or any data structure you prefer
    validationState: StepValidationState;
}