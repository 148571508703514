import { Component } from '@angular/core';
import { ClientAuthService } from '../../services/auth/client-auth.service';
import { authConfig } from 'projects/reg-hub-client/src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent {
  username: string = "";
  email: string = "";

  constructor(private authService: ClientAuthService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.username = this.authService.getUserNameFromToken() ?? "";
  }

  changePassword(): void {
    const returnUrl = encodeURIComponent(window.location.origin + "/callback");

    const identityServerUrl = `${authConfig.authority}/account/changepassword?username=${this.username}&returnurl=${returnUrl}`;
    window.location.href = identityServerUrl;
  }
}
