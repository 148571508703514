import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OrderMetaData } from '../../interfaces/order-meta-data';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class OrderMetaDataRepositoryService {

  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  public getOrderMetaData = (corporationID: string | null | undefined,
    userGroupID: string | null | undefined,
    userID: string | null | undefined,
    lockedByUserID: string | null | undefined) => {

    let resourceParameters = new HttpParams();

    if (corporationID) {
      resourceParameters = resourceParameters.set('CorporationID', corporationID);
    }

    if (userGroupID) {
      resourceParameters = resourceParameters.set('UserGroupID', userGroupID);
    }

    if (userID) {
      resourceParameters = resourceParameters.set('UserID', userID);
    }

    if (lockedByUserID) {
      resourceParameters = resourceParameters.set('LockedByUserID', lockedByUserID);
    }

    return this.http.get<OrderMetaData>(`${this.config.rootUrl}/OrderMetaData`, { params: resourceParameters });
  }
}
