import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { DebtorsComponent } from "../../../../orders/debtors/debtors.component";
import { SecuredPartiesComponent } from "../../../../orders/secured-parties/secured-parties.component";
import { CollateralComponent } from "../../../../orders/collateral/collateral.component";
import { ReviewComponent } from "../../../../orders/review/review.component";
import { ABLienDetailsComponent } from "../../../../orders/lien-details/ab-lien-details/ab-lien-details.component";

export class ABLienStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: ABLienDetailsComponent, validationState: StepValidationState.DefaultState },
            { title: "Debtors", content: DebtorsComponent, validationState: StepValidationState.DefaultState },
            { title: "Sec. Parties", content: SecuredPartiesComponent, validationState: StepValidationState.DefaultState },
            { title: "Collateral", content: CollateralComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}