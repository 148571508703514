import { Component, Input, ViewChild } from '@angular/core';
import { GeneralCollateralComponent } from '../general-collateral/general-collateral.component';
import { Asset, Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { BehaviorSubject } from 'rxjs';
import { AssetsComponent } from '../assets/assets.component';

@Component({
  selector: 'app-collateral',
  templateUrl: './collateral.component.html',
  styleUrls: ['./collateral.component.css']
})
export class CollateralComponent extends SteppedComponent {
  @ViewChild(GeneralCollateralComponent) generalCollateralComponent!: GeneralCollateralComponent;
  @ViewChild(AssetsComponent) assetsComponent!: AssetsComponent;

  protected collateralClassificationsErrors$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  private orderToValidate: Order | null = null;
  
  handleAssetFormValueChangedEvent(asset: Asset) {
    if(!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }
    
    this.orderToValidate!.assets = [ asset ];
    this.validate(this.orderToValidate!);
  }

  handleGeneralCollateralFormValueChangedEvent(generalCollateral: any) {
    if(!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    this.ensureGeneralCollateral(this.orderToValidate!);

    this.orderToValidate!.lien!.generalCollateral.collateralValue = generalCollateral.collateralValue ?? null;
    this.orderToValidate!.lien!.generalCollateral.collateralToAdd = generalCollateral.collateralToAdd ?? null;
    this.orderToValidate!.lien!.generalCollateral.collateralToRemove = generalCollateral.collateralToRemove ?? null;
    this.orderToValidate!.lien!.securityInterest = generalCollateral.securityInterest ?? false;

    this.validate(this.orderToValidate!);
  }

  handleCollateralOntarioCollateralFormValueChangedEvent(value: any) {
    if(!this.orderToValidate) {
      this.orderToValidate = this.copyOrder(this.order);
    }

    for (const key in value) {
      if (key in this.orderToValidate!.lien!) {
        (this.orderToValidate?.lien as any)[key] = value[key];
      }
    }

    this.validate(this.orderToValidate!);
  }

  public override onSaving(): void {
    if(!this.uiConfiguration.collateralConfiguration?.hasGeneralCollateral) {
      return;
    }
    
    this.ensureGeneralCollateral(this.order);
    
    if (this.generalCollateralComponent?.generalCollateralDetails) {
      this.order.lien!.generalCollateral.collateralValue = this.generalCollateralComponent.generalCollateralDetails.get('collateralValue')?.value;
      this.order.lien!.generalCollateral.collateralToAdd = this.generalCollateralComponent.generalCollateralDetails.get('collateralToAdd')?.value;
      this.order.lien!.generalCollateral.collateralToRemove = this.generalCollateralComponent.generalCollateralDetails.get('collateralToRemove')?.value;
      this.order.lien!.securityInterest = this.generalCollateralComponent.generalCollateralDetails.get('securityInterest')?.value;
    }

    this.assetsComponent.onSaving();
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    this.collateralClassificationsErrors$.next(errors?.filter(error => error.path.includes('/collateralclassifications'))?.at(0)?.userFriendlyMessage);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return ((order.assets?.length ?? 0 > 0) || 
      order.referenceRegistrationInformation?.hasHadGeneralCollateral ||
      order.lien?.generalCollateral.collateralToAdd ||
      order.lien?.generalCollateral.collateralValue) &&
      errors?.filter(error => error.path.includes('liendetails')).length === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  private ensureGeneralCollateral(order: Order) {
    if(!order.lien!.generalCollateral) {
      order!.lien!.generalCollateral = { };
    }
  }
}
