import { Component, Input, OnInit } from '@angular/core';
import { GeneralCollateralHistory } from '../../interfaces/general-collateral/general-collateral-history';
import { OrderGroupsService } from '../../services/order-groups/order-groups.service';
import { Order } from '../../interfaces/orders/order';

@Component({
  selector: 'lib-general-collateral-history-expandable',
  templateUrl: './general-collateral-history-expandable.component.html',
  styleUrls: ['./general-collateral-history-expandable.component.css']
})
export class GeneralCollateralHistoryExpandableComponent implements OnInit {
  @Input() order!: Order;

  protected history: GeneralCollateralHistory[] = [];

  constructor(private orderGroupsService: OrderGroupsService) { }

  ngOnInit(): void {
    this.orderGroupsService.getGeneralCollateralHistory(this.order.orderGroup?.id ?? '')
      .subscribe(history => this.history = history);
  }
}
