import { UserGroup, UserGroupForCreation } from '../../interfaces/user-groups/user-group';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { UserGroupForUpdate } from '../../interfaces/user-groups/user-group-for-update';
import { SelectOption } from '../../interfaces/select-option';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class UserGroupRepositoryService extends PaginatedRepository<UserGroup>{

  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      super(
        http,
        messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/UserGroups`;
        this._baseLink = `${config.rootUrl}/UserGroups`;
    }

    private generateHeaders = () => {
      return {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
    }

  // CRUD Operations ---------------
  public getUserGroup = (userGroupID: string) => {
    return this.http.get<UserGroup>(`${this._baseLink}/${userGroupID}`);
  }

  public getUserGroups = (params?: HttpParams) => {
    return this.http.get<UserGroup[]>(`${this._baseLink}`, { params: params});
  }

  public createUserGroup = (userGroup: UserGroupForCreation) => {
    return this.http.post<UserGroup>(
      `${this._baseLink}`,
      userGroup,
      this.generateHeaders());
  }

  public updateUserGroup = (userGroupID: string, userGroup: UserGroupForUpdate) => {
    return this.http.put(`${this._baseLink}/${userGroupID}`, userGroup, this.generateHeaders());
  }

  public getUserGroupsAsSelectOptions(corporationID: string): Observable<SelectOption[]> {
    this.populateBaseLink([corporationID]);

    return this.http.get<SelectOption[]>(`${this._baseLink}/SelectOptions`);
  } 

  // public deleteUserGroup = (userGroupID: string) => {
  //   return this.http.delete(`${this._baseLink}/${userGroupID}`);
  // }
}
