import { Component, ViewChild } from '@angular/core';
import { Amendment, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseHistoricalLienDetailsComponent } from '../base-historical-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AmendmentDetailsComponent } from '../../amendment-details/amendment-details.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-historical-on-lien-details',
  templateUrl: './historical-on-lien-details.component.html',
  styleUrls: ['./historical-on-lien-details.component.css']
})
export class HistoricalONLienDetailsComponent extends BaseHistoricalLienDetailsComponent {
  cautionFilingError$ = new BehaviorSubject<string | null | undefined>(null);
  maturityDateError$ = new BehaviorSubject<string | null | undefined>(null);
  fileNumberError$ = new BehaviorSubject<string | null | undefined>(null);

  protected noFixedMaturityDate = 1;
  protected maturityDate = 2;
  protected noMaturityDateInformation = 3;

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.cautionFilingError$.next(errors?.filter(error => error.path.includes('cautionfiling'))?.at(0)?.userFriendlyMessage);
    this.maturityDateError$.next(errors?.filter(error => error.path.includes('maturitydate'))?.at(0)?.userFriendlyMessage);
    this.fileNumberError$.next(errors?.filter(error => error.path.includes('filenumber'))?.at(0)?.userFriendlyMessage);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({ }) as FormGroup;

    form.addControl('fileNumber', this.formBuilder.control(this.order.lien?.fileNumber));
    
    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showCautionFiling) {
      form.addControl('cautionFiling', this.formBuilder.control(this.order.lien?.cautionFiling ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showMaturityDate) {
      let maturityDateSelection = this.noMaturityDateInformation;

      if(this.order.lien?.noFixedMaturityDate === true) {
        maturityDateSelection = this.noFixedMaturityDate;
      } else if(this.order.lien?.maturityDate) {
        maturityDateSelection = this.maturityDate;
      }
      
      form.addControl('maturityDateOptions', this.formBuilder.control(maturityDateSelection));
      form.addControl('maturityDate', this.formBuilder.control(this.order.lien?.maturityDate));
      form.addControl('noFixedMaturityDate', this.formBuilder.control(this.order.lien?.noFixedMaturityDate));

      form.get('maturityDateOptions')?.valueChanges.subscribe(value => {
        if(value === this.noFixedMaturityDate) {
          form.get('maturityDate')?.setValue(null, { emitEvent: false });
          form.get('noFixedMaturityDate')?.setValue(true, { emitEvent: false });
        } else if(value === this.maturityDate) {
          form.get('noFixedMaturityDate')?.setValue(null, { emitEvent: false });
        } else if(value === this.noMaturityDateInformation) {
          form.get('maturityDate')?.setValue(null, { emitEvent: false });
          form.get('noFixedMaturityDate')?.setValue(null, { emitEvent: false });
        }
      })
    }

    return form;
  }

  protected showMaturityDate(): boolean {
    return this.lienDetails?.get('maturityDateOptions')?.value === this.maturityDate;
  }
}