import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-idle-countdown-dialog',
  templateUrl: './idle-countdown-dialog.component.html',
  styleUrls: ['./idle-countdown-dialog.component.css']
})
export class IdleCountdownDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<IdleCountdownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { countdown: number }
  ) {}
}
