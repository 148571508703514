import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ListComponent, ProductPricing } from "reg-hub-common";
import { DisbursementPricingRepositoryService } from '../../services/disbursement-pricing/disbursement-pircing.service';

@Component({
  selector: 'app-disbursement-pricing',
  templateUrl: './disbursement-pricing.component.html',
  styleUrls: ['./disbursement-pricing.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DisbursementPricingComponent extends ListComponent<ProductPricing> {

  protected expandedPrice: ProductPricing | null = null;

  displayedColumns: string[] = [
    'productCode',
    'description',
    'total'
  ];

  constructor(
    repo: DisbursementPricingRepositoryService,) {
    super(repo);
  }

  ngOnInit(): void {

  }
}
