import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
    _selectedIndex: number = 0;

    constructor(private activatedRoute: ActivatedRoute) {}
    
    ngOnInit(): void {
        var defaultTab = this.activatedRoute.snapshot.queryParams['tab'];
        if (defaultTab !== null && defaultTab !== undefined)
        {
            switch(defaultTab)
            {
                case "scheduled-reports":
                    this._selectedIndex = 2;
                    break;
                case "saved-reports":
                    this._selectedIndex = 1;
                    break;
                case "live-reports":
                default:
                    this._selectedIndex = 0;
                    break;
            }
        }
    }
}