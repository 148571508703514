import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListComponent, ReusablePartyRepositoryService, ReusableParty, ConfigurationState, UserGroupRepositoryService, Party } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-reusable-party-list',
  templateUrl: './reusable-party-list.component.html',
  styleUrls: ['./reusable-party-list.component.css']
})
export class ReusablePartyListComponent extends ListComponent<ReusableParty> implements OnInit {
  activeUsers: boolean = true;
  hasAccess: boolean = false;
  reusableParties: ReusableParty[] = []
  displayedColumns: string[] = ['partyTypeID',
    'fullName',
    'address'
  ];

  constructor(repo: ReusablePartyRepositoryService,
    private configurationState: ConfigurationState,
    private userGroupService: UserGroupRepositoryService,
    private router: Router) {
    super(repo);
  }

  ngOnInit(): void {
    this.configurationState.hasManageReusablePartyAccess().then(partyAccess => {

      this.hasAccess = partyAccess;

      this.configurationState.getUserGroupID().then(userGroupID => {
        this.userGroupService.getUserGroup(userGroupID).subscribe(userGroup => {
          this.repo.populateBaseLink([userGroup.corporationID]);
          this.getParties();
        })
      })
    });
  }

  getParties() {
    var defaultParams = new HttpParams().set('IsVisible', this.activeUsers);
    this.repo.getFirstPage(defaultParams);
  }

  navigateToAddReusableParty() {
    this.router.navigate([`/management/reusableParties/add`]);
  }

  formatAddress(party: Party) {
    var address = party.contactDetails?.address?.unit + ' ' +
      party.contactDetails?.address?.streetNumber + ' ' +
      party.contactDetails?.address?.streetName + ', ' +
      party.contactDetails?.address?.city + ' ' + party.contactDetails?.address?.jurisdiction + ', ' +
      party.contactDetails?.address?.postalCode;

    if(address.startsWith(',') && address.length > 1) {
      address = address.substring(1);
    }

    if(address.endsWith(',')) {
      address = address.substring(1, address.length - 2);
    }

    return address.trim();
  }
}