import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Validation } from '../../interfaces/validation/validation';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  public currentValidation$ = new BehaviorSubject<Validation>({});

  constructor(private client: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig
  ) { }

  public validate(order: Order): Observable<Validation> {
    return this.client.post<Validation>(`${this.config.rootUrl}/Validate/Orders`, order)
      .pipe(tap(validation => this.currentValidation$.next(validation)));
  }

  public clearValidation(): void {
    this.currentValidation$.next({});
  }
}
