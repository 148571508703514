<div class="padding" *ngIf="hasAccess">
    <h1 class="left-box">{{user?.lastName ?? ""}}, {{user?.firstName ?? ""}} {{user?.middleName ?? ""}}</h1>

    <mat-card>
        <mat-card-content *ngIf="this.user != null">
            <div class="flex-container">
                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Username</mat-label>
                    <input matInput name="username" disabled="true" [(ngModel)]="this.user.userName" placeholder="Username">
                </mat-form-field>
                <mat-checkbox [(ngModel)]="this.user!.isActive" class="checkbox form-field">Active</mat-checkbox>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>First Name</mat-label>
                    <input matInput name="firstName"[(ngModel)]="this.user.firstName" placeholder="First Name">
                </mat-form-field>

                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Middle Name</mat-label>
                    <input matInput name="middleName"[(ngModel)]="this.user.middleName" placeholder="Middle Name">
                </mat-form-field>

                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Last Name</mat-label>
                    <input matInput name="lastName"[(ngModel)]="this.user.lastName" placeholder="Last Name">
                </mat-form-field>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Configuration</mat-label>
                    <mat-select name="configuration" [(ngModel)]="this.user!.configurationDetailsID">
                        <mat-option *ngFor="let configuration of configurationDetailsOptions" [value]="configuration.id">
                            {{configuration.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3>Contact Information</h3>
            <app-client-contact-details-card *ngIf="user != null" [contactDetails]="user!.contactDetails"></app-client-contact-details-card>

            <div class="flex-container">
                <span class="spacer"></span>
                <button mat-raised-button (click)="updateUserDetails()" color="accent" class="apply-padding" aria-label="Update User button">
                    Save Changes
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>