import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { forkJoin, Subject, take, takeUntil } from 'rxjs';
import { FiltersComponent, EnumeratedTypesRepositoryService, Order, OrderRepositoryService, SelectOption, UserGroupAccessRepositoryService, UserGroupAccess, UserRepositoryService, ConfigurationState, eOrderTypeGroup, eGeneration, eQCFormType } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-search-orders-filter',
    templateUrl: './search-orders-filter.component.html',
    styleUrls: ['./search-orders-filter.component.css'],
    providers: [DatePipe]
})
export class SearchOrdersFilterComponent extends FiltersComponent<Order> implements OnInit {
    protected corporationID: string = "";
    protected _orderTypeOptions: SelectOption[] = [];
    protected _orderStatusTypeOptions: SelectOption[] = [];
    protected _qcFormTypeOptions: SelectOption[] = [];
    protected _qcLienFormTypes: SelectOption[] = [];
    protected _qcRenewalFormTypes: SelectOption[] = [];
    protected _qcAmmendmentFormTypes: SelectOption[] = [];
    protected _qcDischargeFormTypes: SelectOption[] = [];
    protected _qcFormTypeOptionsVisible: SelectOption[] = [];
    protected _userGroups: UserGroupAccess[] = [];
    protected _users: SelectOption[] = [];

    private _selectedOrderTypesMask: number = 0;

    protected autoExpand: boolean = false;
    protected autoRedirect: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private configurationState: ConfigurationState,
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private userGroupAccessRepo: UserGroupAccessRepositoryService,
        private usersService: UserRepositoryService,
        private environment: EnvironmentUrlService,
        private route: ActivatedRoute,
        private router: Router,
        ordersRepo: OrderRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe) {
        super(
            ordersRepo,
            formBuilder,
            datePipe);
    }

    ngOnInit() {
        this.repo.resource$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(orders => {
                const ordersArray = orders as Order[]
                if (orders != null && this.autoRedirect == true && ordersArray.length == 1) {
                    this.router.navigate(['orders', orders[0].id]);
                }
            });

        var allFormTypes: eQCFormType[] = [
            eQCFormType.RDd, eQCFormType.RDe, eQCFormType.RDf, eQCFormType.RHa, eQCFormType.RG41, eQCFormType.RHf,
            eQCFormType.RG80, eQCFormType.RRa, eQCFormType.RG34, eQCFormType.RV, eQCFormType.RE
        ];

        let requests = [
            this.enumerationRepo.getOrderTypesAsSelectOptions(this.environment.urlAddress),
            this.enumerationRepo.getOrderStatusTypesAsSelectOptions(this.environment.urlAddress),
            this.enumerationRepo.getQcFormTypesAsSelectOptionsCompact(this.environment.urlAddress, allFormTypes)
        ]

        forkJoin(requests).subscribe(values => {
            this._orderTypeOptions = values[0];
            this._orderStatusTypeOptions = values[1];
            this._qcFormTypeOptions = values[2];
            this.mapQcFormTypes()
        })

        this.userGroupAccessRepo.getReadableUserAccesses()
            .subscribe(groups => {
                this._userGroups = groups;
            })

        this.configurationState.getCorporationID().then(corpID => {
            this.corporationID = corpID;
        })

        this._filterForm = this.formBuilder.group({
            referenceNumber: [null],
            orderTypes: [null],
            status: [null],
            userID: [null],
            userGroupID: [null],
            dateRequestedFrom: [null],
            dateRequestedTo: [null],
            orderStatusUpdatedDateFrom: [null],
            orderStatusUpdatedDateTo: [null],
            country: ['CA'],
            jurisdiction: [null],
            isVisible: [true],
            registrationOrFileNumber: [null],
            registrationDateFrom: [null],
            registrationDateTo: [null],
            firstName: [null],
            lastName: [null],
            dateOfBirth: [null],
            businessName: [null],
            serialNumber: [null],
            sortColumn: ['orderStatusUpdatedDate'],
            sortDirection: ['desc'],
            QCFormTypeIDs: [null]
        })

        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
            const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

            if (routeQueryStringParamKeys.length > 0) {
                this.autoRedirect = routeQueryStringParams["autoRedirect"] == 'true';
                let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                routeQueryStringParamKeys.forEach(key => {
                    const control = this._filterForm.get(key);

                    if (control) {
                        control.setValue(routeQueryStringParams[key]);
                        this._filterForm.markAsDirty();
                        this.autoExpand = routeQueryStringParams["autoExpand"] ?? false;
                        this.filtered = true;
                    }
                })

                this.repo.getFirstPage(preFilteredParams);
            } else {
                this.repo.getFirstPage(this._defaultParams);
            }
        });
    }

    ngOnDestroy(): void {
        // Signal all subscriptions to complete
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected jurisdictionFormChanges(event: FormGroup) {
        this._filterForm.setControl('country', event);
        this._filterForm.setControl('jurisdiction', event);
    }

    protected onUserGroupChanged(event: MatSelectChange) {
        this.groupChanged(event.value);
    }

    protected groupChanged(userGroupID: string) {
        this.usersService.getUsersAsSelectOptions(this.corporationID, userGroupID)
            .subscribe(users => {
                this._users = users;
            })
    }

    protected lienSelected(): boolean {
        var selectedOrderTypes = this._filterForm.get("orderTypes")?.value;

        if (selectedOrderTypes != null && selectedOrderTypes.length > 0) {
            selectedOrderTypes = selectedOrderTypes as Array<string>;

            this._selectedOrderTypesMask = 0;
            if (selectedOrderTypes.includes("Lien")) {
                this._selectedOrderTypesMask += 1
            }
            if (selectedOrderTypes.includes("Renewal") || selectedOrderTypes.includes("BasicRenewal")) {
                this._selectedOrderTypesMask += 2
            }
            if (selectedOrderTypes.includes("Discharge") || selectedOrderTypes.includes("BasicDischarge")) {
                this._selectedOrderTypesMask += 4
            }
            if (selectedOrderTypes.includes("Amendment")) {
                this._selectedOrderTypesMask += 8
            }
            return this._selectedOrderTypesMask > 0;
        }
        return false;
    }

    protected qcLienSelected(): boolean {
        if (this.lienSelected() && this._filterForm.get("jurisdiction")?.value.Jurisdiction == "QC") {
            this._qcFormTypeOptionsVisible = [];
            if (this._selectedOrderTypesMask & 1) {
                this._qcFormTypeOptionsVisible.push(...this._qcLienFormTypes);
            }
            if (this._selectedOrderTypesMask & 2) {
                this._qcFormTypeOptionsVisible.push(...this._qcRenewalFormTypes);
            }
            if (this._selectedOrderTypesMask & 4) {
                this._qcFormTypeOptionsVisible.push(...this._qcDischargeFormTypes);
            }
            if (this._selectedOrderTypesMask & 8) {
                this._qcFormTypeOptionsVisible.push(...this._qcAmmendmentFormTypes);
            }

            return true;
        }

        return false;
    }
    
    protected mapQcFormTypes() {
        var lienFormTypes: string[] = [eQCFormType.RDd.valueOf(), eQCFormType.RDe.valueOf(), eQCFormType.RDf.valueOf(), eQCFormType.RHa.valueOf()];
        var renewalFormTypes: string[] = [eQCFormType.RG41.valueOf(), eQCFormType.RHf.valueOf()];
        var amendmentFormTypes: string[] = [eQCFormType.RG80.valueOf(), eQCFormType.RRa.valueOf(), eQCFormType.RG34.valueOf()];
        var dischargeFormTypes: string[] = [eQCFormType.RV.valueOf(), eQCFormType.RE.valueOf()];

        this._qcFormTypeOptions.forEach((option: SelectOption) => {
            if(lienFormTypes.indexOf(option.label) > -1){
                this._qcLienFormTypes.push(option);
            }
            if(renewalFormTypes.indexOf(option.label) > -1){
                this._qcRenewalFormTypes.push(option);
            }
            if(amendmentFormTypes.indexOf(option.label) > -1){
                this._qcAmmendmentFormTypes.push(option);
            }
            if(dischargeFormTypes.indexOf(option.label) > -1){
                this._qcDischargeFormTypes.push(option);
            }
        })
    }
}
