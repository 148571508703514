import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { InvoicesRepositoryService, EnumeratedTypesRepositoryService, ConfigurationState } from 'reg-hub-common';
import { Invoice, FiltersComponent, SelectOption } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
    selector: 'app-invoices-filter',
    templateUrl: './invoices-filter.component.html',
    styleUrls: ['./invoices-filter.component.css'],
    providers: [DatePipe]
})
export class InvoicesFilterComponent extends FiltersComponent<Invoice> implements OnInit {
    protected billingCycles: SelectOption[] = [];
    protected autoExpand: boolean = false;

    constructor(
        private enumerationRepo: EnumeratedTypesRepositoryService,
        private route: ActivatedRoute,
        private environmentUrl: EnvironmentUrlService,
        private configurationStateService: ConfigurationState,
        invoicesRepository: InvoicesRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe,
    ) {
        super(
            invoicesRepository,
            formBuilder,
            datePipe);
    }

    ngOnInit(): void {
        let requests = [
            this.enumerationRepo.getBillingCyclesAsSelectOptions(this.environmentUrl.urlAddress)
        ]

        forkJoin(requests).subscribe(values => {
            this.billingCycles = values[0];
        })

        this._filterForm = this.formBuilder.group({
            invoiceNumber: [null],
            billingCycleID: [null],
            externalInvoiceID: [null],
            billingDetailsID: [null],
            dateOpenedFrom: [null],
            dateOpenedTo: [null],
            isClosed: [null],
            dateClosedFrom: [null],
            dateClosedTo: [null],
            isExported: [null],
            dateExportedFrom: [null],
            dateExportedTo: [null],
            isPaid: [null],
            datePaidFrom: [null],
            datePaidTo: [null]
        });

        this.configurationStateService.getCorporationID().then(corporationID => {
            this.configurationStateService.getBillingDetailsID().then(billingDetailsID => {
                this.repo.populateBaseLink([corporationID, billingDetailsID]);
                this.getFirstPage();
            });
        });
    }

    getFirstPage() {
        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
            const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

            if (routeQueryStringParamKeys.length > 0) {
                let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                routeQueryStringParamKeys.forEach(key => {
                    const control = this._filterForm.get(key);

                    if (control) {
                        control.setValue(routeQueryStringParams[key]);
                        this._filterForm.markAsDirty();
                        this.filtered = true;
                    }
                })

                this.repo.getFirstPage(preFilteredParams);
            } else {
                this.repo.getFirstPage(this._defaultParams);
            }
        });
    }
}