import { Component, Input, OnInit } from '@angular/core';
import { FiltersComponent, ProductPricing, SelectOption } from 'reg-hub-common';
import { ConfigurationState, EnumeratedTypesRepositoryService, UserGroupRepositoryService } from 'reg-hub-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ServicePricingRepositoryService } from '../../services/service-pricing/service-pricing.service';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Component({
  selector: 'app-service-pricing-filter',
  templateUrl: './service-pricing-filter.component.html',
  styleUrls: ['./service-pricing-filter.component.css'],
  providers: [ DatePipe ]
})
export class ServicePricingFilterComponent extends FiltersComponent<ProductPricing> implements OnInit {
  @Input() isActiveDefault: boolean = true;
  protected _orderTypes: SelectOption[] = [];

  constructor(
    private configurationState: ConfigurationState,
    private userGroupService: UserGroupRepositoryService,
    private environment: EnvironmentUrlService,
    private enumerationRepo: EnumeratedTypesRepositoryService,
    repo: ServicePricingRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe
  ) {
    super(repo, formBuilder, datePipe);
  }

  ngOnInit(): void {
    let requests = [
      this.enumerationRepo.getOrderTypesAsSelectOptions(this.environment.urlAddress),
  ]

  forkJoin(requests).subscribe(values => {
      this._orderTypes = values[0]
  })

    this._filterForm = this.formBuilder.group({
      orderTypeID: [null],
      jurisdiction: [null]
    });

    this.configurationState.getUserGroupID().then(userGroupID => {
      this.userGroupService.getUserGroup(userGroupID).subscribe(userGroup => {
        this._defaultParams = new HttpParams().set('isActive', this.isActiveDefault);
        this._defaultParams = this._defaultParams.append('feeTypeID', 'Service');
        const pricingTemplateID = userGroup?.billingDetails?.pricingTemplate?.id ?? null;
        if (pricingTemplateID != null) {
          this._defaultParams = this._defaultParams.append('pricingTemplateID', pricingTemplateID);
        }

        this.repo.getFirstPage(this._defaultParams);
      });
    });
  }

  protected jurisdictionFormChanges(event: FormGroup) {
    this._filterForm.setControl('jurisdiction', event);
  }
}