import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ValidationLog } from '../../interfaces/validation-logs/validation-log';

@Injectable({
    providedIn: 'root'
})
export class ValidationLogsRepositoryService extends PaginatedRepository<ValidationLog> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLink = `${this.config.rootUrl}/Logs/Validation`;
    }
}