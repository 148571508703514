<h3>Debtors</h3>
<!-- Debtors -->
<div *ngIf="uiConfiguration?.hasDebtors" class="flex-container no-margin">
    <lib-parties-list
        partyTypeTitle="Debtor"
        [order]="order"
        [observableParties$]="parties$"
        [errors$]="observableErrors$"
        class="flex-item"
        [isEditable]="isEditable"
        [uiConfiguration]="uiConfiguration!.debtorsConfiguration!"
        [expandedParty]="newParty"
        [businessPartyType]="businessDebtor"
        [individualPartyType]="individualDebtor"
        [defaultPartyType]="individualDebtor"
        (partyAddedEvent)="handlePartyAddedEvent($event)"
        (partyRemovedEvent)="handlePartyRemovedEvent($event)"
        (partyUpdatedEvent)="handlePartySavedEvent($event)"
        (partyCopiedEvent)="handlePartyCopiedEvent($event)"
        (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
    </lib-parties-list>
</div>