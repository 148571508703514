
<div [@loadingToLoaded]="order.orderStatusTypeID" (@loadingToLoaded.done)="onLoadingToLoadedDone()">
    <div *ngIf="order.orderStatusTypeID != 'Complete'" class="loading-spinner-overlay spinner-animation padding-top-bottom-50">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="order.orderStatusTypeID == 'Complete'" class="results-animation">
        <div *ngIf="this.repo.resource$ | async as data">

            <lib-business-search-results-filter *ngIf="showFilters" class="filter-animation"></lib-business-search-results-filter>

            <div class="spacer-small"></div>

            <div class="table-container" *ngIf="data && order.orderStatusTypeID == 'Complete'">

                <div *ngIf="data.length >= 50">
                    <mat-paginator [pageSize]="this.repo.pageSize$ | async"
                                    [length]="this.repo.resourceCount$ | async"
                                    [pageIndex]="this.repo.pageNum$ | async"
                                    [pageSizeOptions]="[10, 25, 50, 100]"
                                    showFirstLastButtons="true"
                                    (page)="page($event)">
                    </mat-paginator>
                    <div class="spacer-small"></div>
                </div>

                <table mat-table matSort [dataSource]="data" multiTemplateDataRows
                        *ngIf="data.length > 0">

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let result">{{ result.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
                        <td mat-cell *matCellDef="let result">{{ result.number }}</td>
                    </ng-container>

                    <ng-container matColumnDef="jurisdiction">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Jurisdiction</th>
                        <td mat-cell *matCellDef="let result">{{ result.jurisdiction }}</td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let result">{{ result.status }}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                        <td mat-cell *matCellDef="let result" class="action-cell">
                            <button *ngIf="!result.businessReports || result.businessReports?.length == 0"
                            mat-fab extended
                            color="primary"
                            aria-label="Order Report"
                            (click)="orderReport(result);$event.stopPropagation()"
                            class="no-shadow">
                                <mat-icon>description</mat-icon>
                                Order Report
                            </button>

                            <button *ngIf="result.businessReports?.length > 0"
                                mat-fab extended
                                color="primary"
                                aria-label="Update Report"
                                (click)="orderReport(result);$event.stopPropagation()"
                                class="no-shadow">
                                <mat-icon>replay</mat-icon>
                                Update Report
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="indicator">
                        <th mat-header-cell *matHeaderCellDef id="indicator"></th>
                        <td mat-cell *matCellDef="let result">
                            <lib-disclosure-indicator [isExpanded]="result === _expandedResult"></lib-disclosure-indicator>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let result" [attr.colspan]="columnsToDisplayWithView.length">
                            <div class="expanded-entity-detail"
                                    [@detailExpand]="result === _expandedResult ? 'expanded' : 'collapsed'">
                                <div class="expanded-entity-information">
                                    <lib-business-search-result-preview [result]="result"></lib-business-search-result-preview>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithView"></tr>
                    <tr mat-row *matRowDef="let result; columns: columnsToDisplayWithView;"
                        class="expandable-table-row" [class.example-expanded-row]="_expandedResult === result"
                        (click)="_expandedResult = _expandedResult === result ? null : result">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>

                </table>

                <div class="padding center padding-top-bottom-50"
                        *ngIf="((this.repo.loading$ | async) ?? false) && data.length === 0">
                    &nbsp;
                </div>

                <div class="spacer-small"></div>
                <mat-paginator *ngIf="data.length > 0"
                                [pageSize]="this.repo.pageSize$ | async"
                                [length]="this.repo.resourceCount$ | async"
                                [pageIndex]="this.repo.pageNum$ | async"
                                [pageSizeOptions]="[10, 25, 50, 100]"
                                showFirstLastButtons="true"
                                (page)="page($event)">
                </mat-paginator>
            </div>
            <lib-list-issue [repo]="this.repo" [data]="data"></lib-list-issue>

        </div>
    </div>
</div>