import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { eOrderStatusType, eOrderTypeGroup, Order, OrderManagerService, OrderUIConfiguration, UIConfigurationService } from 'reg-hub-common';

@Injectable({
  providedIn: 'root'
})
export class PrintDataService {
  public data: any;

  constructor(
    private router: Router,
    private orderManager: OrderManagerService,
    private uiConfigurationService: UIConfigurationService) { }

  public clear() {
    this.data = undefined;
  }

  public printOrder() {
    this.data = {
      order: this.orderManager.currentOrder.value,
      uiConfiguration: this.uiConfigurationService.uiConfiguration$.value
    }

    this.router.navigate([{ outlets: { print: ['print', 'orders'] } }]);

    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }

  public shouldShowPrintOrderButton(order: Order): boolean {
    return (order.orderType.orderTypeGroup.id === eOrderTypeGroup.PPSARegistration ||
      order.orderType.orderTypeGroup.id === eOrderTypeGroup.PPSAChangeManagement ||
      order.orderType.orderTypeGroup.id === eOrderTypeGroup.BasicPPSARegistration) &&
      (order.orderStatusTypeID === eOrderStatusType.draft ||
      order.orderStatusTypeID === eOrderStatusType.pending ||
      order.orderStatusTypeID === eOrderStatusType.historical ||
      order.orderStatusTypeID === eOrderStatusType.historicalDraft);
  }
}
