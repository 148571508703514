import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OrderStatus } from '../../interfaces/order-state/order-status';
import { ValidOrderStateTransitions } from '../../interfaces/order-state/valid-order-state-transitions';
import { Order } from '../../interfaces/orders/order';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class OrderStateRepositoryService {
  private _baseLink: string;

  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      this._baseLink = `${config.rootUrl}/Orders`;
    }

  // CRUD Operations ---------------
  public getOrderState = (orderID: string) => {
    return this.http.get<OrderStatus>(`${this._baseLink}/${orderID}/State`);
  }

  public getValidOrderStateTransitions(order: Order) {
    return this.getValidOrderStateTransitionsByState(order.orderStatusTypeID);
  }

  public getValidOrderStateTransitionsByState(orderState: string) {
    return this.http.get<ValidOrderStateTransitions>(`${this._baseLink}/States/${orderState}/Transitions`);
  }
  
  public updateOrderState = (orderID: string, orderStatus: OrderStatus) => {
    return this.http.put(`${this._baseLink}/${orderID}/State`, orderStatus, this.generateHeaders());
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }
}
