<!-- notification.component.html -->
<div class="notification">
    <div class="notification-header">
        {{notification.header}}
        <button mat-icon-button (click)="removeNotification(notification.id)" aria-label="Close notification" class="close-button">
        <mat-icon>close</mat-icon>
        </button>
    </div>
    <p>{{notification.body}}</p>
    <button *ngIf="notification.orderId" mat-button (click)="viewOrder(notification.id, notification.orderId)" aria-label="View order" class="close-button">
        View
    </button>
</div>
  