import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagementComponent } from './management/management.component';
import { UserGroupInfoComponent } from './user-group-info/user-group-info.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ManagementOptionsComponent } from './management-options/management-options.component';
import { AddReusablePartyComponent } from './add-reusable-party/add-reusable-party.component';
import { ReussablePartyInfoComponent } from './reusable-party-info/reusable-party-info.component';

const routes: Routes = [
    { 
        path: '',
        component: ManagementComponent,
        children: [
          { path: '', component: ManagementOptionsComponent },
          { path: 'userGroups/:userGroupID', component: UserGroupInfoComponent, data: { label: 'User Group', url: 'userGroups/00000000-0000-0000-0000-000000000000' }},
          { path: 'userGroups/:userGroupID/users/add', component: AddUserComponent, data: { label: 'Add User', url: 'userGroups/00000000-0000-0000-0000-000000000000/users/add' }},
          { path: 'userGroups/:userGroupID/users/:userID', component: UserInfoComponent, data: { label: 'User', url: 'userGroups/00000000-0000-0000-0000-000000000000/users/00000000-0000-0000-0000-000000000000' }},
          { path: 'reusableParties/add', component: AddReusablePartyComponent, data: { label: 'Add Reusable Party', url: 'reusableParties/add' }},
          { path: 'reusableParties/:reusablePartyID', component: ReussablePartyInfoComponent, data: { label: 'User', url: 'reusableParties/00000000-0000-0000-0000-000000000000' }},
        ]
      }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ManagementRoutingModule { }