import { Component, ViewChild } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { EmptyEntityService, ePartyType, Order, OrderManagerService, PartiesFormComponent, Party, PartyRepositoryService, UIConfigurationService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { PartiesComponent } from '../parties/parties.component';

@Component({
  selector: 'app-registering-agent',
  templateUrl: './registering-agent.component.html',
  styleUrls: ['./registering-agent.component.css']
})
export class RegisteringAgentComponent extends PartiesComponent {
  @ViewChild(PartiesFormComponent) agentform!: PartiesFormComponent;

  protected businessRegisteringAgent = ePartyType.businessRegisteringAgent;
  protected individualRegisteringAgent = ePartyType.individualRegisteringAgent;
  protected defaultType = ePartyType.businessRegisteringAgent;

  protected agent!: Party;

  constructor(
    partyRepository: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService,
    private emptyEntityService: EmptyEntityService) { 
    super(partyRepository, uiConfigurationService, orderManager, validationService);
  }

  public override init(order: Order): void {
    super.init(order);

    this.agent = order.parties?.find(party => Constants.isRegisteringAgent(party.partyTypeID)) || this.emptyEntityService.getEmptyParty(this.defaultType);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }

  protected override getParties(): Party[] {
    return this.order.parties!.filter(party => Constants.isRegisteringAgent(party.partyTypeID));
  }

  public override onSaving(): void {
    const agent = this.agentform.getParty();
    this.replaceParty(agent);
  }
}
