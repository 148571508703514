<h3>Secured Parties</h3>
<!-- SecuredParties -->
<div *ngIf="uiConfiguration?.hasSecuredParties" class="flex-container no-margin">
    <lib-parties-list
        partyTypeTitle="Secured Party"
        [order]="order"
        [observableParties$]="parties$"
        [errors$]="observableErrors$"
        class="flex-item"
        [isEditable]="isEditable"
        [businessPartyType]="businessSecuredParty"
        [individualPartyType]="individualSecuredParty"
        [defaultPartyType]="businessSecuredParty"
        [uiConfiguration]="uiConfiguration!.securedPartiesConfiguration!"
        [expandedParty]="newParty"
        [reusableParties]="reusableSecuredParties"
        (partyAddedEvent)="handlePartyAddedEvent($event)"
        (reusablePartySavedEvent)="handleExistingPartySaveEvent($event)"
        (partyUpdatedEvent)="handlePartySavedEvent($event)"
        (partyCopiedEvent)="handlePartyCopiedEvent($event)"
        (partyRemovedEvent)="handlePartyRemovedEvent($event)"
        (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
    </lib-parties-list>
</div>