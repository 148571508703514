import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { PaginatedRepository } from 'reg-hub-common';
import { ProductPricing } from 'reg-hub-common';
import { EnvironmentUrlService } from '../environment-url/environment-url.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class DisbursementPricingRepositoryService extends PaginatedRepository<ProductPricing> {
    protected override _baseLink: string;

    constructor(
      http: HttpClient,
      messageService: ToastrService,
      private environmentUrl: EnvironmentUrlService) {
      super(
          http,
          messageService);
    
          this._currentParams = new HttpParams()
            .set('pageSize', 10);
            
          this._baseLink = `${this.environmentUrl.urlAddress}/ProductPricing`;
      }
}