import { Component, Input } from '@angular/core';
import { BusinessSearchResult } from '../../interfaces/business-search-results/business-search-result';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-business-search-result-preview',
  templateUrl: './business-search-result-preview.component.html',
  styleUrls: ['./business-search-result-preview.component.css']
})
export class BusinessSearchResultPreviewComponent {
  @Input() result!: BusinessSearchResult

  constructor(private router: Router) { }

  onReportSelected(orderId: string): void {
    if (orderId) {
      this.router.navigate(['/orders', orderId]);
    }
  }
}
