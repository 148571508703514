import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationState, ReportTemplate, ReportTemplatesRepositoryService, ConfirmDialogComponent, RequestedReportRepositoryService, eRequestedReportType } from 'reg-hub-common';
import { RequestedReportForCreation } from 'reg-hub-common';

@Component({
    selector: 'app-template-report',
    templateUrl: './template-report.component.html',
    styleUrls: ['./template-report.component.css'],
})
export class TemplateReportComponent {
    _selectedIndex: number = 0;
    _corporationID: string | null = null;
    _reportTemplate: ReportTemplate | null = null;

    constructor(private configurationState: ConfigurationState,
        private reportTemplatesService: ReportTemplatesRepositoryService,
        private requestedReportsService: RequestedReportRepositoryService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.configurationState.getCorporationID().then(corporationID => {
            this._corporationID = corporationID;

            let reportTemplateID = this.activatedRoute.snapshot.paramMap.get('reportTemplateID')!;

            this.reportTemplatesService.populateBaseLink([corporationID]);
            this.requestedReportsService.populateBaseLink([corporationID]);

            this.reportTemplatesService.getReportTemplate(corporationID, reportTemplateID)
                .subscribe(report => {
                    this._reportTemplate = report;
                });
        })
    }

    onSaveReport() {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Save Report',
                confirmationPrompt: 'Are you sure you would like to save the current report as csv?',
                positiveResponseLabel: "Save",
                negativeResponseLabel: "Cancel"
            },
            minWidth: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // submission is confirmed, submit order
                this.saveReport();
            }
        });
    }

    saveReport() {
        let newReport: RequestedReportForCreation = {
            reportName: `${this._reportTemplate!.name} - `,
            requestedReportTypeID: eRequestedReportType.Orders,
            reportTemplateID: this._reportTemplate!.id
        };

        this.requestedReportsService.createRequestedReport(this._corporationID!, newReport)
        .subscribe({
            next: () => {
                this._selectedIndex = 1;
                this.requestedReportsService.getFirstPage();
            },
            error: () => {
                //Log error here
            }
        });
    }
}