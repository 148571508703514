import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, DocumentsRepositoryService, ListComponent, RequestedReport, RequestedReportRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-saved-template-reports-list',
    templateUrl: './saved-template-reports-list.component.html',
    styleUrls: ['./saved-template-reports-list.component.css']
})
export class SavedTemplateReportsListComponent extends ListComponent<RequestedReport> {
  displayedColumns: string[] = [
    'reportName',
    'added',
    'state',
    'download',
    'delete'
  ];

  constructor(
    private requestedReportService: RequestedReportRepositoryService,
    private documentsRepository: DocumentsRepositoryService,
    private dialog: MatDialog) {
    super(requestedReportService);
  }

  ngOnInit() {
  }

  getIcon(requestedReport: RequestedReport) {
    switch (requestedReport.state) {
      case "Pending":
        return 'archive';
      case "Completed":
        return 'history';
      default:
        return '';
    }
  }

  isComplete(requestedReport: RequestedReport) {
    return (requestedReport.state === "Completed");
  }

  downloadDocument(requestedReport: RequestedReport) {
    if (!requestedReport.document) {
      return;
    }

    this.documentsRepository.viewAndDownloadDocumentWithType(requestedReport.document.id, "application/xml");
  }

  onDelete(requestedReport: RequestedReport) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { 
        title: "Delete Report", 
        confirmationPrompt: "Are you sure you want to delete this saved report",
        positiveResponseLabel: "Delete",
        negativeResponseLabel: "Cancel"
      },
      minWidth: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // submission is confirmed, submit order
        this.delete(requestedReport);
      }
    });
  }

  delete(requestedReport: RequestedReport) {
    this.requestedReportService.deleteRequestedReport(requestedReport.corporationID, requestedReport.id)
      .subscribe({
        next: () => {
          this.repo.refresh();
        }
      })
  }
}