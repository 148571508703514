<div class="padding" *ngIf="hasAccess">
    <h1 class="left-box">New User</h1>
    <mat-card>
        <mat-card-content>
            <div class="flex-container padding-top">
                <div class="flex-item form-field">
                    <mat-radio-group [(ngModel)]="this.newUser.userTypeID" aria-label="Lien term or infinite lien radio option">
                        <mat-radio-button class="radio-button" [value]="apiUserType" (change)="onUserTypeSelectionChange($event)">API</mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="portalUserType" (change)="onUserTypeSelectionChange($event)">Client Portal</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="flex-container">
                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>First Name</mat-label>
                    <input matInput name="firstName"[(ngModel)]="this.newUser.firstName" placeholder="First Name">
                </mat-form-field>

                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Middle Name</mat-label>
                    <input matInput name="middleName"[(ngModel)]="this.newUser.middleName" placeholder="Middle Name">
                </mat-form-field>

                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Last Name</mat-label>
                    <input matInput name="lastName"[(ngModel)]="this.newUser.lastName" placeholder="Last Name">
                </mat-form-field>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="flex-item">
                    <mat-label>Username</mat-label>
                    <input matInput name="username" [(ngModel)]="this.newUser.userName" placeholder="Username">
                </mat-form-field>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>{{passwordFieldLabel}}</mat-label>
                    <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="this.newUser.password" [placeholder]="passwordFieldLabel">
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>{{confirmPasswordFieldLabel}}</mat-label>
                    <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="confirmPassword" [placeholder]="confirmPasswordFieldLabel">
                    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
                        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Configuration Override (Optional)</mat-label>
                    <mat-select name="configuration" [(ngModel)]="this.newUser!.configurationDetailsID">
                        <mat-option *ngFor="let configuration of configurationDetailsOptions" [value]="configuration.id">
                            {{configuration.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3>Contact Information</h3>
            <app-client-contact-details-card [contactDetails]="newUser!.contactDetails"></app-client-contact-details-card>

            <div class="flex-container">
                <span class="spacer"></span>
                <button mat-raised-button (click)="saveUser()" color="accent" class="apply-padding" aria-label="Save User Button">
                    Save
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>