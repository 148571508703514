<h2 mat-dialog-title>Duplicate {{ getOrderType() }} in Another Jurisdiction</h2>
<mat-dialog-content>
    <div class="flex-container">
        <mat-form-field class="flex-item form-field" appearance="outline">
            <mat-label>Jurisdiction</mat-label>
            <mat-select name="jurisdiction" [(ngModel)]="selectedJurisdiction"
                (selectionChange)="handleJurisdictionChangeEvent()">
                <mat-option *ngFor="let jurisdiction of jurisdictions" [value]="jurisdiction.jurisdictionCode">
                    {{jurisdiction.jurisdictionFullName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex-container" *ngIf="showQcFormTypeControl()">
        <mat-form-field class="flex-item form-field" appearance="outline">
            <mat-label>Form Type</mat-label>
            <mat-select name="qc-from-type" [(ngModel)]="selectedQcForm">
                <mat-option *ngFor="let qcFormType of qcFormTypes" [value]="qcFormType.value">
                    {{qcFormType.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
    <button mat-raised-button [disabled]="shouldDisableDuplicateButton()" color="primary" (click)="onDuplicateClick()" [mat-dialog-close]="true">Duplicate</button>
</mat-dialog-actions>