<div class="padding" *ngIf="!loading">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex">
                    <mat-tab label="Service Pricing">
                        <app-service-pricing></app-service-pricing>
                    </mat-tab>
                    <mat-tab label="Disbursement Pricing">
                        <app-disbursement-pricing></app-disbursement-pricing>
                    </mat-tab>
                    <mat-tab *ngIf="hasAccountingAccess" label="Invoices">
                        <app-invoices></app-invoices>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>