import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { AuthService } from '../../auth-common/auth/auth.service';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { OrderFee } from '../../interfaces/order-fees/order-fee';

@Injectable({
  providedIn: 'root'
})
export class OrderFeeRepositoryService extends PaginatedRepository<OrderFee> {
  protected override _baseLink: string;
  protected rootURL: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this.rootURL = config.rootUrl;
    this._baseLink = `${config.rootUrl}/OrderFees`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public getOrderFees = (orderID: string) => {
    return this.http.get<OrderFee[]>(`${this.rootURL}/Orders/${orderID}/OrderFees`, this.generateHeaders());
  }

  public getExpectedOrderFees = (orderID: string) => {
    return this.http.get<OrderFee[]>(`${this.rootURL}/Orders/${orderID}/OrderFees/Expected`, this.generateHeaders());
  }
}
