<div class="padding">
    <form [formGroup]="lienDetails">
        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Registration Number</mat-label>
                    <input matInput name="registration-number" placeholder="Registration Number"
                        [formControlName]="'registrationNumber'">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['registrationNumber']"
                    [message]="registrationNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div  class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Registration Date</mat-label>
                    <input matInput [matDatepicker]="registrationDate" name="registration-date" formControlName="registrationDate"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="registrationDate"></mat-datepicker-toggle>
                    <mat-datepicker #registrationDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('registrationDate')!"
                    [message]="registrationDateError$"></lib-error-bubble>
            </div>
        </div>

        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$"
            [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput name="amount" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showRIN" class="flex-container padding-top">
            <div class="flex-item form-field">
                <mat-radio-group formControlName="customRin" name="rin-radio"
                    aria-label="Lien term or infinite lien radio option">
                    <mat-radio-button class="radio-button" name="no-rin" [value]="'no'">No RIN</mat-radio-button>
                    <mat-radio-button class="radio-button" name="choose-rin" [value]="'choose'">Choose
                        RIN</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showRIN && lienDetails.get('customRin')!.value === 'choose'" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>RIN</mat-label>
                    <input matInput name="rin" placeholder="RIN" formControlName="rin">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['rin']" [message]="rinError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture" class="flex-container flex-item padding-top">
            <div class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" formControlName="trustIndenture" name="trust-indenture">
                    Trust Indenture
                </mat-checkbox>
            </div>
            <lib-error-bubble [control]="lienDetails.controls['trustIndenture']" [message]="trustIndentureError$"></lib-error-bubble>
        </div>
    </form>
</div>