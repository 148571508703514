<div class="padding">
    <div class="flex-container">
        <span class="form-field flex-item">
            <button mat-fab extended (click)="openSummaryDocument()" *ngIf="hasSummaryDocument()"
                color="accent" class="apply-padding padding-sides-5" aria-label="Download Result Document"
                [class.spinner]="this.isSaving" [disabled]="this.isSaving">
                <mat-icon aria-hidden="false" aria-label="Download icon" fontIcon="download"></mat-icon>
                <mat-label>Summary Document</mat-label>
            </button>
        </span>
        <span class="form-field flex-item">
            <button mat-fab extended (click)="openResultDocument()" *ngIf="hasResultDocument()"
                color="accent" class="apply-padding padding-sides-5" aria-label="Download Result Document"
                [class.spinner]="this.isSaving" [disabled]="this.isSaving">
                <mat-icon aria-hidden="false" aria-label="Download icon" fontIcon="download"></mat-icon>
                <mat-label>Result Document</mat-label>
            </button>
        </span>
    </div>
</div>