import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientAuthService } from '../../services/auth/client-auth.service';
import { NotificationService } from 'reg-hub-common';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent {
  constructor(private _authService: ClientAuthService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _notificationService: NotificationService) { }
  ngOnInit(): void {
    this._route.queryParams.pipe(
      switchMap(params => {
        const message = params['message'];
        if (message) {
          this._notificationService.addNotification("Notification", decodeURIComponent(message), "");
        }
        if (this._authService.isLoggedIn()) {
          return this._router.navigate(['dashboard']);
        }
        return [];
      })
    ).subscribe();
  }
}
