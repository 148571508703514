<div class="padding">
    <form [formGroup]="discharge">
        <div class="flex-container">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'dischargeType'" aria-label="Discharge type radio option">
                    <mat-radio-button name="total-discharge-selection" class="radio-button" [value]="totalDischarge">Total Discharge</mat-radio-button>
                    <mat-radio-button name="agreement-to-discharge-selection" class="radio-button" [value]="agreementToDischarge">Agreement to Discharge</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </form>
</div>