import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { Order, OrderManagerService, ValidationProblem, ValidationService, eDischargeType } from 'reg-hub-common';

@Component({
  selector: 'app-qc-discharge',
  templateUrl: './qc-discharge.component.html',
  styleUrls: ['./qc-discharge.component.css']
})
export class QCDischargeComponent extends SteppedComponent implements OnInit {
  protected discharge!: FormGroup;

  protected totalDischarge: eDischargeType = eDischargeType.totalDischarge;
  protected agreementToDischarge: eDischargeType = eDischargeType.agreementToDischarge;

  constructor(private formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService
  ) {
    super(orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.discharge = this.formBuilder.group({
      dischargeType: [this.order?.discharge?.dischargeType ?? this.totalDischarge],
    });
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }

  public override onSaving(): void {
    this.order!.discharge!.dischargeType = this.discharge.get('dischargeType')?.value
  }
}