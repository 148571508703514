<div class="padding" *ngIf="hasAccess">
    <h1 class="left-box">New Reusable Party</h1>

    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field grows-to-fill">
                    <mat-label>Party Type</mat-label>
                    <mat-select [(ngModel)]="reusableParty.partyTypeID">
                        <mat-option *ngFor="let partyType of partyTypes" [value]="partyType.value">
                        {{partyType.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="!isBusiness()">
                    <mat-checkbox [(ngModel)]="this.reusableParty.isEstate"
                        name="is-estate">
                        Estate
                    </mat-checkbox>
                </div>
            </div>

            <div class="flex-container">
                <mat-form-field appearance="outline" class="form-field" *ngIf="isBusiness(); else isPerson">
                    <mat-label>Name</mat-label>
                    <input matInput name="name" [(ngModel)]="this.reusableParty.busName" placeholder="Name">
                </mat-form-field>
                <ng-template #isPerson>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>First Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.firstName" placeholder="Name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Middle Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.middleName" placeholder="Name">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-label>Last Name</mat-label>
                        <input matInput name="name" [(ngModel)]="this.reusableParty.lastName" placeholder="Name">
                    </mat-form-field>
                </ng-template>
            </div>

            <app-client-contact-details-card [contactDetails]="reusableParty.contactDetails!"></app-client-contact-details-card>

            <div class="flex-container">
                <button
                    mat-raised-button
                    (click)="cancel()"
                    class="apply-padding"
                    aria-label="Cancel button"
                    [class.spinner]="this.isSaving"
                    [disabled]="this.isSaving">
                    Cancel
                </button>
                <span class="spacer"></span>
                <button
                    mat-raised-button
                    (click)="saveReusableParty()"
                    color="accent"
                    class="apply-padding"
                    aria-label="Save reusable party button"
                    [class.spinner]="this.isSaving"
                    [disabled]="this.isSaving">
                    Save
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>