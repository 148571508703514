import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notification, NotificationService } from 'reg-hub-common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {
  @Input() notification!: Notification;

  constructor(private notificationService: NotificationService,
    private router: Router) {}

  ngOnInit(): void {}

  removeNotification(id: number) {
    this.notificationService.removeNotification(id);
  }

  viewOrder(id: number, orderId: string) {
    this.notificationService.removeNotification(id);
    
    this.router.navigate(['orders', orderId]);
  }
}
