import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../interfaces/confirm-order-undelete-data';

@Component({
  selector: 'lib-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  protected positiveResponseColor: string = 'accent';
  protected negativeResponseColor: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

  ngOnInit(): void {
    if(this.data.positiveResponseColor) {
      this.positiveResponseColor = this.data.positiveResponseColor;
    }

    if(this.data.negativeResponseColor) {
      this.negativeResponseColor = this.data.negativeResponseColor;
    }
  }
}
