import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Observable } from 'rxjs';
import { GeneralCollateralHistory } from '../../interfaces/general-collateral/general-collateral-history';

@Injectable({
  providedIn: 'root'
})
export class OrderGroupsService {
  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  public getGeneralCollateralHistory(orderGroupId: string): Observable<GeneralCollateralHistory[]> {
    return this.http.get<GeneralCollateralHistory[]>(`${this.config.rootUrl}/OrderGroups/${orderGroupId}/Orders/GeneralCollateralHistory`);
  }
}
