import { Component, Input } from '@angular/core';
import { DocumentsRepositoryService } from '../../services/document/documents-repository.service';
import { base64StringToBlob } from 'blob-util';

@Component({
    selector: 'lib-document-download',
    templateUrl: './document-download.component.html',
    styleUrls: ['./document-download.component.css']
})
export class DocumentDownloadComponent {
    @Input({ required: true }) documentID!: string | null | undefined;
    @Input() documentName: string = "Document"

    constructor(private documentsRepo: DocumentsRepositoryService) { }

    downloadDocument() {
        if (this.documentID) {
            //Get Document
            this.documentsRepo.getDocument(this.documentID!)
                .subscribe(result => {
                    const documentType = `application/${result?.documentTypeID ?? "pdf" }`.toLowerCase();
                    const blob = base64StringToBlob(result.base64, documentType);
                    const blobURL = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = blobURL;
                    link.download = result.fileName; // set a name for the file
                    link.click();
                });
        }
    }
}