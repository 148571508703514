import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountingComponent } from './accounting/accounting.component';
import { AccountingOptionsComponent } from './accounting-options/accounting-options.component';
import { InvoiceInfoComponent } from './invoice-info/invoice-info.component';

const routes: Routes = [
    { 
        path: '',
        component: AccountingComponent,
        children: [
          { path: '', component: AccountingOptionsComponent },
          { path: 'invoices/:invoiceID', component: InvoiceInfoComponent, data: { breadcrumb: { label: 'Invoice', url: 'invoices/00000000-0000-0000-0000-000000000000' } } }
        ]
      }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class AccountingRoutingModule { }