<div class="padding" *ngIf="hasExistingParties()">
    <div class="flex-container">
        <mat-form-field class="flex-item-6" appearance="outline">
            <mat-select placeholder="Reusable Party" [(ngModel)]="this.selectedExistingPartyID">
                <mat-option *ngFor="let party of existingParties" [value]="party.id!">
                    {{ party.fullName! }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button
            (click)="saveExistingParty()"
            [disabled]="selectedExistingPartyID == ''"
            color="primary"
            class="margin-top-15 padding-left-25 flex-item"
            aria-label="Save Reusable Party button">
            Add Reusable Party
        </button>
    </div>
</div>