import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { ReviewComponent } from "../../../../orders/review/review.component";
import { BasicLienDetailsComponent } from "../../../../orders/basic-lien-details/basic-lien-details.component";
import { DebtorComponent } from "../../../../orders/debtor/debtor.component";
import { SecuredPartyComponent } from "../../../../orders/secured-party/secured-party.component";

export class ABBasicDischargeStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: BasicLienDetailsComponent, validationState: StepValidationState.DefaultState },
            { title: "Debtor", content: DebtorComponent, validationState: StepValidationState.DefaultState },
            { title: "Secured Party", content: SecuredPartyComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}