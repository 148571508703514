import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { GeneralCollateralUIConfiguration } from '../../interfaces/ui-configurations/general-collateral-ui-configuration';
import { MatDialog } from '@angular/material/dialog';
import { GeneralCollateralHistoryPopupComponent } from '../general-collateral-history-popup/general-collateral-history-popup.component';

@Component({
  selector: 'lib-general-collateral',
  templateUrl: './general-collateral.component.html',
  styleUrls: ['./general-collateral.component.css']
})
export class GeneralCollateralComponent implements OnInit, OnDestroy {
  @Input() uiConfiguration: GeneralCollateralUIConfiguration | null | undefined;
  @Input() hideHistory: boolean = false;
  order!: Order;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private orderManager: OrderManagerService) {}

  ngOnInit(): void {
    this.orderManager.currentOrder
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(order => this.order = order);
  }

  openGeneralCollateralHistory() {
    this.dialog.open(GeneralCollateralHistoryPopupComponent, {
      data: this.order,
      minWidth: '800px'
    })
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}