import { DatePipe } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { PaginatedRepository } from '../services/pagination/paginated.repository';

export abstract class FiltersComponent<TEntity> {

    protected _filterForm: FormGroup = this.formBuilder.group({});
    protected _defaultParams: HttpParams = new HttpParams();

    protected filtered: boolean = false;

    constructor(
        protected repo: PaginatedRepository<TEntity>,
        protected formBuilder: FormBuilder,
        protected datePipe: DatePipe) { }

    protected onFilter() {
        if(this._filterForm.dirty) {
            let params = this.buildParamsFromForm(this._filterForm);

            this._defaultParams.keys().forEach(key => {
                let value = this._defaultParams.get(key);

                if(value) {
                    params = params.append(key, value);
                }
            });

            this.repo.filterSubscribable(params).subscribe(() => this.filtered = true)
        }
    }

    protected onClear() {
        if (this._filterForm.dirty) {
            let initialFormValues: { [key: string]: any } = {};

            for(const key of this._defaultParams.keys()) {
                initialFormValues[key] = this._defaultParams.get(key);
            }

            this._filterForm.reset(initialFormValues);                            

            if(this.filtered) {
                this.repo.filterSubscribable(this._defaultParams).subscribe(() => this.filtered = false);
            }
        }
    }

    protected buildParamsFromForm(group: FormGroup | FormArray): HttpParams {
        const parameterNames = Object.keys(group.controls);
        let httpParams = new HttpParams();

        for(const parameterName of parameterNames) {
            const control = group.get(parameterName);

            if(control) {
                if(control instanceof FormGroup || control instanceof FormArray) {
                    httpParams = this.mergeParams(httpParams, this.buildParamsFromForm(control));
                } else if(control.value !== null) {
                    httpParams = this.appendParam(httpParams, parameterName, control.value);
                }
            }
        }

        return httpParams;
    }

    protected buildParamsFromObject(params: any): HttpParams {
        return new HttpParams().appendAll(params);
    }

    protected appendParam(params: HttpParams, parameterName: string, parameterValue: any): HttpParams {
        if(parameterValue instanceof Date) {
            return params.append(parameterName, this.datePipe.transform(parameterValue, 'yyyy-MM-dd hh:mm') ?? '');
        } else if(Array.isArray(parameterValue)) {
            for(const value of parameterValue) {
                params = params.append(parameterName, value);
            }

            return params;
        } else {
            return params.append(parameterName, parameterValue);
        }
    }

    protected mergeParams(left: HttpParams, right: HttpParams): HttpParams {
        let params = new HttpParams();

        for(const key of left.keys()) {
            const values = left.getAll(key);

            if(values) {
                for(const value of values) {
                    params = params.append(key, value);
                }
            }
        }

        for(const key of right.keys()) {
            const values = right.getAll(key);

            if(values) {
                for(const value of values) {
                    params = params.append(key, value);
                }
            }
        }

        return params;
    }
}