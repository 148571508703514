import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PartyForCreation } from '../../interfaces/parties/party-for-creation';
import { ReusableParty } from '../../interfaces/reusable-parties/reusable-party';

@Component({
    selector: 'lib-handle-existing-party',
    templateUrl: './handle-existing-party.component.html',
    styleUrls: ['./handle-existing-party.component.css']
})
export class HandleExistingPartyComponent {
    selectedExistingPartyID: string = '';
    @Input() country: string = '';
    @Input() jurisdiction: string = '';
    @Input() existingParties: ReusableParty[] = [];
    @Output() existingPartySavedEvent = new EventEmitter<PartyForCreation>();

    hasExistingParties(): boolean {
        return this.existingParties.length > 0;
    }

    saveExistingParty() {
        this.existingParties.forEach(party => {
            if (party.id == this.selectedExistingPartyID) {
                var reusableRegistryCodes = party.reusablePartyCodes?.filter(p => p.country == this.country && p.jurisdiction == this.jurisdiction);
                let existingParty: PartyForCreation = {
                    busName: party.busName ?? null,
                    busNumber: "",
                    firstName: party.firstName ?? null,
                    middleName: party.middleName ?? null,
                    lastName: party.lastName ?? null,
                    isEstate: party.isEstate ?? null,
                    dateOfBirth: null, //DOB missing
                    priority: 0,
                    reusableRegistryCode: (reusableRegistryCodes?.length ?? 0) > 0 ? reusableRegistryCodes![0].code : null, //Registry Code Missing
                    partyTypeID: party.partyTypeID,
                    generationID: null,
                    externalEntity: null,
                    contactDetails: party.contactDetails
                }

                this.existingPartySavedEvent.emit(existingParty);
                this.selectedExistingPartyID = "";
            }
        })
    }
}