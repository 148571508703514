<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-select [formControlName]="'reportTemplateID'" placeholder="Report">
                    <mat-option *ngFor="let template of _reportTemplates" [value]="template.id">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Requested From</mat-label>
                <input matInput [matDatepicker]="addedFrom" [formControlName]="'addedFrom'"
                    placeholder="Date Requested From">
                <mat-datepicker-toggle matSuffix [for]="addedFrom"></mat-datepicker-toggle>
                <mat-datepicker #addedFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field flex-item">
                <mat-label>Date Requested To</mat-label>
                <input matInput [matDatepicker]="addedTo" [formControlName]="'addedTo'"
                    placeholder="Date Requested To">
                <mat-datepicker-toggle matSuffix [for]="addedTo"></mat-datepicker-toggle>
                <mat-datepicker #addedTo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="spacer-small"></div>

        <div>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>