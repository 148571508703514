import { Component, Inject, OnInit } from '@angular/core';
import { GeneralCollateralHistory } from '../../interfaces/general-collateral/general-collateral-history';
import { OrderGroupsService } from '../../services/order-groups/order-groups.service';
import { Order } from '../../interfaces/orders/order';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-general-collateral-history-popup',
  templateUrl: './general-collateral-history-popup.component.html',
  styleUrls: ['./general-collateral-history-popup.component.css']
})
export class GeneralCollateralHistoryPopupComponent implements OnInit {
  order!: Order;

  protected history: GeneralCollateralHistory[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private orderGroupsService: OrderGroupsService) { 
      this.order = data;
    }

  ngOnInit(): void {
    this.orderGroupsService.getGeneralCollateralHistory(this.order.orderGroup?.id ?? '')
      .subscribe(history => this.history = history);
  }
}
