<h2 mat-dialog-title>Validation Logs</h2>
<mat-dialog-content>
    <table mat-table *ngIf="validationLogs.length > 0" [dataSource]="validationLogs" multiTemplateDataRows class="mat-elevation-z2">

        <!-- Severity -->
        <ng-container matColumnDef="validationLogTypeID">
            <th mat-header-cell *matHeaderCellDef> Validation Type </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.validationLogTypeID}} </td>
        </ng-container>

        <!-- Messsage -->
        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Messsage </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.message}} </td>
        </ng-container>

        <!-- path -->
        <ng-container matColumnDef="path">
            <th mat-header-cell *matHeaderCellDef> Path </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.path}} </td>
        </ng-container>

        <!-- Messsage -->
        <ng-container matColumnDef="detailedMessage">
            <th mat-header-cell *matHeaderCellDef> Detailed Message </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.detailedMessage}} </td>
        </ng-container>

        <!-- Username -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Username </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.username}} </td>
        </ng-container>

        <!-- Added -->
        <ng-container matColumnDef="added">
            <th mat-header-cell *matHeaderCellDef> Added </th>
            <td mat-cell *matCellDef="let validationLog"> {{validationLog.added | localizedDate:'yyyy-MM-dd HH:mm:ss'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>
    <lib-placeholder iconName="warning" iconClass="icon-info"
        message="No validations accepted" 
        [condition]="validationLogs.length === 0"></lib-placeholder>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>