<div>
    <!-- Main container -->
    <div>
        <div class="flex-container">
            <!-- Search widget -->
            <div class="flex-item padding-sides-15">
                <lib-search-bar (search)="onSearch($event)"></lib-search-bar>
            </div>
            <!-- End of pending orders widget-->
        </div>

        <div class="flex-container-stretch">
            <!-- Draft orders widget -->
            <div class="flex-item">
                <div class="padding grows-to-fill">
                    <app-draft-order-list class="grows-to-fill" [data]="this._draftOrders" [total]="this._totalDraftOrders" [loading]="this._loading"></app-draft-order-list>
                </div>
            </div>
            <!-- End of pending orders widget-->

            <!-- Pending orders widget -->
            <div class="flex-item">
                <div class="padding-top-bottom grows-to-fill">
                    <app-pending-order-list class="grows-to-fill" [data]="this._pendingOrders" [total]="this._totalPendingOrders" [loading]="this._loading"></app-pending-order-list>
                </div>
            </div>
            <!-- End of pending orders widget-->

            <!-- Invalid orders widget -->
            <div class="flex-item">
                <div class="padding grows-to-fill">
                    <app-invalid-order-list class="grows-to-fill" [data]="this._invalidOrders" [total]="this._totalInvalidOrders" [loading]="this._loading"></app-invalid-order-list>
                </div>
            </div>
            <!-- End of pending orders widget-->
        </div>

        <div class="flex-container">
            <!-- Pending orders widget -->
            <div class="flex-item">
                <div class="padding">
                    <app-complete-order-list [data]="this._completeOrders" [total]="this._totalCompleteOrders" [loading]="this._loading"></app-complete-order-list>
                </div>
            </div>
            <!-- End of pending orders widget-->
        </div>
    </div>
    <!-- End of main container -->

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this._loading" class="loading-spinner-overlay">
        <mat-spinner></mat-spinner>
    </div>
    <!-- End of spinner -->
</div>