import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Order, ValidationLog, ValidationLogsRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-order-validation-logs',
  templateUrl: './order-validation-logs.component.html',
  styleUrls: ['./order-validation-logs.component.css']
})
export class OrderValidationLogsComponent {
  order: Order;
  validationLogs: ValidationLog[] = [];

  displayedColumns: string[] = [
    'validationLogTypeID', 
    'message', 
    'path', 
    'detailedMessage', 
    'username',
    'added'
  ];

  constructor(protected validationLogsRepo: ValidationLogsRepositoryService,
    @Inject(MAT_DIALOG_DATA) public dialogOrder: Order,
    private router: Router
  ) {
    this.order = dialogOrder;
  }

  ngOnInit(): void {
    var params = new HttpParams();
    params = params.append('orderID', this.order.id);
    params = params.append('pageSize', 100);

    this.validationLogsRepo.getFirstPageSubscribable(params).subscribe(logs => {
      this.validationLogs = logs;
    });
  }
}
