import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { FiltersComponent, RequestedReport, ReportTemplate, RequestedReportRepositoryService, ReportTemplatesRepositoryService, ConfigurationState } from 'reg-hub-common';

@Component({
    selector: 'app-saved-template-reports-filter',
    templateUrl: './saved-template-reports-filter.component.html',
    styleUrls: ['./saved-template-reports-filter.component.css'],
    providers: [DatePipe]
})
export class SavedTemplatesReportsFilterComponent extends FiltersComponent<RequestedReport> implements OnInit {
    protected autoExpand: boolean = false;
    protected _reportTemplates: ReportTemplate[] = [];

    constructor(
        private route: ActivatedRoute,
        private configurationState: ConfigurationState,
        repo: RequestedReportRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe) {
        super(
            repo,
            formBuilder,
            datePipe);
    }

    ngOnInit() {
        let reportTemplateID = this.route.snapshot.paramMap.get('reportTemplateID')!;

        this._filterForm = this.formBuilder.group({
            reportTemplateID: [reportTemplateID],
            addedFrom: [null],
            addedTo: [null]
        })

        this.configurationState.getCorporationID().then(corporationID => {
            this.repo.populateBaseLink([corporationID]);

            this._defaultParams = this.buildParamsFromForm(this._filterForm);

            this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
                const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

                if (routeQueryStringParamKeys.length > 0) {
                    let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                    routeQueryStringParamKeys.forEach(key => {
                        const control = this._filterForm.get(key);

                        if (control) {
                            control.setValue(routeQueryStringParams[key]);
                            this._filterForm.markAsDirty();
                            this.autoExpand = routeQueryStringParams["autoExpand"] ?? false;
                            this.filtered = true;
                        }
                    })

                    this.repo.getFirstPage(preFilteredParams);
                } else {
                    this.repo.getFirstPage(this._defaultParams);
                }
            });
        });
    };
}