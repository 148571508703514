import { Component, ViewChild } from '@angular/core';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { ValidationProblem, Order, OrderManagerService, ValidationService } from 'reg-hub-common';
import { TermComponent } from '../term/term.component';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css']
})
export class RenewalComponent extends SteppedComponent {
  @ViewChild(TermComponent) termComponent!: TermComponent;

  constructor(
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  public override onSaving(): void {
    const termControl = this.termComponent.termDetails.get('term');
    const infiniteControl = this.termComponent.termDetails.get('isInfiniteTerm');
    const expiryDateControl = this.termComponent.termDetails.get('expiryDate');

    if(this.order.lien) {
      if(termControl) {
        this.order.lien.term = termControl?.value;
      }

      if(infiniteControl) {
        this.order.lien.isInfiniteTerm = infiniteControl?.value;
      }

      if(expiryDateControl) {
        this.order.lien.expiryDate = expiryDateControl?.value;
      }
    }
  }
}
