<mat-card>
    <mat-card-content>
        <div class="button-container" *ngIf="showDuplicateButton()">
            <a mat-flat-button color="primary"
                (click)="duplicate()">Duplicate</a>
        </div>
        <div class="button-container" *ngIf="order.orderStatusTypeID == 'Historical'">
            <a mat-flat-button color="primary" (click)="edit()">Edit</a>
        </div>
        <div class="button-container" *ngIf="uiConfiguration.hasAmendments && isActionable()">
            <a mat-flat-button color="primary" (click)="amend()">Amend</a>
        </div>
        <div class="button-container" *ngIf="!order.lien!.isInfiniteTerm && uiConfiguration.hasRenewals && isActionable()">
            <a mat-flat-button color="primary"
                (click)="renew()">Renew</a>
        </div>
        <div class="button-container" *ngIf="uiConfiguration.hasDischarges && isActionable()">
            <a mat-flat-button color="warn" (click)="discharge()">Discharge</a>
        </div>
    </mat-card-content>
</mat-card>