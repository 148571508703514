import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order, OrderManagerService, OrderRepositoryService, OrderStateRepositoryService, eOrderType, ePartyType, Party, eOrderStatusType } from 'reg-hub-common';
import { Router } from '@angular/router';
import { ActionUIConfiguration } from 'projects/reg-hub-common/src/lib/interfaces/ui-configurations/action-ui-configuration';
import { DuplicateInComponent } from '../duplicate-in/duplicate-in.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  @Input() order!: Order;
  @Input() uiConfiguration!: ActionUIConfiguration;
  protected baseOrderDetails!: FormGroup;
  protected isSaving: boolean = false;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private orderStateRepo: OrderStateRepositoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.baseOrderDetails = this.formBuilder.group({
      referenceNumber: [this.order.referenceNumber, Validators.required],
      country: [this.order.country, Validators.required],
      jurisdiction: [this.order.jurisdiction, Validators.required],
      orderTypeID: [this.order.orderTypeID, Validators.required],
      originalOrderID: [this.order.id, Validators.required]
    })
  }

  amend() {
    // replacer excludes properties with ID
    var copiedOrder = JSON.parse(JSON.stringify(this.order));
    copiedOrder.originalOrderID = this.order.id;
    copiedOrder.orderTypeID = eOrderType.amendment;
    copiedOrder.parties = copiedOrder.parties.filter((party: Party) => party.partyTypeID !== ePartyType.businessRegisteringAgent && party.partyTypeID !== ePartyType.individualRegisteringAgent);

    delete copiedOrder.id // Remove ID from request

    this.requestOrder(eOrderType.amendment, copiedOrder);
  }

  renew() {
    const newOrderType = this.order.orderTypeID === eOrderType.basicRenewal ? eOrderType.basicRenewal : eOrderType.renewal;

    if(newOrderType === eOrderType.basicRenewal) {
      this.requestOrder(newOrderType, {
        referenceNumber: this.order.referenceNumber,
        jurisdiction: this.order.jurisdiction,
        country: this.order.country,
        orderTypeID: newOrderType,
        originalOrderID: this.order.id,
        lien: { }
      } as any);
    } else {
      this.requestOrder(newOrderType);
    }
  }

  discharge() {
    const newOrderType = this.order.orderTypeID === eOrderType.basicDischarge || 
      this.order.orderTypeID === eOrderType.basicRenewal ? 
      eOrderType.basicDischarge : 
      eOrderType.discharge;

    if(newOrderType === eOrderType.basicDischarge) {
      this.requestOrder(newOrderType, {
        referenceNumber: this.order.referenceNumber,
        jurisdiction: this.order.jurisdiction,
        country: this.order.country,
        orderTypeID: newOrderType,
        originalOrderID: this.order.id,
        lien: { }
      } as any);
    } else {
      this.requestOrder(newOrderType);
    }
  }

  edit() {
    const newStatus = {
      newStatus: "HistoricalDraft",
      status: "",
      message: "Cancelled from Reg Hub Client"
    }

    this.orderStateRepo.updateOrderState(this.order.id, newStatus)
      .subscribe(result => {
        this.isSaving = false;
        this.router.navigate([
          `new-registration/${this.order.id}`
        ]);
      })
  }

  duplicate() {
    const dialogRef = this.dialog.open(DuplicateInComponent, { 
      data: this.order,
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.event === 'submitted') {
        this.isSaving = true;
      }
    })
  }

  showDuplicateButton(): boolean {
    return (this.uiConfiguration.hasDuplicateIn ?? false) &&
      !(this.order.lien?.registrationResults?.some(res => res.expiryDate ? new Date() > res.expiryDate : false) ?? true);
  }

  requestOrder(orderTypeID: eOrderType, order?: Order): boolean {
    this.orderManager.updateParentOrder(this.order);

    this.baseOrderDetails.patchValue({
      orderTypeID: orderTypeID
    });
    
    this.isSaving = true;

    // Otherwise, create it
    this.ordersRepo.createOrder("orders", order ?? this.baseOrderDetails.value)
      .subscribe(result => {
        this.isSaving = false;
        const order = (result as any).resource;
        this.orderManager.updateOrder(order);
        if (this.order.id) {
          this.router.navigate([
            `new-registration/${order.id}`
          ]);
        }
      })

    // do validation here
    return true;
  }

  isActionable(): boolean {
    return this.order.orderStatusTypeID === eOrderStatusType.complete ||
      this.order.orderStatusTypeID === eOrderStatusType.historical;
  }
}
