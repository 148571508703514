import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Order } from '../../interfaces/orders/order';
import { OrderRepositoryService } from '../../services/order/order-repository-service.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent {
  order: Order;
  orderHistory: Order[] = [];

  displayedColumns: string[] = [
    'reference', 
    'orderTypeID', 
    'orderStatusTypeID', 
    'country', 
    'jurisdiction', 
    'dateRequested',
    'dateUpdated'
  ];

  constructor(private ordersRepoService: OrderRepositoryService,
    @Inject(MAT_DIALOG_DATA) public dialogOrder: Order,
    private router: Router
  ) {
    this.order = dialogOrder;
  }

  ngOnInit(): void {
    var params = new HttpParams().set('isVisible', true);
    params = params.append('orderGroupID', this.order.orderGroup!.id);
    params = params.append('pageSize', 100);

    this.ordersRepoService.getFirstPageSubscribable(params).subscribe(orders => {
      this.orderHistory = orders;
    });
  }

  navigateToOrder(orderID: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['orders', orderID])
    );
  
    window.open(url, '_blank');
  }
}
