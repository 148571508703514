import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { DebtorsComponent } from "../../../../orders/debtors/debtors.component";
import { SecuredPartiesComponent } from "../../../../orders/secured-parties/secured-parties.component";
import { CollateralComponent } from "../../../../orders/collateral/collateral.component";
import { ReviewComponent } from "../../../../orders/review/review.component";
import { MBLienDetailsComponent } from "../../../../orders/lien-details/mb-lien-details/mb-lien-details.component";
import { RegisteringAgentComponent } from "../../../../orders/registering-agent/registering-agent.component";

export class MBLienStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: MBLienDetailsComponent, validationState: StepValidationState.DefaultState },
            { title: "Debtors", content: DebtorsComponent, validationState: StepValidationState.DefaultState },
            { title: "Sec. Parties", content: SecuredPartiesComponent, validationState: StepValidationState.DefaultState },
            { title: "Agent", content: RegisteringAgentComponent, validationState: StepValidationState.DefaultState },
            { title: "Collateral", content: CollateralComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}