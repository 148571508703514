import { Component } from '@angular/core';
import { Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseHistoricalLienDetailsComponent } from '../base-historical-lien-details.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-historical-bc-lien-details',
    templateUrl: './historical-bc-lien-details.component.html',
    styleUrls: ['./historical-bc-lien-details.component.css']
})
export class HistoricalBCLienDetailsComponent extends BaseHistoricalLienDetailsComponent {
    protected surrenderDateError$ = new BehaviorSubject<string | null | undefined>(null);

    constructor(
        formBuilder: FormBuilder,
        orderManager: OrderManagerService,
        validationService: ValidationService) {
        super(formBuilder, orderManager, validationService);
    }

    protected override init(order: Order): void {
        super.init(order);
        this.order.lien!.authorizationReceived = true;
    }

    protected override buildForm(): FormGroup {
        const form = this.formBuilder.group({}) as FormGroup;

        if (this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
            form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
        }

        if (this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
            form.addControl('trustIndenture', this.formBuilder.control(this.order.lien?.trustIndenture ?? false));
        }

        if (this.uiConfiguration.lienDetailsConfiguration?.showSurrenderDate) {
            form.addControl('surrenderDate', this.formBuilder.control(this.order.lien?.surrenderDate));
        }

        return form;
    }

    public override pushErrors(errors: ValidationProblem[] | undefined): void {
        super.pushErrors(errors);

        this.surrenderDateError$.next(errors?.filter(error => error.path.includes('/surrenderdate'))?.at(0)?.userFriendlyMessage);
    }
}