import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { InformationDocument } from '../../interfaces/information-documents/information-document';
import { InformationDocumentForCreation } from '../../interfaces/information-documents/information-document-for-creation';
import { InformationDocumentForUpdate } from '../../interfaces/information-documents/information-document-for-update';
import { DocumentBinary } from '../../interfaces/documents/document-binary';

@Injectable({
    providedIn: 'root'
})
export class InformationDocumentsRepositoryService extends PaginatedRepository<InformationDocument> {
    protected override _baseLink: string;

    constructor(
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
        http: HttpClient,
        messageService: ToastrService) {
        super(http, messageService);

        this._baseLink = `${this.config.rootUrl}/InformationDocuments`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getInformationDocument = (informationDocumentID: string) => {
        return this.http.get<InformationDocument>(`${this._baseLink}/${informationDocumentID}`, this.generateHeaders());
    }

    public getInformationDocumentBinary = (informationDocumentID: string) => {
        return this.http.get<DocumentBinary>(`${this._baseLink}/${informationDocumentID}/Document`, this.generateHeaders());
    }

    public createInformationDocument(newEvent: InformationDocumentForCreation): Observable<InformationDocument> {
        return this.http.post<InformationDocument>(`${this._baseLink}`, newEvent);
    }

    public updateInformationDocument(informationDocumentID: string, informationDocument: InformationDocumentForUpdate): Observable<InformationDocument> {
        return this.http.put<InformationDocument>(`${this._baseLink}/${informationDocumentID}`, informationDocument);
    }

    public deleteInformationDocument(informationDocumentID: string) {
        return this.http.delete(`${this._baseLink}/${informationDocumentID}`, this.generateHeaders());
    }
}