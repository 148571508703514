import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { ReviewComponent } from "projects/reg-hub-client/src/app/orders/review/review.component";

export class DischargeStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}