import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BillingDetails } from '../../interfaces/billing-details/billing-details';
import { BillingDetailsForCreation } from '../../interfaces/billing-details/billing-details-for-creation';
import { BillingDetailsForUpdate } from '../../interfaces/billing-details/billing-details-for-update';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class BillingDetailsRepositoryService extends PaginatedRepository<BillingDetails> {
  protected override _baseLink: string;
  protected _adminLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(
      http,
      messageService);

    this._currentParams = new HttpParams()
      .set('pageSize', 10);

    this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/BillingDetails`;
    this._baseLink = this._baseLinkTemplate
    this._adminLink = `${config.rootUrl}/BillingDetails`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  public createBillingDetails = (corporationID: string, billingDetails: BillingDetailsForCreation) => {
    this.populateBaseLink([corporationID]);

    return this.http.post<BillingDetails>(
      this._baseLink,
      billingDetails,
      this.generateHeaders());
  }

  public adminGetBillingDetails = (billingDetailsID: string) => {
    return this.http.get<BillingDetails>(`${this._adminLink}/${billingDetailsID}`, this.generateHeaders());
  }

  public getBillingDetails = (corporationID: string, billingDetailsID: string) => {
    this.populateBaseLink([corporationID]);

    return this.http.get<BillingDetails>(`${this._baseLink}/${billingDetailsID}`, this.generateHeaders());
  }

  public adminUpdateBillingDetails = (billingDetailsID: string, billingDetails: BillingDetailsForUpdate) => {
    return this.http.put(`${this._adminLink}/${billingDetailsID}`, billingDetails, this.generateHeaders());
  }

  public updateBillingDetails = (corporationID: string, billingDetailsID: string, billingDetails: BillingDetailsForUpdate) => {
    this.populateBaseLink([corporationID]);

    return this.http.put(`${this._baseLink}/${billingDetailsID}`, billingDetails, this.generateHeaders());
  }
}