import { Component, ViewChild } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { EmptyEntityService, ePartyType, Order, OrderManagerService, PartiesFormComponent, Party, PartyRepositoryService, UIConfigurationService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { PartiesComponent } from '../parties/parties.component';

@Component({
  selector: 'app-debtor',
  templateUrl: './debtor.component.html',
  styleUrls: ['./debtor.component.css']
})
export class DebtorComponent extends PartiesComponent {
  @ViewChild(PartiesFormComponent) debtorForm!: PartiesFormComponent;

  protected businessDebtor = ePartyType.businessDebtor;
  protected individualDebtor = ePartyType.individualDebtor;
  protected defaultType = ePartyType.individualDebtor;

  protected debtor!: Party;

  constructor(
    partyRepository: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService,
    private emptyEntityService: EmptyEntityService) { 
    super(partyRepository, uiConfigurationService, orderManager, validationService);
  }

  public override init(order: Order): void {
    super.init(order);

    this.debtor = order.parties?.find(party => Constants.isDebtor(party.partyTypeID)) || this.emptyEntityService.getEmptyParty(this.defaultType);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }

  protected override getParties(): Party[] {
    return this.order.parties!.filter(party => Constants.isDebtor(party.partyTypeID));
  }

  public override onSaving(): void {
    const debtor = this.debtorForm.getParty();
    this.order.debtor = debtor;
    
    const nonDebtors = this.order.parties?.filter(party => party.partyTypeID !== ePartyType.businessDebtor && party.partyTypeID !== ePartyType.individualDebtor);
    this.order.parties = nonDebtors;
    this.order.parties?.push(debtor);

    this.orderManager.updateOrder(this.order);
  }
}
