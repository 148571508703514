import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any, format: string = 'yyyy-MM-dd HH:mm'): any {
    // Get the client's timezone
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    // Format the date using the client timezone
    return this.datePipe.transform(value, format, clientTimezone);
  }
}