import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { RequestedReport } from '../../interfaces/requested-reports/requested-report';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { RequestedReportForCreation } from '../../interfaces/requested-reports/requested-report-for-creation';
import { RequestedReportForUpdate } from '../../interfaces/requested-reports/requested-report-for-update';

@Injectable({
    providedIn: 'root'
})
export class RequestedReportRepositoryService extends PaginatedRepository<RequestedReport> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        
        super(http,messageService);

        this._currentParams = new HttpParams()
            .set('pageSize', 10);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/RequestedReports`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getRequestedReport = (corporationID: string, requestedReportID: string) => {
        this.populateBaseLink([corporationID]);

        return this.http.get<RequestedReport>(
            `${this._baseLink}/${requestedReportID}`,
            this.generateHeaders());
    }

    public createRequestedReport = (corporationID: string, requestedReport: RequestedReportForCreation) => {
        this.populateBaseLink([corporationID]);

        return this.http.post<RequestedReport>(
            this._baseLink,
            requestedReport,
            this.generateHeaders());
    }

    public updateRequestedReport = (corporationID: string, requestedReportID: string, requestedReport: RequestedReportForUpdate) => {
        this.populateBaseLink([corporationID]);

        return this.http.put<RequestedReport>(
            `${this._baseLink}/${requestedReportID}`,
            requestedReport,
            this.generateHeaders());
    }

    public deleteRequestedReport = (corporationID: string, requestedReportID: string) => {
        this.populateBaseLink([corporationID]);

        return this.http.delete(
            `${this._baseLink}/${requestedReportID}`,
            this.generateHeaders());
    }
}