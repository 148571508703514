<div class="padding" *ngIf="hasAccess">
    <h1 class="left-box">{{userGroup?.name ?? ""}}</h1>

    <mat-card>
        <mat-card-content *ngIf="this.userGroup != null">
            <div class="flex-container">
                <span class="flex-item bold">Billing Centre:</span>
                <span class="flex-item-2">{{ this.userGroup!.billingDetails.name }}</span>

                <span class="flex-item bold">Configuration:</span>
                <span class="flex-item-2">{{ this.userGroup!.configurationDetails.name }}</span>
            </div>

            <div class="flex-container">
                <span class="flex-item bold">Phone:</span>
                <span class="flex-item-2">{{ this.userGroup!.contactDetails.phoneNumber }}</span>

                <span class="flex-item bold">Fax:</span>
                <span class="flex-item-2">{{ this.userGroup!.contactDetails.faxNumber }}</span>
            </div>

            <div class="flex-container">
                <span class="flex-item bold">Address:</span>
                <span class="flex-item-2">
                    {{ this.userGroup!.contactDetails.address.streetNumber }} {{ this.userGroup!.contactDetails.address.streetName }}<br/>
                    {{ this.userGroup!.contactDetails.address.city }}, {{ this.userGroup!.contactDetails.address.jurisdiction }}<br/>
                    {{ this.userGroup!.contactDetails.address.postalCode }}
                </span>

                <span class="flex-item bold">Email:</span>
                <span class="flex-item-2">{{ this.userGroup!.contactDetails.email }}</span>
            </div>

            <!-- <h3>Contact Information</h3>
            <app-client-contact-details-card *ngIf="userGroup != null" [contactDetails]="userGroup!.contactDetails"></app-client-contact-details-card>

            <div class="flex-container">
                <span class="spacer"></span>
                <button mat-raised-button (click)="updateUserGroupDetails()" color="accent" class="apply-padding" aria-label="Update Contact Details button">
                    Save Changes
                </button>
            </div> -->
        </mat-card-content>
    </mat-card>

    <mat-divider></mat-divider>

    <app-users-list [userGroup]="this.userGroup" ></app-users-list>

    <!-- <mat-divider></mat-divider> -->
    
    <!-- <app-manage-user-group-access [userGroup]="userGroup"></app-manage-user-group-access> -->
</div>