import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationState } from 'reg-hub-common';

@Component({
  selector: 'app-accounting-options',
  templateUrl: './accounting-options.component.html',
  styleUrls: ['./accounting-options.component.css']
})
export class AccountingOptionsComponent {
  @Input() public loading: boolean = false;
  hasAccountingAccess: boolean = true; //TODO: Populate this from config state
  selectedIndex: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
    private configurationState: ConfigurationState
  ) { }

  ngOnInit(): void {
    var defaultTab = this.activatedRoute.snapshot.queryParams['tab'];
    this.configurationState.hasAccountingAccess().then(hasAccountingAccess => {
      this.hasAccountingAccess = hasAccountingAccess;
      if (defaultTab !== null && defaultTab !== undefined) {
        if (defaultTab == 'invoices' && hasAccountingAccess) {
          this.selectedIndex = 1;
        }
        else {
          this.selectedIndex = 0;
        }
      }
    });
  }
}
