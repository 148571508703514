import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ClientInvoiceOrderFeesRepositoryService } from '../../services/invoice-order-fees/clien-invoice-order-fees-repository.service';
import { ConfigurationState, ListComponent, OrderFee } from 'reg-hub-common';

@Component({
    selector: 'app-invoice-fees',
    templateUrl: './invoice-fees.component.html',
    styleUrls: ['./invoice-fees.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed,void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class InvoiceFeesComponent extends ListComponent<OrderFee> {
    protected expandedFee: OrderFee | null = null;

    displayedColumns: string[] = [
        'feeType',
        'productCode',
        'taxCode',
        'total',
        'added',
    ];

    constructor(private activatedRoute: ActivatedRoute,
        private configurationState: ConfigurationState,
        repo: ClientInvoiceOrderFeesRepositoryService) {
        super(repo);
    }

    ngOnInit(): void {
        this.configurationState.getCorporationID().then(corpID => {
            this.configurationState.getBillingDetailsID().then(billingDetailsID => {
                this.activatedRoute.paramMap.subscribe(params => {
                    const invoiceID = params.get('invoiceID')!;
                    this.repo.populateBaseLink([corpID, billingDetailsID, invoiceID]);
                });
            });
        });
    }
}