<lib-parties-form
    partyTypeTitle="Secured Party"
    [businessPartyType]="businessSecuredParty"
    [individualPartyType]="individualSecuredParty"
    [defaultPartyType]="defaultType"
    [party]="securedParty"
    [errors$]="observableErrors$"
    [showSaveButton]="false"
    [showClearButton]="false"
    [showDeleteButton]="false"
    [uiConfiguration]="uiConfiguration.securedPartiesConfiguration!"
    (partySavedEvent)="handlePartySavedEvent($event)"
    (partyFormValueChangedEvent)="handlePartyFormValueChangedEvent($event)">
</lib-parties-form>