import { Component } from '@angular/core';
import { ConfigurationState } from 'reg-hub-common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent {
  protected isAuthorized: boolean = false;
  protected userAccess: boolean = false;
  protected partyAccess: boolean = false;

  constructor(private configurationState: ConfigurationState,
    private messageService: ToastrService) { }

  ngOnInit(): void {
    this.configurationState.hasUserManagementAccess().then(userAccess => {
      this.userAccess = userAccess;
      this.configurationState.hasManageReusablePartyAccess().then(partyAccess => {
        this.partyAccess = partyAccess;

        if (partyAccess || userAccess) {
          this.isAuthorized = true;
        }
        else {
          this.messageService.error("Access Denied");
        }
      })
    });
  }
}
