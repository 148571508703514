<h3>{{ isFormEnabled ? "Enter Search Criteria" : "Search Criteria Review" }}</h3>
<div class="padding">
    <form [formGroup]="searchCriteriaDetails">
        <div class="flex-container" *ngIf="shouldDisplayExactSimilar">
            <div class="flex-item form-field">
                <mat-radio-group [formControlName]="'exactSimilar'" name="exact-similar"
                    aria-label="Exact or similars radio option">
                    <mat-radio-button class="radio-button" [value]="false">Include Similars</mat-radio-button>
                    <mat-radio-button class="radio-button" [value]="true">Exact Only</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Serial Number</mat-label>
                    <input matInput [formControlName]="'serialNumber'" name="serial-number" placeholder="Serial Number">
                </mat-form-field>
                <lib-error-bubble [control]="searchCriteriaDetails.get('serialNumber')!"
                    [message]="serialNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="shouldDisplayAssetType" class="flex-container">
            <mat-form-field appearance="outline" class="form-field flex-item">
                <mat-label>Asset Type</mat-label>
                <mat-select [formControlName]="'assetTypeID'" placeholder="Asset Type">
                    <mat-option *ngFor="let option of assetTypes" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-expansion-panel *ngIf="shouldDisplaySearchFromDate"
            [expanded]="shouldExpandAdditionalParameters()"
            [class.mat-elevation-z1]="!panel.expanded" [class.mat-elevation-z0]="panel.expanded"
            #panel="matExpansionPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>Additional Parameters</h4>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="shouldDisplaySearchFromDate" class="flex-container">
                <div class="validated-form-field-container flex-container">
                    <mat-form-field class="form-field flex-item" appearance="outline">
                        <mat-label>Exclude Registrations Previous To</mat-label>
                        <input matInput [matDatepicker]="searchFromDate" [formControlName]="'searchFromDate'"
                            name="search-from-date" placeholder="YYYY-MM-DD">
                        <mat-datepicker-toggle matSuffix [for]="searchFromDate"></mat-datepicker-toggle>
                        <mat-datepicker #searchFromDate></mat-datepicker>
                    </mat-form-field>
                    <lib-error-bubble [control]="searchCriteriaDetails.get('searchFromDate')!"
                        [message]="searchFromDateError$"></lib-error-bubble>
                </div>
            </div>
            <br />
            <br />
        </mat-expansion-panel>
    </form>
</div>