import { Component, Input } from '@angular/core';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';

@Component({
  selector: 'lib-list-issue',
  templateUrl: './list-issue.component.html',
  styleUrls: ['./list-issue.component.css']
})
export class ListIssueComponent {
  @Input() repo!: PaginatedRepository<any>
  @Input() data!: any[]
}
