<div class="padding">
    <form [formGroup]="lienDetails">
        <app-term [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!" [errors$]="observableErrors$" [order]="order" [isDisabled]="isDisabled"></app-term>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAmount" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput name="amount" placeholder="Amount" [formControlName]="'amount'" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['amount']"
                    [message]="amountError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction ||
                    uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest" class="flex-container flex-item padding-top">
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPerfectionInAnotherJurisdiction" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'perfectionInAnotherJurisdiction'" name="perfection">
                    Perfection in Another Jurisdiction
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['perfectionInAnotherJurisdiction']"
                    [message]="perfectionInAnotherJurisdictionError$"></lib-error-bubble>
            </div>
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showPurchaseMoneySecurityInterest" class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'purchaseMoneySecurityInterest'"
                    name="security-interest">
                    Purchase Money Security Interest
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['purchaseMoneySecurityInterest']"
                    [message]="purchaseMoneySecurityInterestError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture ||
                    uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment" class="flex-container flex-item padding-top">
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showTrustIndenture"  class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'trustIndenture'" name="trust-indenture">
                    Trust Indenture
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['trustIndenture']"
                    [message]="trustIndentureError$"></lib-error-bubble>
            </div>
            <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showReceiverAppointment"  class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" [formControlName]="'receiverAppointment'" name="receiver-appointment">
                    Receiver Appointment
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['receiverAppointment']"
                    [message]="receiverAppointmentError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showRegisteredAdditionalInformation">
            Answers will be used to generate additional information text:
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showStillHavePossession" class="flex-container flex-item padding-top">
            <div class="validated-form-field-container flex-container">
                <mat-checkbox class="flex-item" formControlName="stillHavePossession" name="stillHavePossession">
                    Still Have Possession
                </mat-checkbox>
                <lib-error-bubble [control]="lienDetails.controls['stillHavePossession']"
                    [message]="stillHavePossessionError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showSurrenderDate" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Surrender Date</mat-label>
                    <input matInput [matDatepicker]="surrenderDate" name="surrender-date" formControlName="surrenderDate"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="surrenderDate"></mat-datepicker-toggle>
                    <mat-datepicker #surrenderDate></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('surrenderDate')!"
                    [message]="surrenderDateError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showServiceLastRendered" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Service Last Rendered</mat-label>
                    <input matInput [matDatepicker]="serviceLastRendered" name="service-last-rendered" formControlName="serviceLastRendered"
                        placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="serviceLastRendered"></mat-datepicker-toggle>
                    <mat-datepicker #serviceLastRendered></mat-datepicker>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.get('serviceLastRendered')!"
                    [message]="serviceLastRenderedError$"></lib-error-bubble>
            </div>
        </div>

        <div *ngIf="uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation" class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Additional Information</mat-label>
                    <textarea matInput name="additional-information" [formControlName]="'additionalInformation'"
                        placeholder="Additional information"></textarea>
                </mat-form-field>
                <lib-error-bubble [control]="lienDetails.controls['additionalInformation']"
                    [message]="additionalInformationError$"></lib-error-bubble>
            </div>
        </div>
    </form>
</div>