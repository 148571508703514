import { Step, StepValidationState } from "../../../../../interfaces/step";
import { Stepper } from "../../../../../interfaces/stepper";
import { ReviewComponent } from "../../../../orders/review/review.component";
import { BasicLienDetailsComponent } from "../../../../orders/basic-lien-details/basic-lien-details.component";
import { DebtorsComponent } from "../../../../orders/debtors/debtors.component";
import { SecuredPartiesComponent } from "../../../../orders/secured-parties/secured-parties.component";

export class QCBasicDischargeStepper implements Stepper {

    steps: Step[];

    constructor() {
        this.steps = [
            { title: "Details", content: BasicLienDetailsComponent, validationState: StepValidationState.DefaultState },
            { title: "Debtors", content: DebtorsComponent, validationState: StepValidationState.DefaultState },
            { title: "Secured Parties", content: SecuredPartiesComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: ReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}