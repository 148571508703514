<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field">
                <mat-select formControlName="systemEventTypeIDs" placeholder="Event Type" multiple="true">
                    <mat-option *ngFor="let option of _systemEventTypes" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="form-field">
                <mat-select formControlName="orderTypeID" placeholder="Order Type">
                    <mat-option *ngFor="let option of _orderTypes" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <lib-jurisdiction-selection (formReady)="jurisdictionFormChanges($event)"></lib-jurisdiction-selection>
        <div class="flex-container">
            <mat-form-field class="form-field">
                <mat-label>Date Start From</mat-label>
                <input matInput [matDatepicker]="startDateFrom" [formControlName]="'startDateFrom'"
                    placeholder="Date Started From">
                <mat-datepicker-toggle matSuffix [for]="startDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #startDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field">
                <mat-label>Date Start To</mat-label>
                <input matInput [matDatepicker]="dateStartedTo" [formControlName]="'dateStartedTo'"
                    placeholder="Date Start To">
                <mat-datepicker-toggle matSuffix [for]="dateStartedTo"></mat-datepicker-toggle>
                <mat-datepicker #dateStartedTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container">
            <mat-form-field class="form-field">
                <mat-label>Date End From</mat-label>
                <input matInput [matDatepicker]="endDateFrom" [formControlName]="'endDateFrom'"
                    placeholder="Date End From">
                <mat-datepicker-toggle matSuffix [for]="endDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #endDateFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="form-field">
                <mat-label>Date End To</mat-label>
                <input matInput [matDatepicker]="endDateTo" [formControlName]="'endDateTo'" placeholder="Date End To">
                <mat-datepicker-toggle matSuffix [for]="endDateTo"></mat-datepicker-toggle>
                <mat-datepicker #endDateTo></mat-datepicker>
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="padding-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>