<div class="flex-container" *ngIf="uiConfiguration?.showFileNumber">
    <span class="flex-item-3">File Number:</span>
    <span class="flex-item-4">{{ lien?.fileNumber }}</span>
</div>
<div class="flex-container">
    <span class="flex-item-3">Registration Number:</span>
    <span class="flex-item-4">{{ results?.registrationNumber }}</span>
</div>
<div class="flex-container">
    <span class="flex-item-3">Registration Date:</span>
    <span class="flex-item-4">{{ results?.registrationDate | localizedDate: "yyyy-MM-dd" }}</span>
</div>
<div class="flex-container">
    <span class="flex-item-3">Expiry Date:</span>
    <span class="flex-item-4">{{ (lien?.isInfiniteTerm ?? false) ? "NO EXPIRY DATE" : results?.expiryDate  | localizedDate: "yyyy-MM-dd" }}</span>
</div>