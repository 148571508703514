import { DebtorSearchReviewComponent } from "projects/reg-hub-client/src/app/orders/debtor-search-review/debtor-search-review.component";
import { DebtorSearchCriteriaComponent } from "projects/reg-hub-client/src/app/orders/debtor-search-criteria/debtor-search-criteria.component";
import { SearchStepper, eCriteriaVariation } from "../search-stepper";
import { StepValidationState } from "projects/reg-hub-client/src/interfaces/step";

export class NSDebtorSearchStepper extends SearchStepper {

    constructor() {
        super()
        this.configuration = [
            eCriteriaVariation.ExactSimilar,
            eCriteriaVariation.FirstMiddleLast
        ];

        this.steps = [
            { title: "Search Criteria", content: DebtorSearchCriteriaComponent, validationState: StepValidationState.DefaultState },
            { title: "Review", content: DebtorSearchReviewComponent, validationState: StepValidationState.DefaultState }
        ]
    }
}