<div class="padding">
    <!-- Page Header -->
    <h1 class="padding-top-bottom-25">Contact Us</h1>
    <p class="intro-text padding-bottom-25">
    We're here to assist you. Whether you have questions, need support, or have billing inquiries, please reach out to us through any of the methods below.
    </p>

    <!-- Contact Cards in a Responsive Flex Layout -->
    <div class="flex-container flex-wrap" [@fadeIn]>
        <mat-card class="document-card mat-elevation-z5">
            <!-- Thumbnail Image -->
            <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">support_agent</mat-icon>
            
            <!-- Card Content -->
            <mat-card-content class="padding">
                <mat-card-title class="card-title">Operational/Client Support</mat-card-title>
                <mat-card-subtitle>For assistance with our services or client support, contact us at:</mat-card-subtitle>

                <p class="flex-container">
                    <strong class="flex-item">Email:</strong>
                    <a [href]="supportLink" class="flex-item-4">admin@reghub.ca</a>
                </p>
                <p class="flex-container">
                    <strong class="flex-item">Phone:</strong>
                    <a [href]="supportPhone" class="flex-item-4">1 (888) 508-0608</a>
                </p>
                
            </mat-card-content>

            <!-- Card Actions -->
            <mat-card-actions class="card-actions">
                <mat-card-footer class="padding-left-15">
                    <h5>Available Monday–Friday, 9:00 AM – 5:00 PM EST</h5>
                </mat-card-footer>
              </mat-card-actions>
        </mat-card>

        <mat-card class="document-card mat-elevation-z5">
            <!-- Thumbnail Image -->
            <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">attach_money</mat-icon>
            
            <!-- Card Content -->
            <mat-card-content class="padding">
                <mat-card-title class="card-title">Billing Support</mat-card-title>
                <mat-card-subtitle>For billing inquiries or assistance with your account, reach out to:</mat-card-subtitle>

                <p class="flex-container">
                    <strong class="flex-item">Email:</strong>
                    <a [href]="supportLink" class="flex-item-4">billing@reghub.ca</a>
                </p>

                <p class="flex-container">
                    <strong class="flex-item"></strong>
                    <a [href]="" class="flex-item-4"></a>
                </p>
            </mat-card-content>
            <span class="spacer"></span>
            <!-- Card Actions -->
            <mat-card-actions class="card-actions">
                <mat-card-footer class="padding-left-15">
                    <h5>Available Monday–Friday, 9:00 AM – 5:00 PM EST</h5>
                </mat-card-footer>
              </mat-card-actions>
        </mat-card>
    </div>
</div>