import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ConfigurationDetails } from '../../interfaces/configuration-details/configuration-details';
import { ConfigurationDetailsForCreation } from '../../interfaces/configuration-details/configuration-details-for-creation';
import { ConfigurationDetailsForUpdate } from '../../interfaces/configuration-details/configuration-details-for-update';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationDetailsRepository extends PaginatedRepository<ConfigurationDetails> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/ConfigurationDetails`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getConfigurationDetails = (configurationDetailsID: string) => {
        return this.http.get<ConfigurationDetails>(`${this._baseLink}/${configurationDetailsID}`, this.generateHeaders());
      }

    public createConfigurationDetails = (configurationDetails: ConfigurationDetailsForCreation) => {
        return this.http.post<ConfigurationDetails>(
            this._baseLink,
            configurationDetails,
            this.generateHeaders());
    }

    public updateConfigurationDetails = (configurationDetailsID: string, configurationDetails: ConfigurationDetailsForUpdate) => {
        return this.http.put(`${this._baseLink}/${configurationDetailsID}`, configurationDetails, this.generateHeaders());
    }
}