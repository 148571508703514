import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateReportRepositoryService, Order, ListComponent, ConfigurationState, ReportTemplate, ReportTemplatesRepositoryService } from 'reg-hub-common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-live-template-report',
    templateUrl: './live-template-report.component.html',
    styleUrls: ['./live-template-report.component.css'],
    animations: [
        trigger('detailExpand', [
          state('collapsed,void', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
export class LiveTemplateReportComponent extends ListComponent<Order> {
    protected _expandedOrder: Order | null = null;
    reportTemplate: ReportTemplate | null = null;

    displayedColumns: string[] = [
        'referenceNumber',
        'orderTypeID',
        'orderStatusTypeID',
        'country',
        'jurisdiction',
        'dateRequested'
    ];

    columnsToDisplayWithView = [...this.displayedColumns, 'view', 'indicator'];

    constructor(
        repo: TemplateReportRepositoryService,
        private activatedRoute: ActivatedRoute,
        private configurationState: ConfigurationState,
        private router: Router,) {
        super(repo);
    }

    ngOnInit(): void {
        this.configurationState.getCorporationID().then(corporationID => {
            let reportTemplateID = this.activatedRoute.snapshot.paramMap.get('reportTemplateID')!;
            this.repo.populateBaseLink([corporationID, reportTemplateID]);

            this.repo.getFirstPage();
        })
    }

    protected viewOrder(order: Order, newTab: boolean) {
        if (newTab) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(['orders', order.id])
            );

            window.open(url, '_blank');
        }
        else {
            this.router.navigate(['orders', order.id])
        }
    }
}