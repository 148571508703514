import { Component, OnInit } from '@angular/core';
import { ePartyType, Order, OrderUIConfiguration, Party } from 'reg-hub-common';
import { PrintDataService } from '../../services/print-data/print-data.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html',
  styleUrls: ['./print-order.component.css']
})
export class PrintOrderComponent implements OnInit {
  protected order!: Order;
  protected uiConfiguration!: OrderUIConfiguration;

  protected securedParties = new BehaviorSubject<Party[]>([]);
  protected debtors = new BehaviorSubject<Party[]>([]);
  protected dealers = new BehaviorSubject<Party[]>([]);
  protected registeringAgents = new BehaviorSubject<Party[]>([]);

  constructor(private printDataService: PrintDataService) { }

  ngOnInit(): void {
    this.order = this.printDataService.data.order;
    this.uiConfiguration = this.printDataService.data.uiConfiguration;

    this.printDataService.clear();

    if(this.order.parties) {
      this.securedParties.next(this.order.parties.filter(p => p.partyTypeID === ePartyType.businessSecuredParty || p.partyTypeID === ePartyType.individualSecuredParty));
      this.debtors.next(this.order.parties.filter(p => p.partyTypeID === ePartyType.businessDebtor || p.partyTypeID === ePartyType.individualDebtor));
      this.dealers.next(this.order.parties.filter(p => p.partyTypeID === ePartyType.businessDealer || p.partyTypeID === ePartyType.individualDealer));
      this.registeringAgents.next(this.order.parties.filter(p => p.partyTypeID === ePartyType.businessRegisteringAgent || p.partyTypeID === ePartyType.individualRegisteringAgent));
    }
  }
}
