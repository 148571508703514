<div *ngIf="isAuthenticated">
  <router-outlet></router-outlet>
</div>

<router-outlet name="print"></router-outlet>

<div class="redirect" *ngIf="!isAuthenticated">
  <div class="block">
    <div class="flex-container-center">
        <mat-spinner></mat-spinner>
    </div>
    <div class="spacer-large">
    </div>
    <span>Redirecting to login...</span>
  </div>
</div>