<h3>Serial Collateral</h3>
<!-- Assets -->
<div *ngIf="uiConfiguration" class="flex-container no-margin">
    <lib-asset-list
        [order]="order"
        [observableAssets$]="assets$"
        [errors$]="errors$"
        [uiConfiguration]="uiConfiguration"
        class="flex-item"
        [isEditable]="isEditable"
        [expandedAsset]="expandedAsset"
        (assetAddedEvent)="handleAssetAddedEvent($event)"
        (assetUpdatedEvent)="handleAssetSaveEvent($event)"
        (assetCopiedEvent)="handleAssetCopiedEvent($event)"
        (assetRemovedEvent)="handleAssetRemovedEvent($event)"
        (assetFormValueChangedEvent)="handleAssetFormValueChange($event)">
    </lib-asset-list>
</div>