import { Component } from '@angular/core';
import { Order, ValidationProblem } from 'reg-hub-common';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';

@Component({
  selector: 'app-business-report-review',
  templateUrl: './business-report-review.component.html',
  styleUrls: ['./business-report-review.component.css']
})
export class BusinessReportReviewComponent extends SteppedComponent {
  protected override init(order: Order): void {
    super.init(order);
    this.validate(this.order);
  }
  
  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }
}