import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { EnumeratedTypesRepositoryService, JurisdictionInfo, JurisdictionSelectionComponent, Order, OrderManagerService, OrderRepositoryService, OrderTypeJurisdictions, SelectOption, eAssetType, eBusinessSearchCriteriaType, eMatSelectAppearance, eOrderType, eOrderTypeGroup, ePartyType, eQCFormType } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  selector: 'app-base-order-information',
  templateUrl: './base-order-information.component.html',
  styleUrls: ['./base-order-information.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ]
})
export class BaseOrderInformationComponent implements OnInit, OnDestroy {
  order: Order = {
    referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet
  } as Order;

  originalOrderID: string | null = null;
  isLoadingOrder: boolean = false;
  isHistorical: boolean = false;
  shouldShowJurisdiction: boolean = true;

  orderTypeGroupIDs: eOrderTypeGroup[] = [eOrderTypeGroup.PPSARegistration];
  ppsaRegistrationOrderTypeGroup = eOrderTypeGroup.PPSARegistration;
  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  protected baseOrderDetails!: FormGroup;
  protected orderTypes: SelectOption[] = [];
  protected orderTypeJurisdictions: OrderTypeJurisdictions[] = [];
  protected jurisdictions: JurisdictionInfo[] = [];
  protected orderTypeFieldName: string = "orderTypeID";
  protected appearance: MatFormFieldAppearance = eMatSelectAppearance.outline;
  protected selectedOrderType: string | null = null;
  protected orderIsQC = false;
  protected qcFormTypes: SelectOption[] = [];
  protected isSaving: boolean = false;
  protected isBasic: boolean = false;

  constructor(
    private environmentUrl: EnvironmentUrlService,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    private router: Router) {
  }

  ngOnInit(): void {
    const currentURL = this.router.url;
    if (currentURL.includes('new-registration')) {
      this.orderTypeGroupIDs = [eOrderTypeGroup.PPSARegistration, eOrderTypeGroup.BasicPPSARegistration]
    } else if (currentURL.includes('new-search')) {
      this.orderTypeGroupIDs = [eOrderTypeGroup.PPSASearch]
    } else if (currentURL.includes('new-business-search')) {
      this.orderTypeGroupIDs = [eOrderTypeGroup.BusinessSearch]
    }
    this.getOrderTypes();
    this.getOrderTypeJurisdictions();

    this.baseOrderDetails = this.formBuilder.group({
      referenceNumber: [this.order.referenceNumber, Validators.required],
      country: [this.order.country, Validators.required],
      jurisdiction: [this.order.jurisdiction, Validators.required],
      orderTypeID: [this.order.orderTypeID, Validators.required],
      searchParameters: [{}],
      lien: this.formBuilder.group({
        qcFormTypeID: [null]
      })
    })

    if (this.order.orderTypeID === eOrderType.businessSearch) {
      this.baseOrderDetails.addControl('businessSearchCriteria', this.formBuilder.group({
        businessSearchCriteriaTypeID: [eBusinessSearchCriteriaType.Name],
        name: ['']
      }));
    }
  }

  ngOnDestroy(): void {

  }

  getOrderTypes() {
    this.enumeratedTypesRepo.getOrderTypesAsSelectOptions(this.environmentUrl.urlAddress, this.orderTypeGroupIDs)
      .subscribe(orderTypes => {
        this.orderTypes = orderTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      })
  }

  getQCFormTypes() {
    var lienFormTypes: eQCFormType[] = [eQCFormType.RDd, eQCFormType.RDe, eQCFormType.RHa, eQCFormType.RDf];
    this.enumeratedTypesRepo.getQcFormTypesAsSelectOptions(this.environmentUrl.urlAddress, lienFormTypes)
      .subscribe(qcFormTypes => {
        this.qcFormTypes = qcFormTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      })
  }

  protected handleJurisdictionChangeEvent() {
    const jurisdiction = this.baseOrderDetails.get('jurisdiction')!.value;
    this.orderIsQC = jurisdiction == "QC";

    this.updateQCFormTypeControl();
  }

  protected getOrderTypeJurisdictions() {
    this.enumeratedTypesRepo.getOrderTypeJurisdictions(this.environmentUrl.urlAddress)
      .subscribe(orderTypes => {
        this.orderTypeJurisdictions = orderTypes;
      })
  }

  protected onOrderTypeSelectionChange() {
    this.selectedOrderType = this.baseOrderDetails.get('orderTypeID')!.value;
    this.isBasic = (this.selectedOrderType == "BasicDischarge" || this.selectedOrderType == "BasicRenewal");
    
    if (this.isBasic) {
      this.isHistorical = false;
    }

    this.shouldShowJurisdiction = (this.selectedOrderType != "BusinessSearch")
    if (this.shouldShowJurisdiction) {
      // clear jurisdiction control
      this.baseOrderDetails.get('jurisdiction')?.reset();

      this.orderTypeJurisdictions.forEach(orderTypeJurisdiction => {
        if (orderTypeJurisdiction.orderTypeID == this.selectedOrderType) {
          this.jurisdictions = orderTypeJurisdiction.jurisdictions;
        }
      });
    } else {
      // if we don't want to show jurisdictions, set to CW
      // (business search jurisdiction selection happens later)
      this.baseOrderDetails.get('jurisdiction')?.setValue("CW");
    }

    this.updateQCFormTypeControl();
  }

  private updateQCFormTypeControl() {
    const qcFormTypeIDControl = this.baseOrderDetails.get('lien')?.get('qcFormTypeID');

    if (!qcFormTypeIDControl) {
      return;
    }

    if (this.orderIsQC && this.selectedOrderType == "Lien") {
      qcFormTypeIDControl.addValidators(Validators.required);
      this.getQCFormTypes();
    } else {
      qcFormTypeIDControl.clearValidators();
      qcFormTypeIDControl.reset();
    }
  }

  saveContents(): boolean {
    this.isSaving = true;

    // Add extra required groups depending on request type
    if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.PPSASearch) {
      const orderTypeIDValue = this.baseOrderDetails.get('orderTypeID')!.value;
      if (orderTypeIDValue == "AssetSearch") {
        this.baseOrderDetails.addControl('asset', this.formBuilder.group({
          assetTypeID: [eAssetType.MotorVehicle],
          serialNumber: [""]
        }));
      }
      if (orderTypeIDValue == "DebtorSearch" || orderTypeIDValue == "CertifiedDebtorSearch") {
        this.baseOrderDetails.addControl('debtor', this.formBuilder.group({
          partyTypeID: [ePartyType.individualDebtor],
          busName: [""]
        }));
      }
    }

    if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.BusinessSearch) {
      const orderTypeIDValue = this.baseOrderDetails.get('orderTypeID')!.value;
      if (orderTypeIDValue == "BusinessSearch") {
        this.baseOrderDetails.addControl('businessSearchCriteria', this.formBuilder.group({
          businessSearchCriteriaTypeID: [eBusinessSearchCriteriaType.Name],
          name: [""]
        }));
      }
      if (orderTypeIDValue == "BusinessReport") {
        this.baseOrderDetails.addControl('businessReportCriteria', this.formBuilder.group({
          number: [""]
        }));
      }
    }

    if (!this.baseOrderDetails.valid) {
      this.baseOrderDetails.markAllAsTouched();
      this.baseOrderDetails.updateValueAndValidity();

      return false;
    }

    this.ordersRepo.createNewOrder(this.baseOrderDetails.value, this.isHistorical)
      .subscribe(result => {
        this.isSaving = false;
        const order = (result as any).resource;
        this.order = order;
        this.orderManager.updateOrder(order);
        if (this.order.id) {
          var redirectUrl = ''
          if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.PPSARegistration) {
            redirectUrl = `new-registration/${order.id}`;
          } else if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.PPSASearch) {
            redirectUrl = `new-search/${order.id}`;
          } else if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.BusinessSearch) {
            redirectUrl = `new-business-search/${order.id}`;
          }
          this.router.navigate([redirectUrl]);
        }
      })

    // do validation here
    return true;
  }

  // TODO: Fill out validation
  validateContents(form?: NgForm): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }
}
