<div class="flex-container-center">
    <h2>{{ order.jurisdiction }} {{ order.orderType.name }} Order</h2>
</div>

<div class="flex-container-center padding-bottom-25">
    <h4>Status: {{ order.orderStatusTypeID }}</h4>
</div>

<div class="section-container" *ngIf="uiConfiguration.amendmentConfiguration?.showChangeText">
    <h4>Change Text</h4>
    <lib-change-text></lib-change-text>
</div>

<div class="section-container" *ngIf="uiConfiguration.hasReferenceRegistrationInformation">
    <h4>Existing Registration Details</h4>
    <lib-existing-registration-details
        [uiConfiguration]="uiConfiguration.referenceRegistrationInformationUIConfiguration!">
    </lib-existing-registration-details>
</div>

<div class="section-container" *ngIf="uiConfiguration.hasLienDetails">
    <h4 *ngIf="uiConfiguration.hasLienDetails">{{order.orderType.name}} Details</h4>
    <lib-lien-details [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"></lib-lien-details>
</div>

<div class="section-container" *ngIf="uiConfiguration?.hasSecuredParties">
    <h4>Secured Parties</h4>
    <lib-parties-list [order]="order" [observableParties$]="securedParties" [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.securedPartiesConfiguration!">
    </lib-parties-list>
</div>

<div class="section-container" *ngIf="uiConfiguration?.hasDebtors">
    <h4>Debtors</h4>
    <lib-parties-list partyTypeTitle="Debtor" [order]="order" [observableParties$]="debtors" [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.debtorsConfiguration!">
    </lib-parties-list>
</div>

<div class="section-container" *ngIf="uiConfiguration?.hasDealers">
    <h4>Dealers</h4>
    <lib-parties-list partyTypeTitle="Dealer" [order]="order" [observableParties$]="dealers" [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.dealersConfiguration!">
    </lib-parties-list>
</div>

<div class="section-container" *ngIf="uiConfiguration?.hasRegisteringAgents">
    <h4>Registering Agents</h4>
    <lib-parties-list partyTypeTitle="Registering Agent" [order]="order" [observableParties$]="registeringAgents"
        [isEditable]="false" [uiConfiguration]="uiConfiguration!.registeringAgentsConfiguration!">
    </lib-parties-list>
</div>

<div class="section-container" *ngIf="uiConfiguration?.collateralConfiguration?.hasGeneralCollateral">
    <h4>General Collateral</h4>
    <lib-general-collateral [uiConfiguration]="uiConfiguration.collateralConfiguration?.generalCollateralConfiguration"
        [hideHistory]="true"></lib-general-collateral>
</div>

<div class="section-container" *ngIf="uiConfiguration?.collateralConfiguration?.hasAssets">
    <h4>Assets</h4>
    <lib-asset-list [order]="order" [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.collateralConfiguration!.assetConfiguration!">
    </lib-asset-list>
</div>