import { Component, ViewChild } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { ePartyType, Order, OrderManagerService, Party, PartyRepositoryService, UIConfigurationService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { PartiesComponent } from '../parties/parties.component';
import { PartiesListComponent } from 'reg-hub-common';

@Component({
  selector: 'app-debtors',
  templateUrl: './debtors.component.html',
  styleUrls: ['./debtors.component.css']
})
export class DebtorsComponent extends PartiesComponent {
  @ViewChild(PartiesListComponent) partiesListComponent!: PartiesListComponent;

  businessDebtor = ePartyType.businessDebtor;
  individualDebtor = ePartyType.individualDebtor;

  constructor(
    partyRepo: PartyRepositoryService,
    uiConfigurationService: UIConfigurationService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(partyRepo, uiConfigurationService, orderManager, validationService);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    const filteredDebtors = order.parties!.filter((party) => Constants.isDebtor(party.partyTypeID));
    return filteredDebtors?.length ?? 0 > 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  protected override getParties(): Party[] {
    return this.order.parties!.filter((party) => Constants.isDebtor(party.partyTypeID));
  }

  override onSaving(): void {
    var partyToReplace = this.partiesListComponent.onSaving();
    if (partyToReplace) {
      this.replaceParty(partyToReplace!);
    }
  }
}
